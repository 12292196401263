import { formatNumber } from '@/core/libs/formatters';
import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { SearchableConfigurationImpl } from '@/modules/matchmaking/models/searchbar/impl/SearchableConfigurationImpl';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';
import { SearchableUnit } from '@/modules/matchmaking/models/searchbar/SearchableUnit';

export class SearchableUnitImpl
  extends SearchableConfigurationImpl
  implements SearchableUnit
{
  readonly scalar: number;

  constructor(
    category: CategoryEnum,
    id: string,
    relatives: SearchableConfiguration[],
    searchWeight: number,
    aliases: string[],
    translationKey: string,
    weightInsideCategory: number,
    descriptionTranslationKey: string,
    scalar: number,
  ) {
    super(
      category,
      id,
      relatives,
      searchWeight,
      aliases,
      translationKey,
      weightInsideCategory,
      descriptionTranslationKey,
    );
    this.scalar = scalar;
  }

  override label(): string {
    return `${formatNumber(this.scalar)}${super.label()}`;
  }

  /**
   * @param searchTerm
   * @return Value between 0.0 and 1.0.
   */
  override matchingWeight(searchTerm: string): number {
    const extractedNumber =
      (searchTerm || '').match(/\d*\.?/gi)?.join('') ?? '';
    if (extractedNumber.length <= 0) {
      return this.searchWeight;
    } else {
      const parsedNumber = parseFloat(extractedNumber);
      const isNumber = !isNaN(parsedNumber);
      if (isNumber && parsedNumber === this.scalar) {
        return 0.9;
      } else {
        return 0.0;
      }
    }
  }
}

import * as React from 'react';

import { Icon } from '@/core/components/component-library/Icon/Icon';
import { IconButton } from '@/core/components/ui/icon-button';
import { Input, type InputProps } from '@/core/components/ui/input';
import { cn } from '@/core/libs/utils';

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);

    return (
      <div className="relative">
        <Input
          type={showPassword ? 'text' : 'password'}
          className={cn('pr-10', className)}
          ref={ref}
          {...props}
        />
        <IconButton
          type="button"
          appearance="subtle"
          size="sm"
          className="hover:bg-transparent absolute right-0 top-0 h-full px-3 py-1"
          onClick={(): void => setShowPassword((prev) => !prev)}
          disabled={props.value === '' || props.disabled}
        >
          {showPassword ? (
            <Icon type={Icon.type.HIDE} className="size-6" aria-hidden="true" />
          ) : (
            <Icon type={Icon.type.SHOW} className="size-6" aria-hidden="true" />
          )}
          <span className="sr-only">
            {showPassword ? 'Hide password' : 'Show password'}
          </span>
        </IconButton>
      </div>
    );
  },
);
PasswordInput.displayName = 'PasswordInput';

export { PasswordInput };

import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { AUTH_COOKIE_KEY } from '@/core/context/AuthenticationContext';
import { errorHandler, HandleErrorOptions } from '@/core/libs/error-handler';

import { FetchAPIDataType } from './FetchApiDataType.enum';
import { ResponseError } from './ResponseError';

function formatResponse<T>(response: Response): T {
  const contentType = response.headers.get('Content-Type');
  let data: unknown;
  if (contentType?.includes(FetchAPIDataType.JSON)) {
    data = response.json();
  } else if (contentType?.includes('image/')) {
    data = response.blob();
  } else {
    data = response.body;
  }
  return data as T;
}

function handleResponse<T>({
  resolve,
  response,
}: {
  resolve: (arg: T) => void | PromiseLike<void>;
  response: Response;
  url: string;
}): void {
  const { status } = response;
  if (status >= 200 && status < 300) {
    resolve(formatResponse<T>(response));
  } else {
    throw new ResponseError(response);
  }
}

function wrappedFetch<T>(
  targetUrl: string,
  configuration: RequestInit,
): Promise<T> {
  const isInternal = !/^http/.test(targetUrl);
  // const url = handleURL(targetUrl, isInternal);
  const url = targetUrl;

  return new Promise((resolve, reject) => {
    fetch(url, {
      ...(isInternal ? { credentials: 'include' } : {}),
      referrerPolicy: 'strict-origin-when-cross-origin',
      ...configuration,
    })
      .then((response) =>
        handleResponse<T>({
          resolve,
          response,
          url,
        }),
      )
      // In fetch API the promise is only error when something catastrophic happens but not when
      // the header status is set, even with 500. That's why were handling the error here
      .catch((error) => reject(error));
  });
}

function getHeaders(
  dataType = FetchAPIDataType.JSON,
  overrideHeaders = {},
): HeadersInit {
  const headers: HeadersInit = {};

  // To handle properly FormData and Multipart form you must not define
  // the content type and let the browser to do so automatically
  if (dataType === FetchAPIDataType.JSON) {
    headers['Content-Type'] = dataType;
  }

  if (Cookies.get(AUTH_COOKIE_KEY)) {
    headers['Authorization'] = `Bearer ${Cookies.get(AUTH_COOKIE_KEY)}`;
  }

  return {
    ...headers,
    ...overrideHeaders,
  };
}

function formatRequestBody(
  data: unknown,
  dataType = FetchAPIDataType.JSON,
): string | FormData | undefined {
  if (dataType === FetchAPIDataType.JSON) {
    return JSON.stringify(data);
  }
  if (data instanceof FormData) {
    return data;
  }
  if (data && typeof data === 'object') {
    return Object.entries(data).reduce((finalData, [key, value]) => {
      finalData.append(key, value as string);
      return finalData;
    }, new FormData());
  }
  return undefined;
}

export enum RestMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface FetchRequestOptions
  extends Omit<Partial<Request>, 'method' | 'headers'> {
  dataType?: FetchAPIDataType;
  method?: RestMethods;
  headers?: Record<string, string> | Headers;
}
// We break the export pattern to be able to keep delete method
// without strange naming when importing to be used
export const fetchApi = {
  get: <T>(
    url: string,
    { cache = 'no-store', ...options }: FetchRequestOptions = {},
  ): Promise<T> => {
    return wrappedFetch<T>(url, { ...options, method: RestMethods.GET, cache });
  },
  getCached: <T>(url: string): Promise<T> =>
    fetchApi.get<T>(url, { cache: 'default' }), // Backwards compatibility, we should get rid of it
  post: <T = unknown>(
    url: string,
    data: unknown,
    { dataType, headers, ...options }: FetchRequestOptions = {},
  ): Promise<T> =>
    wrappedFetch<T>(url, {
      ...options,
      method: RestMethods.POST,
      headers: getHeaders(dataType, headers),
      body: formatRequestBody(data, dataType),
    }),
  put: <T = unknown>(
    url: string,
    data: unknown,
    { dataType, headers, ...options }: FetchRequestOptions = {},
  ): Promise<T> =>
    wrappedFetch<T>(url, {
      ...options,
      method: RestMethods.PUT,
      headers: getHeaders(dataType, headers),
      body: formatRequestBody(data, dataType),
    }),
  delete: <T = unknown>(
    url: string,
    options: FetchRequestOptions = {},
  ): Promise<T> =>
    wrappedFetch<T>(url, { ...options, method: RestMethods.DELETE }),
};

export type UseFetch<T> = {
  response: T | null;
  error: string | null;
  isLoading: boolean;
};

export interface UseFetchOptions extends FetchRequestOptions {
  data?: unknown;
  errorConfig?: HandleErrorOptions;
}

export function useFetch<T>(
  method: RestMethods,
  url: string,
  { data, errorConfig, ...options }: UseFetchOptions = {},
): UseFetch<T> {
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true);

      try {
        let fetchResponse: T;

        switch (method) {
          case 'POST':
            fetchResponse = await fetchApi.post<T>(url, data, options);
            break;
          case 'PUT':
            fetchResponse = await fetchApi.put<T>(url, data, options);
            break;
          case 'DELETE':
            fetchResponse = await fetchApi.delete<T>(url, options);
            break;
          case 'GET':
          default:
            fetchResponse = await fetchApi.get<T>(url, options);
            break;
        }

        setResponse(fetchResponse);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (fetchError: any) {
        setError(fetchError?.toString());
        errorHandler.capture(fetchError, errorConfig);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [method, url]);

  return { response, error, isLoading };
}

/**
 * Hook wrapper for useFetch with GET
 *
 * @param url The url for the fetch call
 *
 * @param options Optional parameters for the request and error handling (Check useFetch)
 */
export const useFetchGet = <T>(
  url: string,
  options: UseFetchOptions,
): UseFetch<T> => useFetch<T>(RestMethods.GET, url, options);

/**
 * Hook wrapper for useFetch with POST
 *
 * @param url The url for the fetch call
 *
 * @param data Data to be sent with the request
 *
 * @param options Optional parameters for the request and error handling (Check useFetch)
 */
export const useFetchPost = <T>(
  url: string,
  data: unknown,
  options: UseFetchOptions,
): UseFetch<T> => useFetch<T>(RestMethods.POST, url, { ...options, data });

/**
 * Hook wrapper for useFetch with PUT
 *
 * @param url The url for the fetch call
 *
 * @param data Data to be sent with the request
 *
 * @param options Optional parameters for the request and error handling (Check useFetch)
 */
export const useFetchPut = <T>(
  url: string,
  data: unknown,
  options: UseFetchOptions,
): UseFetch<T> => useFetch<T>(RestMethods.PUT, url, { ...options, data });

/**
 * Hook wrapper for useFetch with DELETE
 *
 * @param url The url for the fetch call
 *
 * @param options Optional parameters for the request and error handling (Check useFetch)
 */
export const useFetchDelete = <T>(
  url: string,
  options: UseFetchOptions,
): UseFetch<T> => useFetch<T>(RestMethods.DELETE, url, options);

import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { Button } from '@/core/components/ui/button';
import { IconButton } from '@/core/components/ui/icon-button';
import { Separator } from '@/core/components/ui/separator';
import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { LanguageCode, useLocaleContext } from '@/core/context/I18nContext';
import { cn } from '@/core/libs/utils';
import { User } from '@/generated/api';

import { Icon } from '../component-library/Icon/Icon';
import FlagDE from './icons/FlagDE';
import FlagEN from './icons/FlagEN';
import FlagES from './icons/FlagES';
import FlagIT from './icons/FlagIT';
import FlagTR from './icons/FlagTR';
import FlagZH from './icons/FlagZH';
import { NavItem } from './nav-item';
import { NavMenu } from './nav-menu';
import { NavTitle } from './nav-title';
import OrderfoxLogo from './PartfoxLogo';

interface SideNavProps {
  expanded: boolean;
  onExpanded(expanded: boolean): void;
}

export function SideNav({
  expanded,
  onExpanded: setExpanded,
}: SideNavProps): JSX.Element {
  const { $t } = useIntl();
  const location = useLocation();
  const { setUserLocale } = useLocaleContext();
  const { isLoggedIn, openLoginModal, logout, currentUser } =
    useAuthenticationContext();

  return (
    <aside className="fixed inset-y-4 left-5 z-20 hidden min-h-[calc(100vh-40px)] max-w-[300px] overflow-hidden rounded-xl shadow-sideBar lg:block">
      <nav className="flex h-full flex-col overflow-y-auto bg-white pt-4">
        <div
          className={cn(
            'flex items-center overflow-hidden pb-10 pt-7 transition-all',
            expanded
              ? 'w-[300px] justify-between px-6'
              : 'w-[72px] justify-center px-1',
          )}
        >
          <OrderfoxLogo showBrandName={expanded} />
        </div>
        <div className="flex-1 px-2">
          <IconButton
            appearance="subtle"
            onClick={(): void => setExpanded(!expanded)}
            className={cn(
              'h-12 w-full overflow-hidden rounded-xl transition-all hover:bg-neutral-100',
              expanded ? 'justify-end px-6' : 'px-1',
            )}
          >
            <Icon
              type={
                expanded
                  ? Icon.type.TAILED_ARROW_LEFT
                  : Icon.type.TAILED_ARROW_RIGHT
              }
              className="flex size-6 items-center justify-center text-xl text-neutral-600"
            />
            <span className="sr-only">{expanded ? 'collapse' : 'expand'}</span>
          </IconButton>

          <NavTitle name={$t({ id: 'menu.searchAndExplore' })} />

          <NavItem
            as={Link}
            to="/search"
            active={location.pathname.includes('/search')}
            icon={Icon.type.ZOOM}
            text={$t({ id: 'supplierSearch.menu.search' })}
          />

          {currentUser?.type !== User.type.BUYER && (
            <NavItem
              as={Link}
              to="/feed"
              active={location.pathname.includes('/feed')}
              icon={Icon.type.COPY}
              text={$t({ id: 'menu.feed' })}
              isNew
            />
          )}

          <NavTitle name={$t({ id: 'menu.buyerCockpit' })} />

          <NavItem
            as={isLoggedIn ? Link : Button}
            to="/projects"
            onClick={isLoggedIn ? undefined : (): void => openLoginModal()}
            active={
              location.pathname.includes('projects') ||
              location.pathname.includes('project')
            }
            icon={Icon.type.DOC_FOLDER}
            text={$t({ id: 'projectOverview.pageTitle' })}
          />

          <NavItem
            as={Link}
            to="/my-manufacturer-lists"
            active={location.pathname.includes('/my-manufacturer-lists')}
            icon={Icon.type.BOOKMARK}
            text={$t({ id: 'MyManufacturers.title' })}
          />

          {currentUser?.type !== User.type.BUYER && (
            <>
              <NavTitle name={$t({ id: 'menu.manufacturerCockpit' })} />

              <NavItem
                as={isLoggedIn ? Link : Button}
                to="/requests"
                onClick={isLoggedIn ? undefined : (): void => openLoginModal()}
                active={
                  location.pathname.includes('/requests') ||
                  location.pathname.includes('/request')
                }
                icon={Icon.type.SELECTED_REQUESTS}
                text={$t({ id: 'requestOverview.pageTitle' })}
              />

              <NavItem
                as={isLoggedIn ? Link : Button}
                to="/manufacturer-profile"
                onClick={isLoggedIn ? undefined : (): void => openLoginModal()}
                active={
                  location.pathname.includes('manufacturer-profile') &&
                  !location.pathname.includes('my-machinery') &&
                  !location.pathname.includes('my-sample-parts')
                }
                icon={Icon.type.BUSINESS_CARD}
                text={$t({ id: 'General.yourVisibility' })}
              />

              <NavItem
                as={isLoggedIn ? Link : Button}
                to="/manufacturer-profile/my-machinery"
                onClick={isLoggedIn ? undefined : (): void => openLoginModal()}
                active={location.pathname.includes(
                  'manufacturer-profile/my-machinery',
                )}
                icon={Icon.type.CNC_MACHINE}
                text={$t({ id: 'Machinery.myMachinery' })}
              />

              <NavItem
                as={isLoggedIn ? Link : Button}
                to="/manufacturer-profile/my-sample-parts"
                onClick={isLoggedIn ? undefined : (): void => openLoginModal()}
                active={location.pathname.includes(
                  '/manufacturer-profile/my-sample-parts',
                )}
                icon={Icon.type.BOLT}
                text={$t({ id: 'SampleParts.mySampleParts' })}
              />
            </>
          )}
        </div>
        <div className="mb-2 px-2">
          <div className="px-4">
            <Separator className="my-5" />
          </div>
          {isLoggedIn && currentUser?.role === User.role._0 && (
            <NavItem
              as={isLoggedIn ? Link : Button}
              to="/admin/ghost-mode"
              onClick={isLoggedIn ? undefined : (): void => openLoginModal()}
              active={location.pathname.includes('ghost-mode')}
              icon={Icon.type.GHOST}
              text={$t({ id: 'GhostMode.title' })}
              dataTestId="sidenav__ghost-mode"
            />
          )}
          {!isLoggedIn && (
            <NavMenu
              title={$t({ id: 'General.language' })}
              icon={Icon.type.TRANSLATION}
            >
              <NavItem
                onClick={(): void => setUserLocale(LanguageCode.ENGLISH)}
                icon={<FlagEN />}
                text="English"
                showTooltip={false}
                className="h-10"
                isSubMenuItem
              />
              <NavItem
                onClick={(): void => setUserLocale(LanguageCode.GERMAN)}
                icon={<FlagDE />}
                text="Deutsch"
                showTooltip={false}
                className="h-10"
                isSubMenuItem
              />
              <NavItem
                onClick={(): void => setUserLocale(LanguageCode.ITALIAN)}
                icon={<FlagIT />}
                text="Italiano"
                showTooltip={false}
                className="h-10"
                isSubMenuItem
              />
              <NavItem
                onClick={(): void => setUserLocale(LanguageCode.SPANISH)}
                icon={<FlagES />}
                text="Español"
                showTooltip={false}
                className="h-10"
                isSubMenuItem
              />
              <NavItem
                onClick={(): void => setUserLocale(LanguageCode.TURKISH)}
                icon={<FlagTR />}
                text="Türkçe"
                showTooltip={false}
                className="h-10"
                isSubMenuItem
              />
              <NavItem
                onClick={(): void => setUserLocale(LanguageCode.CHINESE)}
                icon={<FlagZH />}
                text="中文"
                showTooltip={false}
                className="h-10"
                isSubMenuItem
              />
            </NavMenu>
          )}
          <NavItem
            as={isLoggedIn ? Link : Button}
            to="/settings"
            onClick={isLoggedIn ? undefined : (): void => openLoginModal()}
            active={location.pathname.includes('settings')}
            icon={Icon.type.SETTINGS}
            text={$t({ id: 'General.settings' })}
          />

          {isLoggedIn ? (
            <NavItem
              onClick={(): void => {
                setExpanded(false);
                logout();
              }}
              icon={Icon.type.LOGOUT}
              text={$t({ id: 'General.logout' })}
            />
          ) : (
            <NavItem
              onClick={(): void =>
                openLoginModal({
                  onLogin: () => setExpanded(false),
                })
              }
              icon={Icon.type.LOGIN}
              text={$t({ id: 'General.login' })}
              dataTestId="sidenav__login-button"
            />
          )}
        </div>
      </nav>
    </aside>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/browser';

import { toast } from './toast';

export interface HandleErrorOptions {
  avoidSentry?: boolean;
  avoidFlashMessage?: boolean;
  userErrorMessage?: string;
  /** Additional scope data to apply to exception event. */
  additionalValues?: Record<string, unknown>;
}

class ErrorHandler {
  capture(error: any, options: HandleErrorOptions = {}): void {
    if (!options.avoidSentry) {
      Sentry.captureException(error, {
        extra: { ...options.additionalValues, error },
      });
    }

    const isForbiddenByTerms =
      error.status === 403 && error.body.reason === 'GTC_NOT_ACCEPTED';
    if (!isForbiddenByTerms && !options?.avoidFlashMessage) {
      toast({
        appearance: 'danger',
        message: options?.userErrorMessage || 'Unexpected error',
      });
    }

    if (
      import.meta.env.MODE !== 'production' &&
      import.meta.env.MODE !== 'demo' &&
      import.meta.env.MODE !== 'test'
    ) {
      // eslint-disable-next-line no-console
      console.error(error, options);
    }
  }
}

export const errorHandler = new ErrorHandler();

import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { Icon } from '@/core/components/component-library/Icon/Icon';
import { cn } from '@/core/libs/utils';

const badgeVariants = cva(
  'inline-flex cursor-default items-center rounded-full px-3 py-1 text-base font-normal',
  {
    variants: {
      color: {
        primary: 'bg-primary-500 text-secondary-900',
        primaryLight: 'bg-primary-100 text-primary-900',
        secondary: 'bg-secondary-900 text-secondary-50',
        secondary800: 'bg-secondary-800 text-secondary-50',
        secondaryLight: 'bg-secondary-200 text-secondary-800',
        neutral: 'bg-neutral-200 text-neutral-900',
        danger: 'bg-danger-dark text-danger-50',
        dangerLight: 'bg-danger-100 text-danger-dark',
        warningLight: 'bg-warning-100 text-warning-dark',
        successLight: 'bg-success-100 text-success-dark',
      },
      size: {
        indicator: 'h-3 w-12 rounded-full',
        xxs: 'rounded px-1 py-0 text-xs',
        xs: 'rounded-lg px-2 text-xs',
        sm: 'rounded-lg px-4 py-1 text-xs',
        md: 'rounded-xl px-4 py-2 text-sm',
        lg: 'rounded-2xl px-6 py-4 text-lg font-medium',
      },
    },
    compoundVariants: [
      {
        color: 'secondary',
        size: 'md',
        className: 'bg-secondary-900 text-secondary-50',
      },
    ],
    defaultVariants: {
      color: 'secondary',
      size: 'md',
    },
  },
);

export interface BadgeProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'>,
    VariantProps<typeof badgeVariants> {
  isRemovable?: boolean;
  onRemove?: () => void;
}

function Badge({
  className,
  color,
  size,
  isRemovable = false,
  onRemove,
  children,
  ...props
}: BadgeProps) {
  return (
    <div
      className={cn(
        badgeVariants({ color, size }),
        isRemovable && 'gap-2',
        className,
      )}
      {...props}
    >
      {children}
      {isRemovable && (
        <button
          onClick={onRemove}
          className={cn(
            size === 'sm' && 'size-4 text-sm',
            size === 'md' && 'size-5 text-base',
            'flex items-center justify-center',
          )}
        >
          <Icon type={Icon.type.X_CROSS} />
        </button>
      )}
    </div>
  );
}

export { Badge, badgeVariants };

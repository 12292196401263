import { useQuery } from '@tanstack/react-query';

import { AnalyticsService, InvitationsService } from '@/generated/api';

export function useInvitationsThisMonth(isLoggedIn: boolean) {
  return useQuery({
    queryKey: ['invitationsThisMonth'],
    enabled: isLoggedIn,
    queryFn: () =>
      InvitationsService.invitationsControllerCountFreeInvitationsThisMonth(),
  });
}

export function useGetManufacturerAnalytics() {
  return useQuery({
    queryKey: ['getManufacturerAnalytics'],
    queryFn: () => AnalyticsService.analyticsControllerUserAnalytics(),
  });
}

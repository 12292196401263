import React from 'react';
import { Outlet } from 'react-router-dom';

import { errorHandler } from '@/core/libs/error-handler';
import { Address } from '@/generated/api';

type CheckoutForm = {
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  vat: string | null;
  country: string | null;
  streetAndNumber: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
};

export type CheckoutContextType = {
  checkoutForm: CheckoutForm;
  setCheckoutForm: (values: CheckoutForm) => void;
  companyAddress: Address | null;
  companyBillingAddress: Address | null;
  setCompanyAddress: (address: Address | null) => void;
  setCompanyBillingAddress: (address: Address | null) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  reset: () => void;
};

const CheckoutContext = React.createContext<CheckoutContextType>({
  checkoutForm: {
    firstName: null,
    lastName: null,
    companyName: null,
    vat: null,
    country: null,
    streetAndNumber: null,
    zipCode: null,
    city: null,
    state: null,
  },
  setCheckoutForm: async () => {
    errorHandler.capture(
      'setForm() being used without initializing Checkout context',
      { avoidFlashMessage: true },
    );
    throw Error();
  },
  companyAddress: null,
  companyBillingAddress: null,
  setCompanyAddress: async () => {
    errorHandler.capture(
      'setCompanyAddress() being used without initializing Checkout context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  setCompanyBillingAddress: async () => {
    errorHandler.capture(
      'setCompanyBillingAddress() being used without initializing Checkout context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  isLoading: false,
  setIsLoading: async () => {
    errorHandler.capture(
      'setIsLoading() being used without initializing Checkout context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  reset: async () => {
    errorHandler.capture(
      'reset() being used without initializing Checkout context',
      { avoidFlashMessage: true },
    );
    throw Error();
  },
});

export function CheckoutContextProvider(): JSX.Element {
  const [checkoutForm, setCheckoutForm] = React.useState<CheckoutForm>({
    firstName: null,
    lastName: null,
    companyName: null,
    vat: null,
    country: null,
    streetAndNumber: null,
    zipCode: null,
    city: null,
    state: null,
  });
  const [companyAddress, setCompanyAddress] = React.useState<Address | null>(
    null,
  );
  const [companyBillingAddress, setCompanyBillingAddress] =
    React.useState<Address | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const reset = React.useCallback(() => {
    setCheckoutForm({
      firstName: null,
      lastName: null,
      companyName: null,
      vat: null,
      country: null,
      streetAndNumber: null,
      zipCode: null,
      city: null,
      state: null,
    });
    setCompanyAddress(null);
    setCompanyBillingAddress(null);
  }, []);

  const value = React.useMemo(
    () => ({
      checkoutForm,
      setCheckoutForm,
      companyAddress,
      companyBillingAddress,
      setCompanyAddress,
      setCompanyBillingAddress,
      isLoading,
      setIsLoading,
      reset,
    }),
    [checkoutForm, companyAddress, companyBillingAddress, isLoading, reset],
  );

  return (
    <CheckoutContext.Provider value={value}>
      <Outlet />
    </CheckoutContext.Provider>
  );
}

export function useCheckoutContext(): CheckoutContextType {
  return React.useContext(CheckoutContext);
}

import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { SearchableConfigurationImpl } from '@/modules/matchmaking/models/searchbar/impl/SearchableConfigurationImpl';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';

export class SearchableRegionImpl extends SearchableConfigurationImpl {
  /**
   * This might be replaced with actual SearchableConfigurations of countries and therefore make this class redundant.
   */
  readonly countries: string[];

  constructor(
    category: CategoryEnum,
    id: string,
    relatives: SearchableConfiguration[],
    searchWeight: number,
    aliases: string[],
    translationKey: string,
    weightInsideCategory: number,
    descriptionTranslationKey: string,
    countries: string[],
  ) {
    super(
      category,
      id,
      relatives,
      searchWeight,
      aliases,
      translationKey,
      weightInsideCategory,
      descriptionTranslationKey,
    );
    this.countries = countries;
  }

  override searchableValues(): string[] {
    return this.countries;
  }
}

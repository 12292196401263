import { createContext, useContext, useEffect, useState } from 'react';

// Reference from @/core/styles/variables.css
export enum Breakpoints {
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1400,
}

export interface WindowSize {
  width: number;
  height: number;
}

export interface BreakpointContext {
  breakpoint: Breakpoints;
  windowSize: WindowSize;
}

const BreakpointContext = createContext<BreakpointContext>({
  breakpoint: Breakpoints.XXL,
  windowSize: { width: 0, height: 0 },
});

export interface BreakpointContextProviderProps {
  children: JSX.Element;
}

export function BreakpointProvider({
  children,
}: BreakpointContextProviderProps): JSX.Element {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [breakpoint, setBreakpoint] = useState<Breakpoints>(
    getBreakpointFromWidth(windowSize.width),
  );

  function getBreakpointFromWidth(width: number): Breakpoints {
    if (width <= Breakpoints.SM) return Breakpoints.SM;
    if (width <= Breakpoints.MD) return Breakpoints.MD;
    if (width <= Breakpoints.LG) return Breakpoints.LG;
    if (width <= Breakpoints.XL) return Breakpoints.XL;
    return Breakpoints.XXL;
  }

  useEffect(() => {
    const callback = (): void => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', callback);

    return () => window.removeEventListener('resize', callback);
  }, []);

  useEffect((): void => {
    const newBreakpoint = getBreakpointFromWidth(windowSize.width);
    if (newBreakpoint !== breakpoint) {
      setBreakpoint(newBreakpoint);
    }
  }, [windowSize, breakpoint]);

  return (
    <BreakpointContext.Provider
      value={{
        breakpoint,
        windowSize,
      }}
    >
      {children}
    </BreakpointContext.Provider>
  );
}

export function useBreakpoint(): BreakpointContext {
  return useContext(BreakpointContext);
}

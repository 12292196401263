export default function PartfoxLogo(
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="132"
      height="26"
      viewBox="0 0 132 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1227_10076)">
        <path
          d="M57.5041 9.43074C56.9943 8.33625 56.2796 7.49024 55.3601 6.89096C54.4406 6.29227 53.3527 5.99292 52.0964 5.99292C50.9493 5.99292 49.9797 6.29227 49.1877 6.89096C48.6927 7.26573 48.2705 7.73639 47.9211 8.30468L47.7538 6.32969H44.3672V25.9744H47.9723V18.4626C48.3114 18.9982 48.7166 19.4443 49.1877 19.8003C49.9797 20.399 50.9493 20.6984 52.0964 20.6984C53.3527 20.6984 54.4406 20.399 55.3601 19.8003C56.2796 19.2016 56.9943 18.351 57.5041 17.2465C58.0139 16.1427 58.2688 14.8424 58.2688 13.3457C58.2688 11.8489 58.0133 10.5252 57.5041 9.43074ZM54.0901 15.3943C53.7988 15.9936 53.4028 16.4607 52.9021 16.7975C52.4014 17.1343 51.8233 17.3027 51.1678 17.3027C50.5487 17.3027 49.9974 17.1343 49.5154 16.7975C49.0329 16.4607 48.6551 15.9936 48.382 15.3943C48.1089 14.7956 47.9723 14.1127 47.9723 13.3457C47.9723 12.5786 48.1089 11.9004 48.382 11.311C48.6551 10.7217 49.0375 10.2586 49.5291 9.92186C50.0207 9.58509 50.576 9.41671 51.1951 9.41671C51.8506 9.41671 52.4281 9.581 52.9294 9.90783C53.4301 10.2352 53.821 10.6983 54.1038 11.297C54.386 11.8963 54.5271 12.5786 54.5271 13.3457C54.5271 14.1127 54.3809 14.7956 54.0901 15.3943Z"
          fill={props.fill}
        />
        <path
          d="M69.6305 6.32969V8.1819C69.2971 7.66857 68.9011 7.23825 68.4424 6.89096C67.6504 6.29227 66.6808 5.99292 65.5338 5.99292C64.2774 5.99292 63.1889 6.29227 62.27 6.89096C61.3505 7.49024 60.6359 8.33625 60.1261 9.43074C59.6162 10.5252 59.3613 11.8302 59.3613 13.3457C59.3613 14.8611 59.6162 16.1427 60.1261 17.2465C60.6359 18.3504 61.3505 19.2016 62.27 19.8003C63.1895 20.399 64.2774 20.6984 65.5338 20.6984C66.6808 20.6984 67.6504 20.399 68.4424 19.8003C68.9244 19.4361 69.3363 18.9783 69.68 18.427L69.8217 20.3616H73.2356V6.32969H69.6305ZM68.1147 16.7975C67.6322 17.1343 67.0808 17.3027 66.4624 17.3027C65.8069 17.3027 65.2288 17.1343 64.7281 16.7975C64.2273 16.4607 63.8313 15.9936 63.54 15.3943C63.2487 14.7956 63.103 14.1127 63.103 13.3457C63.103 12.5786 63.2487 11.8963 63.54 11.297C63.8313 10.6983 64.2228 10.2352 64.7144 9.90783C65.206 9.58042 65.7796 9.41671 66.435 9.41671C67.0905 9.41671 67.6322 9.581 68.1147 9.90783C68.5966 10.2352 68.975 10.6983 69.2481 11.297C69.4513 11.7419 69.5782 12.2336 69.6305 12.7715V13.9198C69.5782 14.4577 69.4513 14.9494 69.2481 15.3943C68.975 15.9936 68.5966 16.4607 68.1147 16.7975Z"
          fill={props.fill}
        />
        <path
          d="M80.7688 7.0031C79.9711 7.43399 79.3025 8.06251 78.7614 8.88805V6.32957H75.1562V20.3615H78.7614V12.9807C78.7614 12.251 78.989 11.6523 79.4442 11.1846C79.8994 10.7169 80.4957 10.3807 81.2331 10.1743C81.9705 9.96851 82.758 9.86561 83.5955 9.86561V6.27344C82.6123 6.27344 81.6701 6.51666 80.7688 7.0031Z"
          fill={props.fill}
        />
        <path
          d="M93.275 16.7694C93.0383 16.9378 92.8107 17.064 92.5922 17.1482C92.3738 17.2324 92.1735 17.2745 91.9914 17.2745C91.4998 17.2745 91.1265 17.1202 90.8716 16.8115C90.6161 16.5028 90.4893 16.0497 90.4893 15.4504V9.47278H93.9032V6.32962H90.4893V1.95166H86.8841V6.32962H84.6719V9.47278H86.8841V16.2642C86.8841 17.6861 87.2705 18.7806 88.0449 19.5477C88.8187 20.3148 89.9607 20.6983 91.4725 20.6983C92.0005 20.6983 92.5331 20.5907 93.0702 20.3756C93.6068 20.1604 94.0944 19.8845 94.5314 19.5477L93.275 16.7694Z"
          fill={props.fill}
        />
        <path
          d="M100.95 5.54379C100.95 4.77671 101.068 4.22011 101.305 3.87399C101.542 3.52787 101.906 3.35481 102.398 3.35481C102.598 3.35481 102.816 3.39281 103.053 3.46707C103.29 3.5419 103.554 3.65416 103.845 3.80383L105.183 1.13776C104.692 0.875836 104.15 0.651325 103.558 0.464232C102.967 0.27714 102.407 0.183594 101.879 0.183594C100.349 0.183594 99.2113 0.599874 98.4648 1.43244C97.7183 2.265 97.345 3.42965 97.345 4.92639V6.32958H95.0781V9.30435H97.345V20.3615H100.95V9.30435H104.309V6.32958H100.95V5.54379Z"
          fill={props.fill}
        />
        <path
          d="M111.765 20.6984C110.363 20.6984 109.12 20.385 108.037 19.7582C106.954 19.1315 106.102 18.2662 105.483 17.1623C104.864 16.0585 104.555 14.7868 104.555 13.3457C104.555 11.9045 104.859 10.6328 105.47 9.52897C106.08 8.42512 106.926 7.55982 108.01 6.93306C109.093 6.3063 110.327 5.99292 111.71 5.99292C113.094 5.99292 114.378 6.3063 115.452 6.93306C116.526 7.5604 117.368 8.42512 117.978 9.52897C118.588 10.6328 118.893 11.905 118.893 13.3457C118.893 14.7863 118.588 16.0585 117.978 17.1623C117.368 18.2668 116.531 19.1315 115.466 19.7582C114.401 20.385 113.167 20.6984 111.765 20.6984ZM111.765 17.0781C112.402 17.0781 112.962 16.9197 113.445 16.6011C113.927 16.283 114.305 15.8433 114.578 15.2821C114.851 14.7208 114.988 14.0753 114.988 13.3457C114.988 12.616 114.847 11.9705 114.564 11.4092C114.282 10.848 113.895 10.4089 113.404 10.0902C112.912 9.77219 112.348 9.61316 111.71 9.61316C111.073 9.61316 110.536 9.77219 110.044 10.0902C109.553 10.4089 109.17 10.848 108.897 11.4092C108.624 11.9705 108.488 12.616 108.488 13.3457C108.488 14.0753 108.624 14.7208 108.897 15.2821C109.17 15.8433 109.557 16.283 110.058 16.6011C110.559 16.9191 111.127 17.0781 111.765 17.0781Z"
          fill={props.fill}
        />
        <path
          d="M131.999 20.3615L126.965 13.1094L131.671 6.32959H127.411L124.966 10.0884L122.522 6.32959H118.261L122.99 13.0737L117.906 20.3615H122.221L124.985 16.0923L127.711 20.3615H131.999Z"
          fill={props.fill}
        />
        <path
          d="M14.1252 26H19.6524V20.3206H14.1252V26ZM4.91325 15.7766H8.59805V10.0971H14.1252V6.31086L0 0L4.91325 15.7766ZM19.653 6.31086V10.0971H25.1802V15.7766H28.865L33.7782 0L19.653 6.31086Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1227_10076">
          <rect width="132" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

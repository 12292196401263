export default function PartfoxIcon(
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="36"
      height="26"
      viewBox="0 0 36 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1361 26H20.8633V20.3206H15.1361V26ZM5.59096 15.7766H9.40904V10.0971H15.1361V6.31086L0.5 0L5.59096 15.7766ZM20.8639 6.31086V10.0971H26.591V15.7766H30.409L35.5 0L20.8639 6.31086Z"
        fill={props.fill}
      />
    </svg>
  );
}

export function getHighestValue(
  value1?: null | number,
  value2?: null | number,
): number | undefined {
  const val1 = value1 ?? undefined;
  const val2 = value2 ?? undefined;
  if (val1 !== undefined) {
    return val2 === undefined || val1 > val2 ? val1 : val2;
  }
  return val2;
}

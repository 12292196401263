import FuzzySet from 'fuzzyset';
import { IntlFormatters } from 'react-intl';

import { cleanRegExp } from '@/core/libs/regex';
import staticIntl from '@/core/libs/static-intl';
import { meetsFilterException, normalizeText } from '@/core/libs/utils';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';
import {
  SearchFilterSearchValues,
  WeightedMatch,
} from '@/modules/matchmaking/types';

export class FuzzyMatcher<T extends SearchableConfiguration> {
  private get $t(): IntlFormatters['$t'] {
    return staticIntl.instance.$t;
  }
  private readonly labelKey: string;
  private translatedLabelKey = '';
  private readonly items: T[];
  private fuzzyMap: Record<string, T> = {};
  private fuzzySet: FuzzySet = FuzzySet();

  private generateSet(): void {
    const label = this.$t({ id: this.labelKey });
    // We check for language change
    if (label !== this.translatedLabelKey) {
      this.translatedLabelKey = label;
      this.fuzzySet = FuzzySet();
      this.fuzzyMap = {};

      this.items.forEach((item) => {
        const smallKey = normalizeText(this.$t({ id: item.translationKey }));
        const longKey = normalizeText(
          `${this.$t({
            id: this.labelKey,
          })} ${smallKey}`,
        );
        this.fuzzyMap[smallKey] = item;
        this.fuzzyMap[longKey] = item;
        this.fuzzySet.add(smallKey);
        this.fuzzySet.add(longKey);
      });
    }
  }

  constructor(labelKey: string, items: T[]) {
    this.labelKey = labelKey;
    this.items = items;
  }

  /**
   * @return Returns 0.9 if search term and label start with the exact same characters.
   */
  static strictMatchWeight(search: string, text: string): number {
    if (
      text.toLowerCase().startsWith(search.toLowerCase()) ||
      meetsFilterException(search, text)
    ) {
      return 0.9; // exact matches must be on top
    } else if (text.indexOf(search) > 0) {
      return 0.89999999; // exact matches must be on top
    } else {
      const cleanText = cleanRegExp(search);
      const fragments = cleanText
        .split(/\s/u)
        .map((frag) => `^${frag}|\\s${frag}`)
        .join('|');
      const fullMatch = new RegExp(
        `(^${cleanText}|\\s${cleanText}|${fragments})`,
        'iug',
      );

      const matchItems = text.match(fullMatch)?.length;

      // so shuffled values like "3 axis tur" matches "Turning 3+ axis"
      return matchItems ? 0.5 + 0.2 * matchItems : 0;
    }
  }

  /**
   * @return list of weighted matches where the weight is possibly higher than 1.0
   */
  getMatches(
    { fullText = '' }: SearchFilterSearchValues,
    maxSuggestions: number | null = 4,
  ): WeightedMatch<T>[] | null {
    this.generateSet();

    const matches =
      this.fuzzySet
        .get(fullText, null, 0)
        ?.reduce<WeightedMatch<T>[]>((list, [weight, key]) => {
          if (!list.some(([, value]) => value === this.fuzzyMap[key])) {
            const strictMatchWeight: number = FuzzyMatcher.strictMatchWeight(
              fullText,
              key,
            );
            let finalWeight = 0;
            if (strictMatchWeight >= 0.89) {
              finalWeight =
                this.fuzzyMap[key].searchWeight / 10 + strictMatchWeight;
            } else {
              finalWeight = weight / 10;
            }
            list.push([finalWeight, this.fuzzyMap[key]]);
          }
          return list;
        }, [])
        .sort(([weightA], [weightB]) => weightB - weightA) || [];

    const sliced =
      maxSuggestions !== null ? matches.slice(0, maxSuggestions) : matches;

    return sliced.length ? sliced : null;
  }
}

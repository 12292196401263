/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LoginResponseDto = {
  provider_token: string;
  access_token: string;
  expires_in: number;
  expires_at: number;
  refresh_token: string;
  token_type: string;
  user: Record<string, any>;
  /**
   * True if this is the first login of the user
   */
  isFirstLogin: boolean;
  /**
   * The type of the user
   */
  userType: LoginResponseDto.userType;
  /**
   * The name of the membership plan
   */
  membershipPlan: LoginResponseDto.membershipPlan | null;
};

export namespace LoginResponseDto {
  /**
   * The type of the user
   */
  export enum userType {
    MANUFACTURER = 'manufacturer',
    BUYER = 'buyer',
    BOTH = 'both',
  }

  /**
   * The name of the membership plan
   */
  export enum membershipPlan {
    TIER0 = 'TIER0',
    TIER1 = 'TIER1',
    TIER2 = 'TIER2',
    TIER3 = 'TIER3',
  }
}

import dayjs, { Dayjs } from 'dayjs';

import { errorHandler } from '@/core/libs/error-handler';
import staticIntl from '@/core/libs/static-intl';

export function formatNumber(
  value: string | number | null = 0,
  options?: Intl.NumberFormatOptions,
): string {
  return parseFloat((value ?? '0').toString()).toLocaleString(
    undefined, // This takes the browser locale
    {
      style: 'decimal',
      ...options,
    },
  );
}

export function formatAmount(
  value: string | number | null = 0,
  options: Intl.NumberFormatOptions & {
    currencyDisplay?: 'symbol' | 'code' | 'name' | 'narrowSymbol' | 'none';
  } = {},
): string {
  let finalOptions = options;
  if (
    options.currencyDisplay === 'none' &&
    options.maximumFractionDigits === undefined &&
    options.minimumFractionDigits === undefined &&
    options.currency
  ) {
    const { maximumFractionDigits, minimumFractionDigits } =
      new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: options.currency,
      }).resolvedOptions();
    finalOptions = {
      ...options,
      currencyDisplay: undefined, // We reset to undefined because none is not standard
      style: 'decimal',
      maximumFractionDigits,
      minimumFractionDigits,
    };
  }
  return formatNumber(value, {
    currencyDisplay: 'narrowSymbol',
    style: finalOptions.currency ? 'currency' : finalOptions.style,
    ...finalOptions,
  });
}

export function formatDate(
  date?: string | number | Date | Dayjs,
  options?: Intl.DateTimeFormatOptions,
): string {
  if (date === undefined) return '';
  const finalDate = dayjs.utc(date).toDate();
  let locale;
  // variable assignment is wrapped in a try block, since "instance" getter might throw error
  try {
    locale = staticIntl.instance.locale;
  } catch (e) {
    errorHandler.capture(e, { avoidFlashMessage: true });
  }
  return finalDate.toLocaleString(locale, {
    dateStyle: 'medium',
    ...options,
  });
}

export function nFormatter(num: number, digits: number): string {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((n) => {
      return num >= n.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

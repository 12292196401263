import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { PageLoader } from '@/core/components/layout/page-loader';
import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import TermsConditionsModal from '@/modules/user-management/components/TermsConditionsModal';
import { User } from '@/generated/api';

const UserAuthenticatedContext = React.createContext({} as User);

export function useUserAuthenticatedContext(): User {
  return useContext(UserAuthenticatedContext);
}

export default function UserAuthenticatedProvider(): JSX.Element {
  const {
    openLoginModal,
    isLoading,
    isLoggedIn,
    currentUser,
    getUserData: refreshUserData,
  } = useAuthenticationContext();

  useEffect(() => {
    if (!isLoading && !isLoggedIn && !currentUser) {
      openLoginModal();
    }
  }, [isLoading, isLoggedIn, currentUser, openLoginModal]);

  if (!isLoading && !isLoggedIn) {
    return <></>;
  }

  if ((isLoading && !isLoggedIn) || !currentUser) {
    return <PageLoader />;
  }

  return (
    <UserAuthenticatedContext.Provider value={currentUser}>
      <>
        <Outlet />
        {!currentUser.termsAccepted && (
          <TermsConditionsModal isOpen={true} onSubmit={refreshUserData} />
        )}
      </>
    </UserAuthenticatedContext.Provider>
  );
}

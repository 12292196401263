import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { regions } from '@orderfoxag/reference-data';
import { useIntl } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { useUserTracking } from '@/core/hooks/useUserTracking';
import { errorHandler } from '@/core/libs/error-handler';
import { SearchQuery } from '@/modules/matchmaking/models/searchbar/SearchQuery';
import {
  AnalyzedPart,
  AnalyzedPartNamed,
  MatchedManufacturer,
  Workpiece,
} from '@/modules/matchmaking/types';
import {
  RFQ_REQUEST_ACTION_TYPE,
  rfqRequestReducer,
  rfqRequestReducerInitialState,
} from '@/modules/rfq-creation/reducers/rfq-request-reducer';
import {
  BookmarkManufacturersDTO,
  Company,
  ProjectEmailScheduleDto,
  ProjectRequest,
  ProjectsService,
  SelectedManufacturerRequest,
} from '@/generated/api';

import { useManufacturerLists } from '../../manufacturers/services/queries';
import { useCreateRFQ } from '../hooks/use-create-rfq';
import { useRefetchManufacturersForWorkpieces } from '../hooks/use-refetch-manufacturers-for-workpieces';
import { useProjectEmailScheduleConfig } from '../services/queries';
import { partToWorkpiece } from '../utils/split-files-to-workpieces';

export type RFQCreationContextType = {
  searchQuery: SearchQuery;
  setSearchQuery: React.Dispatch<React.SetStateAction<SearchQuery>>;
  manufacturers: MatchedManufacturer[]; // List of manufacturers that match the search query that come from the Search API
  suggestedManufacturers: MatchedManufacturer[]; // If no manufacturers are found, the Search API will return a list of suggested manufacturers
  blockedManufacturers: (BookmarkManufacturersDTO & { name: string | null })[]; // List of manufacturers that are blocked by the user
  setBlockedManufacturers: React.Dispatch<
    React.SetStateAction<(BookmarkManufacturersDTO & { name: string | null })[]>
  >;
  rfqRequest: Partial<ProjectRequest>;
  dispatchRfqRequest: React.Dispatch<RFQ_REQUEST_ACTION_TYPE>;
  workpieceList: Workpiece[];
  updateWorkpieceListAndSearchQueryAndRFQRequest(
    list: Workpiece[],
    query?: SearchQuery,
  ): void;
  isLoading: boolean;
  refetchManufacturers(bm: BookmarkManufacturersDTO[]): Promise<{
    manufacturers: MatchedManufacturer[];
    suggestedManufacturers: MatchedManufacturer[];
    // allManufacturers: MatchedManufacturerLight[];
  }>;
  startRawRequest(): void;
  startSearchRequest(
    fileList: AnalyzedPartNamed[],
    searchQuery: SearchQuery,
  ): void;
  startRepublishRequest(rfqId: number): void;
  createNewRFQ(
    publishAs: Company | null,
    isPrivate: boolean,
    tenderArea: string[],
    certifications: string[],
    selectedManufacturers: SelectedManufacturerRequest[],
  ): Promise<void>;
  rfqAnalyzedFiles: AnalyzedPart[];
  setRfqAnalyzedFiles: React.Dispatch<React.SetStateAction<AnalyzedPart[]>>;
  isRepublishing: boolean;
  origin: 'search' | 'my-requests';
  isSniping: boolean;
  setIsSniping: React.Dispatch<React.SetStateAction<boolean>>;
  projectEmailScheduleConfig: ProjectEmailScheduleDto | undefined;
};

const RFQCreationContext = createContext<RFQCreationContextType>({
  searchQuery: {},
  setSearchQuery: () => {},
  manufacturers: [],
  suggestedManufacturers: [],
  blockedManufacturers: [],
  setBlockedManufacturers: async () => {
    errorHandler.capture(
      'setBlockedManufacturers() being used without initializing RFQCreation context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  rfqRequest: rfqRequestReducerInitialState,
  dispatchRfqRequest: () => {},
  workpieceList: [],
  updateWorkpieceListAndSearchQueryAndRFQRequest: () => {},
  isLoading: false,
  refetchManufacturers: async () => {
    errorHandler.capture(
      'refetchManufacturers() being used without initializing RFQCreation context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  startRawRequest: () => {
    errorHandler.capture(
      'startRawRequest() being used without initializing RFQCreation context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  startSearchRequest: () => {
    errorHandler.capture(
      'startSearchRequest() being used without initializing RFQCreation context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  startRepublishRequest: () => {
    errorHandler.capture(
      'startRepublishRequest() being used without initializing RFQCreation context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  createNewRFQ() {
    errorHandler.capture(
      'createNewRFQ() being used without initializing RFQCreation context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  rfqAnalyzedFiles: [],
  setRfqAnalyzedFiles: () => {},
  isRepublishing: false,
  origin: 'search',
  isSniping: false,
  setIsSniping() {
    errorHandler.capture(
      'setIsSniping() being used without initializing RFQCreation context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  projectEmailScheduleConfig: undefined,
});

export interface RFQCreationContextProps {
  children?: React.ReactNode;
}

export function RFQCreationProvider({
  children,
}: RFQCreationContextProps): JSX.Element {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const { currentUser } = useAuthenticationContext();

  const [searchQuery, setSearchQuery] = React.useState<SearchQuery>({});
  const [manufacturers, setManufacturers] = React.useState<
    MatchedManufacturer[]
  >([]);
  const [suggestedManufacturers, setSuggestedManufacturers] = React.useState<
    MatchedManufacturer[]
  >([]);
  const [blockedManufacturers, setBlockedManufacturers] = React.useState<
    (BookmarkManufacturersDTO & { name: string | null })[]
  >([]);
  const [rfqAnalyzedFiles, setRfqAnalyzedFiles] = React.useState<
    AnalyzedPart[]
  >([]);
  const { data: projectEmailScheduleConfig } = useProjectEmailScheduleConfig();

  const [rfqRequest, dispatchRfqRequest] = useReducer(
    rfqRequestReducer,
    rfqRequestReducerInitialState,
  );
  const [workpieceList, setWorkpieceList] = useState<Workpiece[]>([]);
  const [isRepublishing, setIsRepublishing] = useState(false);
  const [origin, setOrigin] = useState<'search' | 'my-requests'>('search');
  const [isSniping, setIsSniping] = useState(false);

  const { trackUserEvent, TrackerEvents } = useUserTracking();

  const { fetchManufacturers, isLoading: isFetchingManufacturers } =
    useRefetchManufacturersForWorkpieces(workpieceList, searchQuery);
  const { createRFQ, loading: isCreatingRFQ } = useCreateRFQ();

  const { refetch } = useManufacturerLists();

  const getCountriesFromQuery = (query: SearchQuery): string[] => {
    const queryCountries = [...(query.countries ?? [])];
    for (const region of query.regions || []) {
      const refRegion = regions.find((r) => r.id === region);
      if (refRegion) queryCountries.push(...refRegion.countries);
    }
    return [...new Set(queryCountries)];
  };

  const syncBlockedManufacturers = useCallback(async () => {
    const res = await refetch();
    const bm: (BookmarkManufacturersDTO & { name: string | null })[] = [];
    res.data?.forEach((ml) => {
      ml.manufacturers.forEach((m) => {
        if (ml.addToBlocklist) {
          bm.push({ ...m, name: m.name });
        }
      });
    });

    // Remove duplicates based on companyId (if not null) or encryptedUrl
    const uniqueBlockedManufacturers = bm.filter(
      (m, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            (m.companyId !== null && t.companyId === m.companyId) ||
            (m.companyId === null && t.encryptedUrl === m.encryptedUrl),
        ),
    );
    setBlockedManufacturers(uniqueBlockedManufacturers);
  }, [refetch]);

  const startRawRequest = useCallback(() => {
    setOrigin('my-requests');
    setWorkpieceList([]);
    setRfqAnalyzedFiles([]);
    setSearchQuery({});
    dispatchRfqRequest({
      type: 'SET_INITIAL_STATE',
      payload: null,
    });
    setIsRepublishing(false);
    setIsSniping(false);
    syncBlockedManufacturers();

    navigate('/create-request');
  }, [navigate, syncBlockedManufacturers]);

  const startSearchRequest = useCallback(
    (files: AnalyzedPartNamed[], query: SearchQuery) => {
      setOrigin('search');
      setIsRepublishing(false);
      setIsSniping(false);
      trackUserEvent(TrackerEvents.CREATE_RFQ_REQUEST_INIT);

      dispatchRfqRequest({
        type: 'SET_INITIAL_STATE',
        payload: null,
      });

      // We prefill the searchQuery with the fields not related to workpieces
      // Those will be filled automatically as the setAnalyzedFiles
      // triggers the update of the workpiece list
      setSearchQuery((prev) => ({
        ...prev,
        countries: query?.countries ?? prev.countries,
        regions: query?.regions ?? prev.regions,
        certifications: query?.certifications ?? prev.certifications,
      }));
      setWorkpieceList([]);
      setRfqAnalyzedFiles(files);
      syncBlockedManufacturers();

      // We prefill the certifications and countries
      // with the filters preselected in the search page
      if (query.certifications)
        dispatchRfqRequest({
          type: 'SET_CERTIFICATIONS',
          payload: query.certifications,
        });
      if (query.countries || query.regions) {
        const queryCountries = getCountriesFromQuery(query);
        setSearchQuery((prev) => ({
          ...prev,
          countries: queryCountries,
          regions: [],
        }));

        dispatchRfqRequest({
          type: 'SET_TENDER_AREA',
          payload: queryCountries,
        });
      }
      navigate('/create-request');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, $t],
  );

  const startRepublishRequest = useCallback(
    async (rfqId: number) => {
      setOrigin('my-requests');
      setIsRepublishing(true);

      const rfq = await ProjectsService.projectsControllerFind(rfqId, true);

      dispatchRfqRequest({
        type: 'SET_INITIAL_STATE',
        payload: rfq,
      });

      setSearchQuery((prev) => ({
        ...prev,
        countries: rfq.tenderArea,
        certifications: rfq.certifications ?? [],
      }));

      const workpieces = rfq.parts.map(partToWorkpiece);
      const analyzedFiles = workpieces.flatMap((w) => w.files);
      setWorkpieceList(workpieces);
      setRfqAnalyzedFiles(analyzedFiles);
      updateWorkpieceListAndSearchQueryAndRFQRequest(workpieces);

      navigate('/create-request');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, $t],
  );
  const updateWorkpieceListAndSearchQueryAndRFQRequest = (
    newWorkpieceList: Workpiece[],
  ) => {
    // 1. Update workpiece list
    setWorkpieceList(newWorkpieceList);

    // 2. Update search query
    const [materials, technologies] = newWorkpieceList.reduce(
      (acc, workpiece) => {
        acc[0].push(...(workpiece.materials?.map((m) => m.id) || []));
        acc[1].push(...(workpiece.technologies?.map((t) => t.id) || []));
        return acc;
      },
      [[], []] as [string[], string[]],
    );
    setSearchQuery((prev) => ({
      ...prev,
      materials: [...materials],
      technologies: [...technologies],
    }));

    // 3. Update rqfRequest
    dispatchRfqRequest({
      type: 'SET_PARTS',
      payload: newWorkpieceList,
    });
  };

  const refetchManufacturers = useCallback(
    async (bm: BookmarkManufacturersDTO[]) => {
      const res = await fetchManufacturers(bm);
      res.manufacturers = res.manufacturers.filter(
        (m) => m.partfox_id !== currentUser?.company.id,
      );
      setManufacturers(res.manufacturers);
      setSuggestedManufacturers(res.suggestedManufacturers);
      return res;
    },
    [currentUser?.company.id, fetchManufacturers],
  );

  const createNewRFQ = useCallback(
    async (
      publishAs: Company | null,
      isPrivate: boolean,
      tenderArea: string[],
      certifications: string[],
      selectedManufacturers: SelectedManufacturerRequest[],
    ) => {
      /* We wouldn't need to dispatch the RFQ request here because the RFQ request
       * is going to be sent to the API straight away
       */
      dispatchRfqRequest({
        type: 'SET_PUBLICATION_DATA',
        payload: {
          publishAs,
          isPrivate,
          tenderArea,
          certifications,
          selectedManufacturers,
        },
      });
      const newRequest = {
        ...rfqRequest,
        publishAs,
        isPrivate,
        tenderArea,
        certifications,
        selectedManufacturers,
        blockedManufacturers,
        isSniping,
      } as ProjectRequest;
      await createRFQ(newRequest);
    },
    [rfqRequest, blockedManufacturers, isSniping, createRFQ],
  );

  React.useEffect(() => {
    syncBlockedManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => {
    return {
      workpieceList,
      rfqRequest,
      searchQuery,
      manufacturers,
      suggestedManufacturers,
      blockedManufacturers,
      setBlockedManufacturers,
      updateWorkpieceListAndSearchQueryAndRFQRequest,
      dispatchRfqRequest,
      setSearchQuery,
      refetchManufacturers,
      startRawRequest,
      startSearchRequest,
      startRepublishRequest,
      isLoading: isFetchingManufacturers || isCreatingRFQ,
      createNewRFQ,
      rfqAnalyzedFiles,
      setRfqAnalyzedFiles,
      isRepublishing,
      origin,
      isSniping,
      setIsSniping,
      projectEmailScheduleConfig,
    };
  }, [
    workpieceList,
    rfqRequest,
    searchQuery,
    manufacturers,
    suggestedManufacturers,
    blockedManufacturers,
    refetchManufacturers,
    startRawRequest,
    startSearchRequest,
    startRepublishRequest,
    isFetchingManufacturers,
    isCreatingRFQ,
    createNewRFQ,
    rfqAnalyzedFiles,
    isRepublishing,
    origin,
    isSniping,
    projectEmailScheduleConfig,
  ]);

  return (
    <RFQCreationContext.Provider value={value}>
      {children || <Outlet />}
    </RFQCreationContext.Provider>
  );
}
RFQCreationContext.displayName = 'RFQCreationContext';

export const useRFQCreationContext = () => useContext(RFQCreationContext);

import { get } from 'fast-levenshtein';

import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { AbstractSearchableConfiguration } from '@/modules/matchmaking/models/searchbar/impl/AbstractSearchableConfiguration';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';

export class SearchableConfigurationImpl extends AbstractSearchableConfiguration {
  readonly category: CategoryEnum;
  readonly id: string;
  protected readonly relatives: SearchableConfiguration[];
  readonly searchWeight: number;
  protected readonly aliases: string[];
  readonly translationKey: string;
  protected readonly weightInsideCategory: number;
  readonly descriptionTranslationKey: string;

  public static categoryInternalWeightDivider = 100000;

  constructor(
    category: CategoryEnum,
    id: string,
    relatives: SearchableConfiguration[],
    searchWeight: number,
    aliases: string[],
    translationKey: string,
    weightInsideCategory: number,
    descriptionTranslationKey: string,
  ) {
    super();
    this.category = category;
    this.id = id;
    this.relatives = relatives;
    this.searchWeight = searchWeight;
    this.aliases = aliases;
    this.translationKey = translationKey;
    this.weightInsideCategory =
      weightInsideCategory /
      SearchableConfigurationImpl.categoryInternalWeightDivider;
    this.descriptionTranslationKey = descriptionTranslationKey;
  }

  /**
   * Evaluates the highest weighted matching label of this object.
   * @param searchTerm
   * @return Value between 0.0 and 1.0. When parameter is empty, the static search weight is used.
   */
  override matchingWeight(searchTerm: string): number {
    if (searchTerm.length <= 0) {
      return this.searchWeight;
    } else if (
      this.searchableValues().some((item) => item.startsWith(searchTerm))
    ) {
      return (
        0.9999 +
        this.searchWeight /
          SearchableConfigurationImpl.categoryInternalWeightDivider
      );
    } else if (
      this.searchableValues().some((item) => item.indexOf(searchTerm) > 0)
    ) {
      return (
        0.9999 +
        this.searchWeight /
          SearchableConfigurationImpl.categoryInternalWeightDivider
      );
    } else {
      let result = 0.0;
      for (const label in this.searchableValues()) {
        const distance = get(label, searchTerm);
        const maxLength = Math.max(label.length, searchTerm.length);
        const weight = 1 - distance / maxLength;
        result = weight > result ? weight : result;
      }
      return result;
    }
  }
}

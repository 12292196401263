import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { SearchableConfigurationImpl } from '@/modules/matchmaking/models/searchbar/impl/SearchableConfigurationImpl';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';
import { SearchableRange } from '@/modules/matchmaking/models/searchbar/SearchableRange';

export class SearchableRangeImpl
  extends SearchableConfigurationImpl
  implements SearchableRange
{
  readonly minValue: number;
  readonly maxValue: number;

  constructor(
    category: CategoryEnum,
    id: string,
    relatives: SearchableConfiguration[],
    searchWeight: number,
    aliases: string[],
    translationKey: string,
    weightInsideCategory: number,
    descriptionTranslationKey: string,
    minValue: number,
    maxValue: number,
  ) {
    super(
      category,
      id,
      relatives,
      searchWeight / SearchableConfigurationImpl.categoryInternalWeightDivider,
      aliases,
      translationKey,
      weightInsideCategory,
      descriptionTranslationKey,
    );
    this.minValue = minValue;
    this.maxValue = maxValue;
  }

  /**
   * @param searchTerm
   * @return Value between 0.0 and 1.0. When parameter is empty, the static search weight is used.
   */
  override matchingWeight(searchTerm: string): number {
    const extractedNumber =
      (searchTerm || '').match(/\d*\.?/gi)?.join('') ?? '';
    if (extractedNumber.length <= 0) {
      return this.searchWeight;
    } else {
      const parsedNumber = parseFloat(extractedNumber);
      const isNumber = !isNaN(parsedNumber);
      if (isNumber) {
        if (parsedNumber >= this.minValue) {
          if (parsedNumber <= this.maxValue) {
            return 0.8;
          } else {
            return Number.EPSILON;
          }
        } else {
          return Number.EPSILON;
        }
      } else {
        return 0.0;
      }
    }
  }
}

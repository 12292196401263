export default function FlagDE(
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1053_9624)">
        <path d="M0 10.6667H21.3333V16.0001H0V10.6667Z" fill="#FFCE00" />
        <path d="M0 0H21.3333V5.33333H0V0Z" fill="black" />
        <path d="M0 5.33325H21.3333V10.6666H0V5.33325Z" fill="#DD0000" />
      </g>
      <defs>
        <clipPath id="clip0_1053_9624">
          <rect width="21.3333" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

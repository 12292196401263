/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { LoginRequest } from '../models/LoginRequest';
import type { LoginResponseDto } from '../models/LoginResponseDto';
import type { SignupRequest } from '../models/SignupRequest';
import type { User } from '../models/User';

export class AuthService {
  /**
   * @param requestBody
   * @returns User
   * @throws ApiError
   */
  public static authControllerSignup(
    requestBody: SignupRequest,
  ): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/signup',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns LoginResponseDto
   * @throws ApiError
   */
  public static authControllerLogin(
    requestBody: LoginRequest,
  ): CancelablePromise<LoginResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Wrong credentials or company approval is pending/rejected`,
      },
    });
  }

  /**
   * @param token
   * @returns User
   * @throws ApiError
   */
  public static authControllerConfirmEmailUpdate(
    token: string,
  ): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/confirm-email-change/{token}',
      path: {
        token: token,
      },
    });
  }
}

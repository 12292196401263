import { useCallback } from 'react';
import Cookies from 'js-cookie';

import { errorHandler } from '@/core/libs/error-handler';
import { ResponseError } from '@/core/libs/fetch-api/ResponseError';
import { OpenAPI, User, UsersService } from '@/generated/api';

import { AUTH_COOKIE_KEY } from '../context/AuthenticationContext';

export type UseGetUserData = (authToken: string) => Promise<User>;

export default function useGetUserData(): UseGetUserData {
  OpenAPI.TOKEN = Cookies.get(AUTH_COOKIE_KEY);

  return useCallback((): Promise<User> => {
    try {
      return UsersService.userControllerGetMe();
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      if (error.status !== 401) {
        errorHandler.capture(error);
      }
      throw new ResponseError(error);
    }
  }, []);
}

import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';

import Layout from './core/components/layout/Layout';
import staticIntl from './core/libs/static-intl';

function App(): JSX.Element {
  const intl = useIntl();

  useEffect(() => {
    staticIntl.initialize(intl);
  }, [intl]);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default App;

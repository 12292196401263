import { technologies, TechnologyType } from '@orderfoxag/reference-data';

export const searchableTechnologies: TechnologyType[] = technologies.filter(
  ({ id }) =>
    [
      'pt201', // Soldering
      'pt301', // Powder coating
      'pt302', // Liquid painting
      'pt303', // Anodizing
      'pt304', // Nitrogenizing
      'pt305', // Burnishing
      'pt306', // Galvanising
      'pt308', // Nickel plating
      'pt309', // Chrome plating
      'pt310', // Phosphatisation
      'pt403', // Blasting
      'pt410', // Electropolishing
      'pt411', // Polishing
      'pt6', // Cutting
      'pt601', // Flame cutting
      'pt701', // Forging
      'pt703', // Casting
      'pt704', // Plastic injection molding
      'pt706', // Die casting
      'pt801', // Hardening
      'pt802', // Annealing
      'pt803', // Tempering
      'pt9', // Heat treatment
      't1', // Turning
      't12', // Flat grinding
      't12a', // Turning 2 axes
      't13', // Round grinding
      't13pa', // Turning 3+ axes
      't14', // Laser cutting
      't15', // Plasma cutting
      't16', // Stamping
      't17', // Shearing
      't19', // Chamfering / bending
      't2', // Milling (we keep this one as for now file analyzer returns this)
      't20', // Cold forming
      't21', // Deep drawing
      't23', // Rounding / bending
      't24', // Rolling / Rounding
      't25', // Welding
      't26', // Laser welding
      't27', // Laser welding
      't32', // 3-axis-CNC-mill
      't33', // 5-axis-CNC-mill
      't37', // Water jet cutting
      't3D001', // Additive Manufacturing / 3D printing
      't42', // Coating
      't47', // Deep drilling
      't48', // EDM (sinking)
      't49', // EDM (wire)
      't50', // MIG/MAG Welding
      't51', // TIG/WIG Welding
      't52', // Spot Welding
      't53', // Plasma Welding
      't54', // Sand Casting
      't55', // Investment Casting
      't56', // Selective Laser Sintering (SLS)
      't57', // Direct Metal Laser Sintering (DMLS)
      't58', // Fused Deposition Modeling (FDM)
      't59', // Stereolithography (SLA)
      't6', // Grinding
      't60', // Selective Laser Melting (SLM)
      't61', // Electron Beam Melting (EBM)
      't7', // Eroding (EDM)
    ].includes(id),
);

import { useCallback } from 'react';

import { useUserTracking } from '@/core/hooks/useUserTracking';
import TrackerEvents from '@/core/libs/event-tracker/TrackerEvents.enum';
import { useFileDropContext } from '@/modules/matchmaking/context/file-drop-context';
import { SearchQuery } from '@/modules/matchmaking/models/searchbar/SearchQuery';
import { AnalyzedPart } from '@/modules/matchmaking/types';

import { useSearchContext } from '../context/SearchContext';
import { searchQueryToUrl } from '../utils';

export interface SearchFileEventDimension {
  pdfFilesLength: number;
  stepFilesLength: number;
}

export interface SearchEventDimension {
  query: SearchQuery;
  urlQuery: string;
  totalFilesLength: number;
  pdfFilesLength: number;
  stepFilesLength: number;
  page?: number;
}

export type SearchGeneralEvents = TrackerEvents.SEARCH_3D_VIEW_USE;

export type SearchFileEvents = TrackerEvents.SEARCH_UPLOAD_FILE;

export type SearchEvents =
  | TrackerEvents.SEARCH_QUERY
  | TrackerEvents.SEARCH_PRICE_CURVE_INTERACTION
  | TrackerEvents.SEARCH_DEMO_PART_INTERACTION
  | TrackerEvents.SEARCH_DEMO_PART_CLICK
  | TrackerEvents.SEARCH_MEDIA_CLICK;

export interface UseSearchTracking {
  trackGeneralEvent(eventName: SearchGeneralEvents): void;
  trackFileEvent(
    eventName: SearchFileEvents,
    payload: { files: AnalyzedPart[] },
  ): void;
  trackEventWithSearch(
    eventName: SearchEvents,
    payload?: { query?: SearchQuery; page?: number },
  ): void;
}

export function useSearchTracking(): UseSearchTracking {
  const { trackUserEvent } = useUserTracking();
  const { analyzedFiles } = useFileDropContext();
  const { searchQuery, page, searchQueryURL } = useSearchContext();

  const getEventFileDimension = useCallback(
    (files: AnalyzedPart[]): SearchFileEventDimension => {
      let pdfFilesLength = 0;
      let stepFilesLength = 0;

      files.forEach(({ stepFileToken, pdfFileToken }) => {
        if (stepFileToken) stepFilesLength++;
        if (pdfFileToken) pdfFilesLength++;
      });

      return {
        pdfFilesLength,
        stepFilesLength,
      };
    },
    [],
  );

  const trackGeneralEvent = useCallback(
    (eventName: SearchGeneralEvents): void => {
      trackUserEvent(eventName);
    },
    [trackUserEvent],
  );

  const trackFileEvent = useCallback(
    (eventName: SearchFileEvents, payload: { files: AnalyzedPart[] }): void => {
      trackUserEvent(eventName, getEventFileDimension(payload.files));
    },
    [getEventFileDimension, trackUserEvent],
  );

  const trackEventWithSearch = useCallback(
    (
      eventName: SearchEvents,
      { query, page: payloadPage }: { query?: SearchQuery; page?: number } = {},
    ): void => {
      const urlQuery = query ? searchQueryToUrl(query) : searchQueryURL;
      if (urlQuery) {
        const dimensions: SearchEventDimension = {
          query: query || searchQuery,
          urlQuery,
          totalFilesLength: analyzedFiles.length,
          pdfFilesLength: analyzedFiles.filter(
            ({ pdfFileToken }) => pdfFileToken,
          ).length,
          stepFilesLength: analyzedFiles.filter(
            ({ stepFileToken }) => stepFileToken,
          ).length,
          page: payloadPage || page,
        };
        trackUserEvent(eventName, dimensions);
      }
    },
    [searchQueryURL, searchQuery, analyzedFiles, page, trackUserEvent],
  );

  return {
    trackGeneralEvent,
    trackFileEvent,
    trackEventWithSearch,
  };
}

import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { toast } from '@/core/libs/toast';

import { AnalyzedPart } from '../../modules/matchmaking/types';

type FilesValidatorOptions = {
  allowedExtensions: string[];
  sizeLimitMB: number;
  existingFiles?: (File | AnalyzedPart)[];
  checkDuplicates?: boolean;
};

export default function useFilesValidator({
  allowedExtensions,
  sizeLimitMB,
  existingFiles = [],
  checkDuplicates = false,
}: FilesValidatorOptions) {
  const { $t } = useIntl();
  const existingFilesSize = existingFiles.reduce(
    (acc, part) => acc + (part.size ?? 0),
    0,
  );

  const getFileExtension = (name: string): string => {
    return name.toLocaleLowerCase().split('.').pop() || '';
  };

  const getInvalidFiles = useCallback(
    (files: (File | null)[]): (File | null)[] => {
      const lowerCaseExtensions = allowedExtensions.map((ext) =>
        ext.toLocaleLowerCase(),
      );
      return files.filter(
        (file) =>
          file && !lowerCaseExtensions?.includes(getFileExtension(file.name)),
      );
    },
    [allowedExtensions],
  );

  const getInvalidFilesMessage = useCallback(
    (files: (File | null)[], invalidFiles: (File | null)[]): JSX.Element => {
      let skippedFiles: string[] = [];
      let skippedFormats: string[] = [];

      if (invalidFiles.length < 10)
        skippedFiles = invalidFiles.filter(Boolean).map((file) => file!.name);
      else
        skippedFormats = [
          ...new Set(
            invalidFiles.map((file) =>
              getFileExtension(file!.name).toUpperCase(),
            ),
          ),
        ];

      let text;
      if (files.length === 1)
        text = $t({ id: 'General.dropzone.singleFileWasNotValid' });
      else if (invalidFiles.length === files.length)
        text = $t(
          { id: 'General.dropzone.allFilesWereNotValid' },
          { totalNumber: files.length },
        );
      else
        text = $t(
          {
            id: 'General.dropzone.someFilesWereNotValid',
          },
          {
            totalNumber: files.length,
            invalidNumber: invalidFiles.length,
          },
        );

      return (
        <div>
          {/* Explanatory text */}
          <p>{text}</p>

          {/* Invalid files or formats list */}
          {skippedFiles?.length ? (
            <p className="my-5">
              <span className="font-medium">
                {$t({ id: 'General.dropzone.skippedFiles' })}:
              </span>
              <ul>
                {skippedFiles.map((fileName) => (
                  <li key={fileName}>{fileName}</li>
                ))}
              </ul>
            </p>
          ) : (
            <p className="my-5">
              <span className="font-medium">
                {$t({ id: 'General.dropzone.skippedFileFormats' })}:
              </span>{' '}
              <br /> {skippedFormats.join(', ')}
            </p>
          )}

          {/* Supported file formats list */}
          <p className="font-medium">
            {$t({ id: 'General.dropzone.supportedFileFormats' })}:{' '}
            {allowedExtensions.join(', ')}
          </p>
        </div>
      );
    },
    [allowedExtensions, $t],
  );

  const getDuplicatedFiles = useCallback(
    (files: File[]): File[] => {
      return (
        files.filter(
          (file) =>
            file &&
            existingFiles.some((existingFile) =>
              existingFile instanceof File
                ? existingFile.name === file.name
                : existingFile.stepFilename === file.name ||
                  existingFile.pdfFilename === file.name,
            ),
        ) ?? []
      );
    },
    [existingFiles],
  );

  const getDuplicatedFilesMessage = useCallback(
    (duplicatedFiles: File[]): JSX.Element => {
      return (
        <div>
          <p>
            {$t({
              id: 'CreateRequest.workpieces.errors.existingFile',
            })}
          </p>
          <ul className="mt-1 list-disc">
            {duplicatedFiles.slice(0, 9).map((file) => (
              <li key={file.name}>{file.name}</li>
            ))}
            {duplicatedFiles.length > 9 && <li>...</li>}
          </ul>
        </div>
      );
    },
    [$t],
  );

  const validateFiles = useCallback(
    (files: File[]): { acceptedFiles: File[] } => {
      const sizeLimitKB = sizeLimitMB * 1024 * 1024;
      const invalidFiles = getInvalidFiles(files);
      const duplicatedFiles = checkDuplicates ? getDuplicatedFiles(files) : [];
      if (!invalidFiles?.length && !duplicatedFiles?.length) {
        const totalSize = files.reduce((acc, file) => acc + file.size, 0);
        if (totalSize + existingFilesSize > sizeLimitKB) {
          toast({
            appearance: 'danger',
            message: $t(
              { id: 'supplierSearch.searchBar.filesTooBig' },
              { limitMB: sizeLimitMB },
            ),
          });
          return { acceptedFiles: [] };
        } else {
          return { acceptedFiles: files };
        }
      } else {
        if (invalidFiles.length > 0) {
          toast({
            appearance: 'warning',
            message: getInvalidFilesMessage(files, invalidFiles),
          });
        }
        if (duplicatedFiles.length > 0) {
          toast({
            appearance: 'warning',
            message: getDuplicatedFilesMessage(duplicatedFiles),
          });
        }
        const validFiles = files.filter(
          (file) =>
            !invalidFiles.includes(file) && !duplicatedFiles.includes(file),
        );
        const totalSizeValidFiles = validFiles.reduce(
          (acc, file) => acc + file.size,
          0,
        );
        if (totalSizeValidFiles + existingFilesSize > sizeLimitKB) {
          toast({
            appearance: 'danger',
            message: $t(
              { id: 'supplierSearch.searchBar.filesTooBig' },
              { limitMB: sizeLimitMB },
            ),
          });
          return { acceptedFiles: [] };
        } else {
          return { acceptedFiles: validFiles };
        }
      }
    },
    [
      sizeLimitMB,
      getInvalidFiles,
      checkDuplicates,
      getDuplicatedFiles,
      existingFilesSize,
      $t,
      getInvalidFilesMessage,
      getDuplicatedFilesMessage,
    ],
  );

  return { validateFiles };
}

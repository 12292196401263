import { clsx, type ClassValue } from 'clsx';
import { ItemDataType } from 'rsuite/esm/@types/common';
import { twMerge } from 'tailwind-merge';

import { LanguageCode } from '@/core/context/I18nContext';

/**
 * Transforms the data array by prioritizing and sorting items based on their
 * position in the priorities array.
 *
 * @param data An array of ItemDataType objects representing the data to be transformed.
 * @param priorities  An optional array of data types representing the prioritized order.
 * @returns A new array of ItemDataType objects with the transformed and sorted data.
 */
// TODO: Remove when Create RFQ design is implemented
export function prioritizeAndSortItems(
  data: ItemDataType<string>[],
  priorities?: string[],
): ItemDataType<string>[] {
  if (!priorities || priorities.length === 0) return data;

  const transformedData = [
    ...data
      .filter((item) => item.value && priorities.includes(item.value))
      .sort((a, b) => {
        const indexA = priorities.indexOf(a.value ?? '');
        const indexB = priorities.indexOf(b.value ?? '');
        return indexA - indexB;
      }),
    ...data.filter((item) => item.value && !priorities.includes(item.value)),
  ];

  return transformedData;
}

export const isLangValid = (lang: string | null): boolean =>
  Object.values(LanguageCode).includes(lang as LanguageCode);

export const getSubdomain = (): string | undefined =>
  window.location.host.split('.').shift();

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

export const normalizeText = (text: string) =>
  text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const matchSearchTerm = (term: string, option: string): boolean => {
  return (
    normalizeText(option).includes(normalizeText(term)) ||
    meetsFilterException(term, option)
  );
};

export const meetsFilterException = (term: string, option: string): boolean => {
  // Added due to custom logic needed in this ticket
  // https://orderfox.atlassian.net/browse/PF-549
  if (
    (normalizeText(term) === 'turke' || normalizeText(term) === 'turkey') &&
    normalizeText(option) === 'turkiye'
  ) {
    return true;
  }
  return false;
};

export function removeDiacritics(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

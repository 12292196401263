/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MachineSpecsDto = {
  technology?: MachineSpecsDto.technology;
  xMm?: number | null;
  yMm?: number | null;
  zMm?: number | null;
  numberOfAxes?: number | null;
  diameterMm?: number | null;
  turningLengthMm?: number | null;
  maxCuttingThicknessMm?: number | null;
};

export namespace MachineSpecsDto {
  export enum technology {
    MILLING = 'MILLING',
    TURNING = 'TURNING',
    CUTTING = 'CUTTING',
    JOINING = 'JOINING',
    STAMPING = 'STAMPING',
    CASTING_MOULDING = 'CASTING_MOULDING',
    FORMING = 'FORMING',
    PLASTIC_INJECTION_MOULDING = 'PLASTIC_INJECTION_MOULDING',
    AXIS_5 = 'AXIS_5',
    AXIS_3 = 'AXIS_3',
    EDM = 'EDM',
    WATER_CUTTING = 'WATER_CUTTING',
  }
}

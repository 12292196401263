import { SearchMachineDto } from '@/generated/api';

export type FeedPagination = {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
};

export type FeedFiltersReducerType = {
  showExpired: boolean;
  machines: SearchMachineDto[];
  technologyIds: string[];
  materialIds: string[];
  certificationsIds: string[];
  incoterms: string[];
  countryCodes: string[];
  workpieces: number[];
  lotSizes: number[];
  sort: 'newest' | 'deadline';
  pagination: FeedPagination;
  showSparksForMachines: boolean;
  showSparksForMaterials: boolean;
  showSparksForTechnologies: boolean;
  showSparksForCertifications: boolean;
  showSparksForCountryCodes: boolean;
};

export type FEED_REDUCER_ACTION_TYPE =
  | {
      type: 'SET_INITIAL_STATE';
      payload: null;
    }
  | {
      type: 'SET_VALUES_WITH_AI';
      payload: {
        machines: SearchMachineDto[];
        materialIds: string[];
        technologyIds: string[];
        certificationsIds: string[];
        countryCodes: string[];
        showSparksForMachines: boolean;
        showSparksForMaterials: boolean;
        showSparksForTechnologies: boolean;
        showSparksForCertifications: boolean;
        showSparksForCountryCodes: boolean;
      };
    }
  | {
      type: 'TOGGLE_SHOW_EXPIRED';
      payload: null;
    }
  | {
      type: 'SET_MACHINES';
      payload: SearchMachineDto[];
    }
  | {
      type: 'SET_TECHNOLOGY_IDS';
      payload: string[];
    }
  | {
      type: 'SET_MATERIAL_IDS';
      payload: string[];
    }
  | {
      type: 'SET_WORKPIECES';
      payload: number[];
    }
  | {
      type: 'SET_LOT_SIZES';
      payload: number[];
    }
  | {
      type: 'SET_CERTIFICATIONS_IDS';
      payload: string[];
    }
  | {
      type: 'SET_INCOTERMS';
      payload: string[];
    }
  | {
      type: 'SET_COUNTRY_CODES';
      payload: string[];
    }
  | {
      type: 'SET_SORT';
      payload: 'newest' | 'deadline';
    }
  | {
      type: 'SET_PAGINATION';
      payload: FeedPagination;
    }
  | {
      type: 'RESET_FILTERS';
      payload: {
        workpieces: number[];
        lotSizes: number[];
      };
    };

export const feedFiltersReducerInitialState: FeedFiltersReducerType = {
  showExpired: true,
  machines: [],
  technologyIds: [],
  materialIds: [],
  certificationsIds: [],
  incoterms: [],
  countryCodes: [],
  workpieces: [],
  lotSizes: [],
  sort: 'newest',
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
  },
  showSparksForMachines: false,
  showSparksForMaterials: false,
  showSparksForTechnologies: false,
  showSparksForCertifications: false,
  showSparksForCountryCodes: false,
};

export function feedFiltersReducer(
  state: typeof feedFiltersReducerInitialState,
  { type, payload }: FEED_REDUCER_ACTION_TYPE,
): FeedFiltersReducerType {
  switch (type) {
    case 'SET_INITIAL_STATE':
      return {
        ...feedFiltersReducerInitialState,
      };
    case 'SET_VALUES_WITH_AI':
      return {
        ...state,
        ...payload,
      };
    case 'TOGGLE_SHOW_EXPIRED':
      return {
        ...state,
        showExpired: !state.showExpired,
      };
    case 'SET_MACHINES':
      return {
        ...state,
        machines: payload,
        showSparksForMachines: false,
      };
    case 'SET_TECHNOLOGY_IDS':
      return {
        ...state,
        technologyIds: payload,
        showSparksForTechnologies: false,
      };
    case 'SET_MATERIAL_IDS':
      return {
        ...state,
        materialIds: payload,
        showSparksForMaterials: false,
      };
    case 'SET_WORKPIECES':
      return {
        ...state,
        workpieces: payload,
      };
    case 'SET_LOT_SIZES':
      return {
        ...state,
        lotSizes: payload,
      };
    case 'SET_CERTIFICATIONS_IDS':
      return {
        ...state,
        certificationsIds: payload,
        showSparksForCertifications: false,
      };
    case 'SET_INCOTERMS':
      return {
        ...state,
        incoterms: payload,
      };
    case 'SET_COUNTRY_CODES':
      return {
        ...state,
        countryCodes: payload,
        showSparksForCountryCodes: false,
      };
    case 'SET_SORT':
      return {
        ...state,
        sort: payload,
      };
    case 'SET_PAGINATION':
      return {
        ...state,
        pagination: payload,
      };
    case 'RESET_FILTERS':
      return {
        ...feedFiltersReducerInitialState,
        workpieces: payload.workpieces,
        lotSizes: payload.lotSizes,
        pagination: {
          ...state.pagination,
          pageSize: state.pagination.pageSize,
        },
      };
    default:
      return state;
  }
}

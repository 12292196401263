/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileUploadedEventRequest } from './FileUploadedEventRequest';
import type { GclidEventRequest } from './GclidEventRequest';
import type { InteractionEventRequest } from './InteractionEventRequest';

export type TrackingEventRequest = {
  type: TrackingEventRequest.type;
  payload?:
    | InteractionEventRequest
    | FileUploadedEventRequest
    | GclidEventRequest;
  companyId?: number;
};

export namespace TrackingEventRequest {
  export enum type {
    SEARCH = 'SEARCH',
    CONTACT = 'CONTACT',
    INVITATION = 'INVITATION',
    FILE_UPLOADED = 'FILE_UPLOADED',
    PROFILE_PAGE_VIEW = 'PROFILE_PAGE_VIEW',
    GCLID = 'GCLID',
  }
}

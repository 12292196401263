import { useQuery } from '@tanstack/react-query';

import { errorHandler } from '@/core/libs/error-handler';
import { fetchApi } from '@/core/libs/fetch-api/fetch-api';
import { MatchedManufacturer } from '@/modules/matchmaking/types';
import { CompaniesService, ManufacturerListsService } from '@/generated/api';

export function useManufacturerLists(enabled: boolean = true) {
  return useQuery({
    enabled,
    queryKey: ['manufacturerLists'],
    queryFn:
      ManufacturerListsService.manufacturerListsControllerGetManufacturerLists,
  });
}

export function useManufacturerList(listId: number) {
  return useQuery({
    queryKey: ['manufacturerList', { listId }],
    queryFn: () =>
      ManufacturerListsService.manufacturerListsControllerGetManufacturersListById(
        listId,
      ),
    enabled: !!listId,
  });
}

export function useManufacturerPage(uuid: string) {
  return useQuery({
    queryKey: ['manufacturerPage', { uuid }],
    queryFn: () => CompaniesService.companyControllerGetProfile(uuid),
    enabled: !!uuid,
  });
}

interface ApiResponse {
  success: false;
  manufacturer: MatchedManufacturer | null;
}
export function useManufacturerSearch({
  uuid,
  url,
  triggeredBy,
}: {
  uuid?: string;
  url?: string;
  triggeredBy?: string;
}) {
  return useQuery({
    queryKey: ['manufacturerSearch', { uuid, url, triggeredBy }],
    queryFn: async () => {
      try {
        const params = uuid
          ? { uuid, triggered_by: triggeredBy }
          : { url, triggered_by: triggeredBy };
        const response = await fetchApi.post<ApiResponse>(
          `${
            import.meta.env.PUBLIC_SEARCH_ENGINE_API_URL
          }/api/man-search/get-url-data`,
          params,
          {
            cache: 'no-cache',
            credentials: 'include',
            headers: {
              'Content-Type': 'text/plain; charset=utf-8',
            },
          },
        );
        return response.manufacturer;
      } catch (error) {
        errorHandler.capture(error, {
          avoidFlashMessage: true,
          additionalValues: { url },
        });
      }
    },
    enabled: !!uuid || !!url,
    initialData: null,
  });
}

export default function FlagTR(
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1053_11742)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H21.3333V16H0V0Z"
          fill="#E30A17"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5669 8.25008C13.5669 10.4568 11.7469 12.2468 9.50026 12.2468C7.25359 12.2468 5.43359 10.4568 5.43359 8.24675C5.43359 6.03675 7.25359 4.25342 9.50026 4.25342C11.7469 4.25342 13.5669 6.04342 13.5669 8.25008Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.767 8.24989C13.767 10.0166 12.3137 11.4466 10.517 11.4466C8.72034 11.4466 7.26367 10.0132 7.26367 8.24656C7.26367 6.47989 8.72034 5.05322 10.517 5.05322C12.3137 5.05322 13.7703 6.48322 13.7703 8.24989H13.767Z"
          fill="#E30A17"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3573 6.38322L14.3239 7.85988L12.9473 8.23322L14.3073 8.71655L14.2739 10.0732L15.1573 9.01322L16.4973 9.47988L15.7239 8.34322L16.6673 7.21322L15.2173 7.61322L14.3573 6.37988V6.38322Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1053_11742">
          <rect width="21.3333" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { fetchApi } from '@/core/libs/fetch-api/fetch-api';
import { MatchedManufacturer } from '@/modules/matchmaking/types/index';

interface GetManufacturersByUrlRequest<T extends number | string> {
  partfox_id?: number;
  url?: string;
  urls?: string[];
  mixed?: T[];
  name?: string;
}

interface GetManufacturersByUrlResponse {
  manufacturer: MatchedManufacturer;
  manufacturers: MatchedManufacturer[];
}

/**
 * Get manufacturer(s) by url(s)
 * Either "partfox_id", "url" or "urls" param must be passed, but NOT all of them
 * @param partfox_id - partfox id of the manufacturer to get
 * @param url - website of the manufacturer to get
 * @param urls - websites of the manufacturers to get
 * @param mixed - combined array of "partfox_ids" and "urls"
 * @returns list of "manufacturer" or "manufacturers" depending on passing "url" or "urls" param
 */
async function getManufacturersByUrl({
  partfox_id,
  url,
  urls,
  mixed,
  name,
}: GetManufacturersByUrlRequest<
  number | string
>): Promise<GetManufacturersByUrlResponse> {
  const apiUrl = import.meta.env.PUBLIC_SEARCH_ENGINE_API_URL as string;
  const results = await fetchApi.post<GetManufacturersByUrlResponse>(
    `${apiUrl}/api/man-search/get-url-data`,
    { partfox_id, url, urls, mixed, name },
    {
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'text/plain; charset=utf-8',
      },
    },
  );
  return results;
}

export { getManufacturersByUrl };

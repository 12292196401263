import { useIntl } from 'react-intl';

import { SignInForm } from '@/core/components/component-library/sign-in-form';
import { buttonVariants } from '@/core/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/core/components/ui/dialog';
import { useUserTracking } from '@/core/hooks/useUserTracking';
import { cn } from '@/core/libs/utils';

interface SignInForm {
  email: string;
  password: string;
}

interface SignInProps {
  className?: string;
  isOpen: boolean;
  onClose(): void;
  onForgotPasswordClick(): void;
  uncloseable?: boolean;
  closeableBackdrop?: boolean;
}

export function SignInModal({
  className,
  isOpen,
  onClose,
  onForgotPasswordClick,
  uncloseable = false,
  closeableBackdrop = false,
}: SignInProps): JSX.Element {
  const { $t } = useIntl();
  const { trackUserEvent, TrackerEvents } = useUserTracking();

  return (
    <Dialog
      open={isOpen}
      onOpenChange={uncloseable ? (): null => null : onClose}
    >
      <DialogContent
        className={cn('max-w-[600px] !rounded-2xl !p-0', className)}
        onPointerDownOutside={(e): void =>
          closeableBackdrop ? onClose() : e.preventDefault()
        }
        classNameClose="text-white"
      >
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="px-10 pb-6 pt-10 md:p-10">
            <DialogHeader>
              <DialogTitle className="pb-6 text-left">
                {$t({ id: 'General.login' })}
              </DialogTitle>
            </DialogHeader>
            <SignInForm
              onSignIn={onClose}
              onForgotPasswordClick={onForgotPasswordClick}
            />
          </div>
          <div className="relative flex flex-col-reverse gap-4 rounded-b-2xl border border-secondary-900 bg-secondary-900 p-10 md:rounded-l-none md:rounded-r-2xl">
            <img
              src="/assets/sphere.webp"
              alt="sphere"
              className="absolute inset-0 hidden w-full md:block"
            />
            <a
              href="/registration"
              className={cn(
                buttonVariants({
                  appearance: 'filled',
                  color: 'primary',
                  size: 'lg',
                }),
                'z-10 w-full font-semibold no-underline hover:text-secondary-900 hover:no-underline focus-visible:text-secondary-900 focus-visible:no-underline',
              )}
              onClick={() => trackUserEvent(TrackerEvents.LOGIN_SIGNUP_CLICK)}
            >
              {$t({ id: 'supplierSearch.signupAndUseForFree' })}
            </a>
            <p className="z-10 text-pretty text-base font-semibold text-white">
              {$t({ id: 'Login.signUpDescription' })}
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

import { cva } from 'class-variance-authority';
import { ExternalToast, toast as sonnerToast } from 'sonner';

import { Icon } from '@/core/components/component-library/Icon/Icon';
import { IconType } from '@/core/components/component-library/Icon/IconType';
import { cn } from '@/core/libs/utils';

import staticIntl from './static-intl';

export interface ToastProps {
  message: string | JSX.Element;
  title?: string;
  appearance?: 'success' | 'danger' | 'warning' | 'info' | 'primary';
  options?: ExternalToast;
}

const toastVariants = cva(
  'relative w-[375px] max-w-full rounded-2xl border p-5',
  {
    variants: {
      appearance: {
        success: 'border-success-200 bg-success-50',
        info: 'border-info-200 bg-info-50',
        warning: 'border-warning-200 bg-warning-50',
        danger: 'border-danger-200 bg-danger-50',
        primary: 'border-primary-500 bg-primary-500',
      },
    },
    defaultVariants: {
      appearance: 'success',
    },
  },
);

const iconVariants = cva('text-2xl', {
  variants: {
    appearance: {
      success: 'text-success-main',
      info: 'text-info-main',
      warning: 'text-warning-main',
      danger: 'text-danger-main',
      primary: 'text-neutral-900',
    },
  },
});

export function toast(props: ToastProps): void {
  const { message, title, appearance = 'success', options } = props;
  const $t = staticIntl.instance.$t;

  const getIconType = (): IconType => {
    switch (appearance) {
      case 'info':
        return IconType.INFORMATION;
      case 'success':
        return IconType.CHECK_CIRCLE_FILLED;
      case 'warning':
        return IconType.ALERT_FILLED;
      case 'primary':
        return IconType.BULB;
      case 'danger':
      default:
        return IconType.REMOVE_CIRCLED_FILLED;
    }
  };

  const getHeaderText = (): string | null => {
    switch (appearance) {
      case 'info':
        return $t({ id: 'General.info' });
      case 'success':
        return $t({ id: 'General.success' });
      case 'warning':
        return $t({ id: 'General.warning' });
      case 'danger':
        return $t({ id: 'General.error' });
      default:
        return $t({ id: 'General.info' });
    }
  };

  const renderToast = (tId: string | number): JSX.Element => {
    return (
      <div className={cn(toastVariants({ appearance }))}>
        {/* Close button */}
        <button
          className="absolute right-5 top-5 size-3"
          aria-label={$t({ id: 'General.close' })}
          onClick={(): string | number => sonnerToast.dismiss(tId)}
        >
          <Icon type={Icon.type.X_CROSS} />
        </button>

        {/* Content */}
        <div className="grid grid-cols-[24px_1fr] items-center gap-x-3">
          <Icon
            type={getIconType()}
            className={cn(iconVariants({ appearance }))}
          />
          <div className="font-medium">{title ?? getHeaderText()}</div>
          <div className="col-start-2 row-start-2 whitespace-pre-line text-neutral-800">
            {message}
          </div>
        </div>
      </div>
    );
  };

  sonnerToast.custom((t) => renderToast(t), options);
}

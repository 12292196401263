import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { Icon } from '@/core/components/component-library/Icon/Icon';
import { cn } from '@/core/libs/utils';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    mode?: 'light' | 'dark';
  }
>(({ mode = 'light', className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      mode === 'light'
        ? 'border-neutral-600 ring-offset-secondary-50 hover:border-secondary-900 focus-visible:ring-secondary-900 data-[state=checked]:bg-secondary-900 data-[state=indeterminate]:bg-secondary-900 data-[state=checked]:text-white data-[state=indeterminate]:text-white'
        : 'border-secondary-600 ring-offset-secondary-900 hover:border-primary-500 focus-visible:ring-secondary-50 data-[state=checked]:bg-primary-500 data-[state=indeterminate]:bg-primary-500 data-[state=checked]:text-secondary-900 data-[state=indeterminate]:text-secondary-900',
      'peer h-5 w-5 shrink-0 rounded-sm border focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('text-current flex items-center justify-center')}
    >
      {props.checked === 'indeterminate' && (
        <Icon type={Icon.type.DELETE_SMALL} className="size-5" />
      )}
      {props.checked === true && (
        <Icon type={Icon.type.CHECK_SMALL} className="size-5 text-white" />
      )}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };

import { useIntl } from 'react-intl';

import { useAuthenticationContext } from '@/core/context/AuthenticationContext';

import PFIcon from './icons/PartfoxIcon';
import PFLogo from './icons/PartfoxLogo';

type PartfoxLogoProps = {
  white?: boolean;
  showBrandName?: boolean;
  isDarkBackground?: boolean;
  className?: string;
};

function PartfoxLogo({
  white = false,
  showBrandName = true,
  className = '',
}: PartfoxLogoProps): JSX.Element {
  const { $t } = useIntl();
  const { currentUser } = useAuthenticationContext();
  const isMAN = currentUser?.type === 'manufacturer';
  const iconClass = `${className}`;

  const color = white ? '#ffffff' : '#222534';

  function linkTo(): string {
    if (!currentUser) return 'https://www.partfox.com';
    if (isMAN) return '/feed';

    return '/search';
  }

  return (
    <a
      href={linkTo()}
      title={
        isMAN
          ? $t({ id: 'Dashboard.yourVisibility' })
          : $t({ id: 'supplierSearch.menu.search' })
      }
    >
      {showBrandName ? (
        <PFLogo className={iconClass} fill={color} />
      ) : (
        <PFIcon className={iconClass} fill={color} />
      )}
    </a>
  );
}

export default PartfoxLogo;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { BookmarkManufacturersDTO } from '../models/BookmarkManufacturersDTO';
import type { ManufacturerList } from '../models/ManufacturerList';
import type { UpdateManufacturerListsDTO } from '../models/UpdateManufacturerListsDTO';
import type { UpsertManufacturerListDTO } from '../models/UpsertManufacturerListDTO';

export class ManufacturerListsService {
  /**
   * @param listId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static manufacturerListsControllerBookmarkManufacturers(
    listId: number,
    requestBody: Array<BookmarkManufacturersDTO>,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/manufacturer-lists/manufacturers/{listId}',
      path: {
        listId: listId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param listId
   * @param requestBody
   * @returns ManufacturerList
   * @throws ApiError
   */
  public static manufacturerListsControllerUnbookmarkManufacturers(
    listId: number,
    requestBody: Array<BookmarkManufacturersDTO>,
  ): CancelablePromise<Array<ManufacturerList>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/manufacturer-lists/manufacturers/{listId}',
      path: {
        listId: listId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static manufacturerListsControllerUpdateManufacturerLists(
    requestBody: UpdateManufacturerListsDTO,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/manufacturer-lists/manufacturers',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns ManufacturerList
   * @throws ApiError
   */
  public static manufacturerListsControllerCreateManufacturersList(
    requestBody: UpsertManufacturerListDTO,
  ): CancelablePromise<ManufacturerList> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/manufacturer-lists/lists',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ManufacturerList
   * @throws ApiError
   */
  public static manufacturerListsControllerGetManufacturerLists(): CancelablePromise<
    Array<ManufacturerList>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/manufacturer-lists/lists',
    });
  }

  /**
   * @param listId
   * @param requestBody
   * @returns ManufacturerList
   * @throws ApiError
   */
  public static manufacturerListsControllerUpdateManufacturersList(
    listId: number,
    requestBody: UpsertManufacturerListDTO,
  ): CancelablePromise<ManufacturerList> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/manufacturer-lists/lists/{listId}',
      path: {
        listId: listId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param listId
   * @returns ManufacturerList
   * @throws ApiError
   */
  public static manufacturerListsControllerGetManufacturersListById(
    listId: number,
  ): CancelablePromise<ManufacturerList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/manufacturer-lists/lists/{listId}',
      path: {
        listId: listId,
      },
    });
  }

  /**
   * @param listId
   * @returns ManufacturerList
   * @throws ApiError
   */
  public static manufacturerListsControllerDeleteList(
    listId: number,
  ): CancelablePromise<Array<ManufacturerList>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/manufacturer-lists/lists/{listId}',
      path: {
        listId: listId,
      },
    });
  }
}

import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { useUserTracking } from '@/core/hooks/useUserTracking';
import { cn } from '@/core/libs/utils';

import { useLayoutContext } from './Layout';

type PageHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
};

function PageHeader({
  children,
  className,
  ...props
}: PageHeaderProps): JSX.Element {
  return (
    <div
      className={cn(
        'bg-secondary-900 px-4 pb-8 pt-24 text-white md:px-10 lg:py-16 lg:pl-32 2xl:pr-[60px]',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

const pageContentVariants = cva(
  'h-full flex-1 px-4 py-10 md:px-10 lg:pl-32 2xl:pr-[60px]',
  {
    variants: {
      variant: {
        default: '',
        centered: 'flex h-dvh flex-col items-center justify-center',
        noSidebar: 'lg:px-[60px]',
      },
      background: {
        default: '',
        grey: 'bg-neutral-100',
        blue: 'bg-secondary-900 text-white',
      },
    },
    defaultVariants: {
      variant: 'default',
      background: 'default',
    },
  },
);

export type PageContentProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof pageContentVariants> & {
    children: React.ReactNode;
    className?: string;
    noPadding?: boolean;
    as?: React.ElementType;
  };

function PageContent({
  children,
  className,
  as: Comp = 'section',
  noPadding = false,
  variant,
  background,
  ...props
}: PageContentProps): JSX.Element {
  return (
    <Comp
      className={cn(
        pageContentVariants({ variant, background }),
        noPadding && 'p-0 md:p-0 lg:p-0 2xl:p-0',
        className,
      )}
      {...props}
    >
      {children}
    </Comp>
  );
}

type PageProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children: React.ReactNode;
  name: string;
  className?: string;
  noIndex?: boolean;
  hideNavigation?: boolean;
};

function Page({
  children,
  name,
  className,
  noIndex = false,
  hideNavigation = false,
  ...props
}: PageProps): JSX.Element {
  const meta = noIndex ? [{ name: 'robots', content: 'noindex' }] : [];
  const { hideNavigationBar, showNavigationBar } = useLayoutContext();
  const { trackUserEvent, TrackerEvents } = useUserTracking();
  const location = useLocation();

  React.useEffect(() => {
    hideNavigation ? hideNavigationBar() : showNavigationBar();
  }, [hideNavigation, hideNavigationBar, showNavigationBar]);

  React.useEffect(() => {
    trackUserEvent(TrackerEvents.GENERAL_PAGE_LOAD);
  }, [TrackerEvents.GENERAL_PAGE_LOAD, location, trackUserEvent]);

  function getCleanCanonicalUrl(): string {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.get('lang') === 'en' && searchParams.delete('lang');
    const searchParamsString = searchParams.toString();

    return searchParamsString.length
      ? new URL(
          `${url.origin}${url.pathname}?${searchParams.toString()}`,
        ).toString()
      : new URL(`${url.origin}${url.pathname}`).toString();
  }

  return (
    <div
      {...props}
      className={cn(
        'flex min-h-[calc(100vh-72px)] max-w-[100vw] flex-col lg:min-h-screen',
        className,
      )}
    >
      <Helmet meta={meta}>
        <title>{name} - Partfox</title>
        <link rel="canonical" href={getCleanCanonicalUrl()} />
      </Helmet>
      {children}
    </div>
  );
}

export { Page, PageHeader, PageContent, pageContentVariants };

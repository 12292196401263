import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';

import { ButtonProps, buttonVariants } from '@/core/components/ui/button';
import { cn } from '@/core/libs/utils';

const iconButtonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-full text-base font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-20',
  {
    variants: {
      size: {
        sm: 'size-[38px] p-[7px]',
        md: 'size-11 p-[10px]',
        lg: 'size-14 p-4',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

export interface IconButtonProps
  extends ButtonProps,
    VariantProps<typeof iconButtonVariants> {
  asChild?: boolean;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      appearance,
      size,
      color,
      mode,
      asChild = false,
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          buttonVariants({ appearance, color, mode, className }),
          iconButtonVariants({ size, className }),
        )}
        ref={ref}
        type={type}
        {...props}
      />
    );
  },
);
IconButton.displayName = 'IconButton';

export { IconButton, iconButtonVariants };

import { useCallback } from 'react';

import { errorHandler } from '@/core/libs/error-handler';
import { UsersService } from '@/generated/api';

export type UseIsEmailInUse = (email: string) => Promise<boolean>;

export default function useIsEmailInUse(): UseIsEmailInUse {
  return useCallback(async (email: string): Promise<boolean> => {
    try {
      await UsersService.userControllerUserExists(email);
      return Promise.resolve(true);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      if (error.status !== 404) {
        errorHandler.capture(error);
      }
      return Promise.resolve(false);
    }
  }, []);
}

import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Loader } from '@/core/components/component-library/Icon/loader';
import { Button } from '@/core/components/ui/button';
import { Checkbox } from '@/core/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/core/components/ui/dialog';
import { Label } from '@/core/components/ui/label';
import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { errorHandler } from '@/core/libs/error-handler';

import { updateUser } from '../hooks/useUser';

interface TermsConditionsModalProps {
  isOpen: boolean;
  onSubmit(): void;
}

export default function TermsConditionsModal({
  isOpen,
  onSubmit,
}: TermsConditionsModalProps): JSX.Element {
  const { $t } = useIntl();
  const { currentUser, logout } = useAuthenticationContext();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [loading, setIsLoading] = useState(false);

  async function validateTC(): Promise<void> {
    if (termsAccepted && currentUser) {
      try {
        setIsLoading(true);
        await updateUser(currentUser.id, {
          termsAccepted: true,
        });
        await onSubmit();
      } catch (error) {
        errorHandler.capture(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsInvalid(true);
    }
  }

  useEffect(() => {
    termsAccepted && setIsInvalid(false);
  }, [termsAccepted]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        return false;
      }}
      modal
    >
      <DialogContent classNameClose="hidden">
        <DialogHeader>
          <DialogTitle>{$t({ id: 'Account.TCModalTitle' })}</DialogTitle>
        </DialogHeader>
        <p>{$t({ id: 'Account.TCModalDescription' })}</p>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <Checkbox
              id="isVerifiedFilter"
              checked={termsAccepted}
              onCheckedChange={(checked): void => {
                setTermsAccepted(!!checked);
              }}
            />

            <Label
              htmlFor="isVerifiedFilter"
              className="cursor-pointer text-base text-neutral-700"
            >
              {$t(
                {
                  id: 'Account.TCModalAction',
                },
                {
                  Link: (str: ReactNode) => (
                    <a
                      href={
                        currentUser?.locale === 'de'
                          ? 'https://www.partfox.com/de/terms-and-conditions'
                          : 'https://www.partfox.com/terms-and-conditions'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {str}
                    </a>
                  ),
                },
              )}
            </Label>
          </div>
          {isInvalid && (
            <div className="text-danger-main">
              {$t({ id: 'Form.fieldRequired' })}
            </div>
          )}
        </div>
        <DialogFooter>
          <Button onClick={logout} color="neutral">
            {$t({ id: 'General.logout' })}
          </Button>
          <Button color="primary" onClick={validateTC} type="submit">
            {loading ? (
              <>
                <Loader size="xs" className="mr-2" />
                {$t({ id: 'General.loading' })}
              </>
            ) : (
              $t({ id: 'General.accept' })
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

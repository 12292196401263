import { useCallback } from 'react';

import { errorHandler } from '@/core/libs/error-handler';
import { ResponseError } from '@/core/libs/fetch-api/ResponseError';
import { CompaniesService, CompanyStatusDto } from '@/generated/api';

export type UseGetCompanyStatusData = () => Promise<CompanyStatusDto>;

export default function useGetCompanyStatus(): UseGetCompanyStatusData {
  return useCallback((): Promise<CompanyStatusDto> => {
    try {
      return CompaniesService.machineryControllerGetStatus();
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      if (error.status !== 401) {
        errorHandler.capture(error);
      }
      throw new ResponseError(error);
    }
  }, []);
}

import { IntlFormatters } from 'react-intl';

import staticIntl from '@/core/libs/static-intl';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';

export class SuggestionsList<T extends SearchableConfiguration> {
  protected get $t(): IntlFormatters['$t'] {
    return staticIntl.instance.$t;
  }
  protected readonly list: T[];
  protected sampleItemTranslation?: string;
  protected _sortedList: T[] = [];

  constructor(list: T[]) {
    this.list = list;
  }

  get sortedList(): T[] {
    if (
      !this.sampleItemTranslation ||
      this.sampleItemTranslation !==
        this.$t({ id: this.list[0].translationKey })
    ) {
      this.sampleItemTranslation = this.$t({ id: this.list[0].translationKey });
      this._sortedList = [...this.list].sort(
        (
          { translationKey: keyA, searchWeight: wA },
          { translationKey: keyB, searchWeight: wB },
        ) => {
          const wDiff: number = wB - wA;
          if (wDiff === 0.0) {
            return this.$t({ id: keyA }).localeCompare(this.$t({ id: keyB }));
          } else {
            return wDiff;
          }
        },
      );
    }
    return this._sortedList;
  }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { AddManufacturersInvitationsRequest } from '../models/AddManufacturersInvitationsRequest';
import type { Company } from '../models/Company';
import type { PaginatedResult } from '../models/PaginatedResult';
import type { Project } from '../models/Project';
import type { ProjectInvitation } from '../models/ProjectInvitation';
import type { ProjectInvitationsStatsDto } from '../models/ProjectInvitationsStatsDto';
import type { ProjectRequest } from '../models/ProjectRequest';
import type { UpdateUserPasswordAdminDTO } from '../models/UpdateUserPasswordAdminDTO';

export class AdminService {
  /**
   * @param searchTerm name or id of the company
   * @param userType
   * @param limit
   * @param page
   * @returns any
   * @throws ApiError
   */
  public static companyAdminControllerFindAllCompanies(
    searchTerm?: string,
    userType?: 'manufacturer' | 'buyer' | 'both',
    limit?: number,
    page?: number,
  ): CancelablePromise<PaginatedResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/companies',
      query: {
        searchTerm: searchTerm,
        userType: userType,
        limit: limit,
        page: page,
      },
    });
  }

  /**
   * @param encryptedUrl
   * @returns Company
   * @throws ApiError
   */
  public static companyAdminControllerFindAllCompanyByEncriptedUrl(
    encryptedUrl: string,
  ): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/companies/{encryptedUrl}',
      path: {
        encryptedUrl: encryptedUrl,
      },
    });
  }

  /**
   * @param duplicateId
   * @param targetId
   * @returns Company
   * @throws ApiError
   */
  public static companyAdminControllerMergeCompanies(
    duplicateId: number,
    targetId: number,
  ): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/companies/merge/{duplicateId}/{targetId}',
      path: {
        duplicateId: duplicateId,
        targetId: targetId,
      },
    });
  }

  /**
   * @param duplicateId
   * @param targetId
   * @param triggeredById
   * @returns Company
   * @throws ApiError
   */
  public static companyAdminControllerGetMergeCompaniesHistory(
    duplicateId?: number,
    targetId?: number,
    triggeredById?: number,
  ): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/companies/merge/history',
      query: {
        duplicateId: duplicateId,
        targetId: targetId,
        triggeredById: triggeredById,
      },
    });
  }

  /**
   * @param query One of: user id, company id, user email, company name
   * @param limit
   * @param page
   * @returns any
   * @throws ApiError
   */
  public static userAdminControllerFindAll(
    query: string,
    limit?: number,
    page?: number,
  ): CancelablePromise<PaginatedResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/users',
      query: {
        query: query,
        limit: limit,
        page: page,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static userAdminControllerChangePassword(
    requestBody: UpdateUserPasswordAdminDTO,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/users/change-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns Project
   * @throws ApiError
   */
  public static projectsAdminControllerCreate(
    requestBody: ProjectRequest,
  ): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/projects',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param projectId
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static projectsAdminControllerAddInvitationsToProject(
    projectId: number,
    requestBody: AddManufacturersInvitationsRequest,
  ): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/projects/projects/{projectId}/add-invitations',
      path: {
        projectId: projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param projectId
   * @returns ProjectInvitation
   * @throws ApiError
   */
  public static projectsAdminControllerSendReminders(
    projectId: number,
  ): CancelablePromise<Array<ProjectInvitation>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/projects/projects/{projectId}/send-reminder',
      path: {
        projectId: projectId,
      },
    });
  }

  /**
   * @param projectId
   * @returns ProjectInvitationsStatsDto
   * @throws ApiError
   */
  public static projectsAdminControllerInvitationsBatchStats(
    projectId: number,
  ): CancelablePromise<ProjectInvitationsStatsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/projects/projects/{projectId}/invitations/batch-stats',
      path: {
        projectId: projectId,
      },
    });
  }

  /**
   * @param projectId
   * @param batchSize
   * @returns any
   * @throws ApiError
   */
  public static projectsAdminControllerAddInvitationsBatches(
    projectId: number,
    batchSize: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/projects/projects/{projectId}/invitations/add-batches/{batchSize}',
      path: {
        projectId: projectId,
        batchSize: batchSize,
      },
    });
  }

  /**
   * @param projectId
   * @returns Project
   * @throws ApiError
   */
  public static invitationsAdminControllerGetMissedOpportunities(
    projectId: number,
  ): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/invitations/missed-opportunities/{projectId}',
      path: {
        projectId: projectId,
      },
    });
  }
}

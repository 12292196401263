export function pdfThumbnail(
  pdfUrl: string,
  target: 'feed' | 'rfq' | 'feed-blur',
): string {
  if (target === 'rfq') {
    return pdfUrl
      .replace('.pdf', '.png')
      .replace('image/upload/', `image/upload/c_pad,g_center,w_420,h_199/`);
  }
  // blur bottom ~30% of image
  if (target === 'feed-blur') {
    return pdfUrl
      .replace('.pdf', '.png')
      .replace(
        'image/upload/',
        `image/upload/c_fill,g_center,w_600,h_320/e_blur_region:2000,y_0.7/`,
      );
  }
  return pdfUrl
    .replace('.pdf', '.png')
    .replace('image/upload/', `image/upload/c_fill,g_center,w_600,h_320/`);
}

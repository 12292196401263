import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { v4 } from 'uuid';

import { DropZone } from '@/core/components/component-library/drop-zone';
import { errorHandler } from '@/core/libs/error-handler';
import TrackerEvents from '@/core/libs/event-tracker/TrackerEvents.enum';
import { splitFileName } from '@/core/libs/file-utils';
import {
  FileAnalyzerUploaderResponse,
  useFileAnalyzerUploader,
} from '@/modules/matchmaking/hooks/use-file-analyzer-uploader';
import { useSearchTracking } from '@/modules/matchmaking/hooks/useSearchTracking';
import { useUrlSearchQuery } from '@/modules/matchmaking/hooks/useUrlSearchQuery';
import { SearchQuery } from '@/modules/matchmaking/models/searchbar/SearchQuery';
import {
  analyzePartFiles,
  AnalyzePartFilesResponse,
} from '@/modules/matchmaking/services/analyze-parts-files';
import {
  AcceptedFiles,
  AnalyzedPart,
  DemandType,
  WorkingPartsMap,
} from '@/modules/matchmaking/types';
import { partsToQuery } from '@/modules/matchmaking/utils';

export enum UploadingStatus {
  STANDBY = 'standby',
  UPLOADING = 'uploading',
  ANALYZING = 'analyzing ',
}

export interface FileDropContext {
  status: UploadingStatus;
  sizeLimitMB: number;
  outputQuery: SearchQuery | null;
  analyzedFiles: AnalyzedPart[];
  selectedFile: AnalyzedPart | null;
  uploadedFiles: FileAnalyzerUploaderResponse[];
  setSelectedFile: (file: AnalyzedPart | null) => void;
  updateSelectedFileQuery: (query: SearchQuery) => void;
  uploadFiles(file: (File | null)[]): Promise<SearchQuery | null>;
  removeFile(file: AnalyzedPart): void;
  removeAllFiles(): void;
}

const FileDrop = createContext<FileDropContext>({
  status: UploadingStatus.STANDBY,
  sizeLimitMB: 0,
  outputQuery: null,
  analyzedFiles: [],
  selectedFile: null,
  uploadedFiles: [],
  setSelectedFile: () => {
    errorHandler.capture(
      'setSelectedFile() being used without initializing FileDrop context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  updateSelectedFileQuery: () => {
    errorHandler.capture(
      'updateSelectedFileQuery() being used without initializing FileDrop context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  uploadFiles: () => {
    errorHandler.capture(
      'uploadFile() being used without initializing FileDrop context',
      { avoidFlashMessage: true },
    );
    throw Error();
  },
  removeFile: () => {
    errorHandler.capture(
      'removeFile() being used without initializing FileDrop context',
      { avoidFlashMessage: true },
    );
    throw Error();
  },
  removeAllFiles: () => {
    errorHandler.capture(
      'removeAllFiles() being used without initializing FileDrop context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
});

export interface FileDropProviderProps {
  children?: ReactNode;
  autoAnalyze?: boolean;
  checkDuplicates?: boolean;
  className?: string;
  initialAnalyzedFiles?: AnalyzedPart[];
}

export function FileDropProvider({
  children,
  className,
  autoAnalyze = true,
  checkDuplicates = false,
  initialAnalyzedFiles,
}: FileDropProviderProps): JSX.Element {
  const sizeLimitMB = 150;
  const { $t } = useIntl();
  const { urlFiles } = useUrlSearchQuery();
  const { upload, isAcceptedFile } = useFileAnalyzerUploader();
  const { trackFileEvent } = useSearchTracking();
  const [uploadStatus, setUploadStatus] = useState<UploadingStatus>(
    UploadingStatus.STANDBY,
  );
  const [fileQueries, setFileQueries] = useState<{
    [key: string]: SearchQuery;
  }>({});
  const [outputQuery, setOuputQuery] = useState<SearchQuery | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<
    FileAnalyzerUploaderResponse[]
  >([]);
  const [analyzedFiles, setAnalyzedFiles] = useState<AnalyzedPart[]>(
    initialAnalyzedFiles ?? [],
  );
  const [selectedFile, setSelectedFile] = useState<AnalyzedPart | null>(
    analyzedFiles?.length ? analyzedFiles[analyzedFiles.length - 1] : null,
  );

  function getParts(dto: AnalyzePartFilesResponse): AnalyzedPart[] {
    return Object.values(dto.parts).reduce<AnalyzedPart[]>(
      (list, { analyzedPart, id, size }) => {
        if (analyzedPart) list.push({ ...analyzedPart, id, size });
        return list;
      },
      [],
    );
  }

  function analizedFilesToWorkingPart(
    files: FileAnalyzerUploaderResponse[],
  ): WorkingPartsMap {
    const map: WorkingPartsMap = {};

    files.forEach(({ token, extension, size }) => {
      const id = v4();
      map[id] = {
        id,
        size,
        files:
          extension === AcceptedFiles.PDF
            ? { pdfFileToken: token }
            : { stepFileToken: token },
        nbBatches: 1,
        hasFollowUpOrders: false,
        batchSizes: [1000],
        demandType: DemandType.oneOff,
      };
    });

    return map;
  }

  const analyzeFiles = useCallback(
    async (
      uploaded: FileAnalyzerUploaderResponse[],
    ): Promise<SearchQuery | null> => {
      setUploadStatus(UploadingStatus.ANALYZING);
      try {
        const analyzedData = await analyzePartFiles(
          analizedFilesToWorkingPart(uploaded),
        );
        const parts = getParts(analyzedData);
        trackFileEvent(TrackerEvents.SEARCH_UPLOAD_FILE, { files: parts });
        setAnalyzedFiles((oldFiles) => {
          const allParts = [...oldFiles, ...parts];
          const newfQueries = parts.reduce(
            (acc, part) => {
              acc[part.id] = partsToQuery([part]);
              return acc;
            },
            {} as { [key: string]: SearchQuery },
          );
          setFileQueries((prev) => ({ ...prev, ...newfQueries }));
          const oQuery = selectedFile
            ? newfQueries[selectedFile.id]
            : allParts.length
            ? newfQueries[allParts[allParts.length - 1].id]
            : null;
          setOuputQuery(oQuery);
          return allParts;
        });
        const query = partsToQuery(parts);
        return query;
      } finally {
        setUploadStatus(UploadingStatus.STANDBY);
      }
    },
    [selectedFile, trackFileEvent],
  );

  const updateSelectedFileQuery = (query: SearchQuery): void => {
    setFileQueries((prev) => {
      if (selectedFile) {
        const oldQueries = { ...prev };
        oldQueries[selectedFile.id] = query;
        return oldQueries;
      }
      return prev;
    });
  };

  useEffect(() => {
    if (selectedFile && fileQueries[selectedFile.id])
      setOuputQuery(fileQueries[selectedFile.id]);
    else setOuputQuery(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const uploadFiles = useCallback(
    async (files: (File | null)[]): Promise<SearchQuery | null> => {
      const finalFiles = files.filter(
        (file) =>
          file &&
          isAcceptedFile(
            splitFileName(file.name).extension?.toLocaleUpperCase(),
          ),
      ) as File[];

      setUploadStatus(UploadingStatus.UPLOADING);
      try {
        const uploaded: FileAnalyzerUploaderResponse[] = await Promise.all(
          finalFiles.map((file) =>
            upload({ blobFile: file }).then((response) => ({
              ...response,
              size: file.size,
            })),
          ),
        );
        setUploadedFiles(uploaded);

        if (!autoAnalyze) return null;
        return await analyzeFiles(uploaded);
      } catch (error) {
        errorHandler.capture(error, {
          userErrorMessage: $t({
            id: 'supplierSearch.searchBar.filedrop.uploadError',
          }),
        });
        return null;
      } finally {
        setUploadStatus(UploadingStatus.STANDBY);
      }
    },
    [isAcceptedFile, $t, autoAnalyze, analyzeFiles, upload],
  );

  const removeFile = useCallback((file: AnalyzedPart): void => {
    setAnalyzedFiles((files) => files.filter((part) => part.id !== file.id));
    setFileQueries((prev) => {
      const newQueries = { ...prev };
      delete newQueries[file.id];
      return newQueries;
    });
  }, []);

  const removeAllFiles = useCallback((): void => {
    setAnalyzedFiles([]);
    setFileQueries({});
  }, []);

  useEffect(() => {
    if (urlFiles?.length) {
      analyzeFiles(urlFiles);
    }
  }, [urlFiles, analyzeFiles]);

  return (
    <FileDrop.Provider
      value={{
        status: uploadStatus,
        outputQuery,
        sizeLimitMB,
        analyzedFiles,
        selectedFile,
        uploadedFiles,
        setSelectedFile,
        updateSelectedFileQuery,
        uploadFiles,
        removeFile,
        removeAllFiles,
      }}
    >
      <DropZone
        className={classNames('file-drop', className)}
        onDrop={(files): void => {
          uploadFiles(files);
        }}
        loading={uploadStatus !== UploadingStatus.STANDBY}
        sizeLimitMB={sizeLimitMB}
        existingFiles={analyzedFiles}
        checkDuplicates={checkDuplicates}
        allowedExtensions={Object.values(AcceptedFiles)}
      >
        {children || <Outlet />}
      </DropZone>
    </FileDrop.Provider>
  );
}

export function useFileDropContext(): FileDropContext {
  return useContext(FileDrop);
}

import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@/core/components/component-library/Icon/Icon';
import { Button } from '@/core/components/ui/button';
import { useGhostModeContext } from '@/core/context/GhostModeContext';

import { Loader } from '../component-library/Icon/loader';

interface GhostModeLayoutProps {
  children: ReactNode;
}

export default function GhostModeLayout({
  children,
}: GhostModeLayoutProps): JSX.Element {
  const { $t } = useIntl();
  const { impersonatedUser, setImpersonatedUser, isLoadingImpersonation } =
    useGhostModeContext();

  const handleOnClick = (): void => {
    setImpersonatedUser(null);
    localStorage.removeItem('impersonatedUser');
    window.location.href = '/admin/ghost-mode';
  };

  if (isLoadingImpersonation) return <Loader />;

  return (
    <>
      {impersonatedUser && (
        <div
          data-testid="ghost-mode-banner"
          className="flex h-[72px] items-center justify-between bg-warning-main px-4 md:px-10 lg:pl-32 2xl:pr-[60px]"
        >
          <div className="flex items-center gap-3">
            <Icon type={Icon.type.GHOST_PACMAN} className="text-2xl" />
            {$t({ id: 'GhostMode.bannerTitle' })}
            <span className="font-semibold">
              {impersonatedUser.firstName} {impersonatedUser.lastName} (ID:{' '}
              {impersonatedUser.id}) - {impersonatedUser.company.name} (ID:{' '}
              {impersonatedUser.company.id}
              {impersonatedUser.company.membershipPlan
                ? ` ${impersonatedUser.company.membershipPlan}`
                : ''}
              )
            </span>
          </div>
          <Button color="neutral" onClick={handleOnClick}>
            {$t({ id: 'GhostMode.stopImpersonating' })}
          </Button>
        </div>
      )}
      {children}
    </>
  );
}

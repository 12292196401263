import React, { createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';

import enMsg from '../../locales/en.json';
import enTranslations from '../../locales/reference-data/en';
import { isLangValid } from '../libs/utils';

export enum LanguageCode {
  ENGLISH = 'en',
  GERMAN = 'de',
  ITALIAN = 'it',
  SPANISH = 'es',
  CHINESE = 'zh',
  TURKISH = 'tr',
}

export interface I18nProviderProps {
  children: React.ReactNode;
}

export interface LocaleContext {
  locale: LanguageCode;
  setUserLocale: React.Dispatch<React.SetStateAction<LanguageCode>>;
}

const ONE_DAY = 24 * 60 * 60 * 1000;

function guessLocale(): LanguageCode {
  const urlLang = new URL(document.URL).searchParams.get(
    'lang',
  ) as LanguageCode;
  const localStorageLang = localStorage.getItem('lang') as LanguageCode;
  const localStorageTTL = +(localStorage.getItem('langTTL') || 0);

  if (isLangValid(urlLang)) {
    return urlLang;
  }
  if (isLangValid(localStorageLang) && localStorageTTL > Date.now() - ONE_DAY) {
    return localStorageLang;
  }
  if (isLangValid(navigator.language)) {
    return navigator.language.split('-')[0] as LanguageCode;
  }
  return LanguageCode.ENGLISH;
}

export const LocaleContext = createContext<LocaleContext>({
  locale: LanguageCode.ENGLISH,
  setUserLocale: {} as React.Dispatch<React.SetStateAction<LanguageCode>>,
});

export function useLocaleContext(): LocaleContext {
  return useContext(LocaleContext);
}

export function I18nProvider({ children }: I18nProviderProps) {
  const [messages, setMessages] = useState<Record<string, string>>({
    ...enMsg,
    ...enTranslations,
  });

  const [userLocale, setUserLocale] = useState<LanguageCode>(guessLocale());

  useEffect(() => {
    function updateUrlLangParam(): void {
      const urlLang = new URL(document.URL).searchParams.get(
        'lang',
      ) as LanguageCode;
      if (urlLang && urlLang !== userLocale) {
        window.history.replaceState(
          {},
          '',
          `${window.location.pathname}?lang=${userLocale}`,
        );
      }
    }
    function loadTranslations(newLanguage: LanguageCode): void {
      async function loadMessages(): Promise<object> {
        setUserLocale(newLanguage);
        localStorage.setItem('lang', newLanguage);
        localStorage.setItem('langTTL', `${Date.now()}`);
        let defaultMessages;
        let referenceDataMessages;

        switch (newLanguage) {
          case LanguageCode.GERMAN:
            dayjs.locale('de');
            [defaultMessages, referenceDataMessages] = await Promise.all([
              import('../../locales/de.json'),
              import('../../locales/reference-data/de'),
            ]);
            return {
              ...defaultMessages.default,
              ...referenceDataMessages.default,
            };
          case LanguageCode.CHINESE:
            dayjs.locale('zh');
            [defaultMessages, referenceDataMessages] = await Promise.all([
              import('../../locales/zh.json'),
              import('../../locales/reference-data/zh'),
            ]);
            return {
              ...defaultMessages.default,
              ...referenceDataMessages.default,
            };
          case LanguageCode.ITALIAN:
            dayjs.locale('it');
            [defaultMessages, referenceDataMessages] = await Promise.all([
              import('../../locales/it.json'),
              import('../../locales/reference-data/it'),
            ]);
            return {
              ...defaultMessages.default,
              ...referenceDataMessages.default,
            };
          case LanguageCode.SPANISH:
            dayjs.locale('es');
            [defaultMessages, referenceDataMessages] = await Promise.all([
              import('../../locales/es.json'),
              import('../../locales/reference-data/es'),
            ]);
            return {
              ...defaultMessages.default,
              ...referenceDataMessages.default,
            };
          case LanguageCode.TURKISH:
            dayjs.locale('tr');
            [defaultMessages, referenceDataMessages] = await Promise.all([
              import('../../locales/tr.json'),
              import('../../locales/reference-data/tr'),
            ]);
            return {
              ...defaultMessages.default,
              ...referenceDataMessages.default,
            };
          case LanguageCode.ENGLISH:
          default:
            dayjs.locale('en');
            [defaultMessages, referenceDataMessages] = await Promise.all([
              import('../../locales/en.json'),
              import('../../locales/reference-data/en'),
            ]);

            return {
              ...defaultMessages.default,
              ...referenceDataMessages.default,
            };
        }
      }

      loadMessages().then((loadedMessages) => {
        setMessages(loadedMessages as Record<string, string>);
      });
    }
    loadTranslations(userLocale);
    Sentry.setTag('user_locale', userLocale);
    updateUrlLangParam();
  }, [userLocale]);

  return (
    <LocaleContext.Provider value={{ setUserLocale, locale: userLocale }}>
      <IntlProvider locale={userLocale} messages={messages}>
        <Helmet>
          <html lang={userLocale} />
        </Helmet>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserRequest = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  locale?: string;
  termsAccepted?: boolean;
  /**
   * The gender specified by the user
   */
  gender?: UpdateUserRequest.gender;
};

export namespace UpdateUserRequest {
  /**
   * The gender specified by the user
   */
  export enum gender {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
  }
}

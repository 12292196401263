/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GclidEventRequest = {
  action: GclidEventRequest.action;
  gclid: string;
  plan?: string;
};

export namespace GclidEventRequest {
  export enum action {
    SIGNUP = 'signup',
    PURCHASE = 'purchase',
  }
}

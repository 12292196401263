import { CompanysizeType, RegionType } from '@orderfoxag/reference-data';
import { ReferenceDataType } from '@orderfoxag/reference-data/dist/esm/types/ReferenceDataType';

import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { SearchableConfigurationImpl } from '@/modules/matchmaking/models/searchbar/impl/SearchableConfigurationImpl';
import { SearchableRangeImpl } from '@/modules/matchmaking/models/searchbar/impl/SearchableRangeImpl';
import { SearchableRegionImpl } from '@/modules/matchmaking/models/searchbar/impl/SearchableRegionImpl';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';
import { SearchableRange } from '@/modules/matchmaking/models/searchbar/SearchableRange';
import { ReferenceDataTypeExtension } from '@/modules/matchmaking/types/reference-data-mappings/ReferenceDataTypeExtension';

import categoriesWeightData from '../types/reference-data-mappings/categories-weights.json';
import certificationsWeightData from '../types/reference-data-mappings/certifications-weights.json';
import companySizeWeightData from '../types/reference-data-mappings/companysize-weights.json';
import countriesWeightData from '../types/reference-data-mappings/countries-weights.json';
import machineriesWeightData from '../types/reference-data-mappings/machineries-weights.json';
import regionsWeightData from '../types/reference-data-mappings/regions-weights.json';
import { searchableMaterials } from './searchable-materials';
import { searchableTechnologies } from './searchable-technologies';

const technologiesWeightData = searchableTechnologies.map((tech) => ({
  id: tech.id,
  searchWeight: 0.5,
}));
const materialsWeightData = searchableMaterials.map((material) => ({
  id: material.id,
  searchWeight: 0.5,
}));

export class ReferenceDataMapper {
  private static mappings: Record<CategoryEnum, ReferenceDataTypeExtension[]> =
    {
      category: categoriesWeightData,
      certifications: certificationsWeightData,
      companySize: companySizeWeightData,
      countries: countriesWeightData,
      machinery: machineriesWeightData,
      materials: materialsWeightData,
      regions: regionsWeightData,
      technologies: technologiesWeightData,
      diameter: [],
      height: [],
      length: [],
      width: [],
      partType: [],
      partQuantity: [],
    };

  /**
   * Merges attributes from reference-data and locally stored additional attributes into a single object by their IDs.
   * @param categoryEnumKey
   * @param rawList
   */
  static mapReferenceDataTypeToSearchableConfiguration(
    categoryEnumKey: CategoryEnum,
    rawList: ReferenceDataType[],
  ): SearchableConfiguration[] {
    const idsToWeight: Record<string, number> =
      ReferenceDataMapper.getIdToWeightMap(categoryEnumKey);
    return rawList.reduce<SearchableConfiguration[]>((list, referenceData) => {
      const weight: number = idsToWeight[referenceData.id];
      if (weight > 0) {
        list.push(
          new SearchableConfigurationImpl(
            categoryEnumKey,
            referenceData.id,
            [],
            weight,
            [],
            referenceData.translationKey,
            weight,
            '',
          ),
        );
      }
      return list;
    }, []);
  }

  static mapRegionTypeToSearchableConfiguration(
    categoryEnumKey: CategoryEnum,
    rawList: RegionType[],
  ): SearchableConfiguration[] {
    const ids = ReferenceDataMapper.getIdToWeightMap(categoryEnumKey);
    return rawList.reduce<SearchableConfiguration[]>(
      (acc, { id, translationKey, countries }) => {
        const weight = ids[id];
        // filter out 0 weighted objects
        if (weight > 0) {
          acc.push(
            new SearchableRegionImpl(
              categoryEnumKey,
              id,
              [],
              weight,
              [],
              translationKey,
              weight,
              '',
              countries,
            ),
          );
        }
        return acc;
      },
      [],
    );
  }

  static mapCompanySizeTypeToSearchableRange(
    categoryEnumKey: CategoryEnum,
    rawList: CompanysizeType[],
  ): SearchableRange[] {
    const ids = ReferenceDataMapper.getIdToWeightMap(categoryEnumKey);
    return rawList.reduce<SearchableRange[]>(
      (acc, { id, translationKey, minValue, maxValue }) => {
        const weight = ids[id];
        // filter out 0 weighted objects
        if (weight > 0) {
          acc.push(
            new SearchableRangeImpl(
              categoryEnumKey,
              id,
              [],
              weight,
              [],
              translationKey,
              weight,
              '',
              minValue,
              maxValue,
            ),
          );
        }
        return acc;
      },
      [],
    );
  }

  /**
   * There are IDs in the reference-data project json files and there are the same IDs inside buyers-platform-fe project stored with additional attributes.
   * @param categoryEnumKey Category for which a map is generated.
   */
  private static getIdToWeightMap(
    categoryEnumKey: CategoryEnum,
  ): Record<string, number> {
    return this.mappings[categoryEnumKey as CategoryEnum].reduce<
      Record<string, number>
    >((acc, { id, searchWeight }) => {
      acc[id] = searchWeight;
      return acc;
    }, {});
  }
}

import { IntlFormatters } from 'react-intl';

import { SearchValidFiltersEnum } from '@/modules/matchmaking/models/searchbar/enums/SearchValidFiltersEnum';

export enum SearchFilterType {
  SINGLE = 'SINGLE',
  LIST = 'LIST',
}

/**
 * Contains the search term and objects typed into the searchbar.
 *
 * e.g. when user typed: 'United sta'
 * fulltext contains: 'United sta'
 * category contains: 'United'
 * value contains: 'sta'
 * filter contains: undefined
 */
export interface SearchFilterSearchValues {
  /**
   * Fulltext search string.
   */
  fullText?: string;

  /**
   * Name of the category in which to search for.
   */
  category?: string;

  /**
   * Value to match against suggestions inside a category.
   */
  value?: string;
  filter?: SearchFilter;
}

export type WeightedMatch<T = unknown> = [number, T];

export interface SearchFilterBase<T = unknown, V = unknown> {
  type: SearchFilterType;
  id: SearchValidFiltersEnum;
  label: string;
  unique?: boolean;
  suggestionLabelMatch: string;
  suggestionMatch(
    search: SearchFilterSearchValues,
    $t: IntlFormatters['$t'],
    maxSuggestions?: number | null,
  ): WeightedMatch<T>[] | null;
  format(value: T, $t: IntlFormatters['$t']): string;
  formatToSearch(value: T, $t: IntlFormatters['$t']): string;

  /**
   * Convert an object to a DTO (Data Transfer Object), plain string in this case.
   * @param value The object to convert to a single word text representation.
   */
  valueToDTO(value: T): V;

  /**
   * Convert an object from the DTO (=plain string).
   * @param values
   */
  valueFromDTO(
    values?: V | null | string | (V | null | string)[],
  ): T | T[] | null;
  hashValue(value: T): string;
}

export interface SearchFilterSingle<T = unknown, V = unknown>
  extends SearchFilterBase<T, V> {
  type: SearchFilterType.SINGLE;
  unique: true;
  suggestionList?($t: IntlFormatters['$t']): T[];
  valueFromDTO(values?: V | null | string): T | null;
}

export interface SearchFilterList<T = unknown, V = unknown>
  extends SearchFilterBase<T, V> {
  type: SearchFilterType.LIST;
  suggestionList(): T[];
  valueFromDTO(values?: V | null | string | (V | null | string)[]): T[] | null;
}

export type SearchFilter<T = unknown, V = unknown> =
  | SearchFilterSingle<T, V>
  | SearchFilterList<T, V>;

export enum SuggestionType {
  FILTER = 'filter',
  VALUE = 'value',
  LIST = 'list',
}

export interface BaseSuggestion<T = unknown> {
  id: string;
  type: SuggestionType;
  label: string;
  suggestionText: string;
  filter: SearchFilter<T>;
  selected?: boolean;
}

export interface FullTextSearchSuggestion<T = unknown>
  extends BaseSuggestion<T> {
  type: SuggestionType.VALUE;
  value: T;
}

export interface FilterSuggestion<T = unknown> extends BaseSuggestion<T> {
  type: SuggestionType.FILTER;
}

export interface ListSuggestion<T = unknown> extends BaseSuggestion<T> {
  type: SuggestionType.LIST;
  value: T;
}

export type Suggestion<T = unknown> =
  | FullTextSearchSuggestion<T>
  | FilterSuggestion<T>
  | ListSuggestion<T>;

/**
 * A suggestion that has a value. Whereas a filter suggestion does not have a value but also extends the base suggestion.
 */
export type ValuedSuggestion<T = unknown> =
  | ListSuggestion<T>
  | FullTextSearchSuggestion<T>;

import { Material, Technology } from '@/generated/types';

export enum DemandType {
  oneOff = 'oneOff',
  batches = 'batches',
}

export enum AcceptedFiles {
  PDF = 'PDF',
  STP = 'STP',
  STEP = 'STEP',
}

export interface UploadedFiles {
  stepFileToken: string;
  pdfFileToken?: string;
  size?: number;
}

export interface AnalyzedPart {
  id: string;
  stepFileToken?: string;
  stepFilename?: string;
  stepUrl?: string;
  stepPublicUrl?: string;
  pdfFileToken?: string;
  pdfFilename?: string;
  pdfUrl?: string;
  pdfPreviewImg?: string;
  imageUrl?: string;
  glbUrl?: string;
  technologies: Pick<Technology, 'id'>[];
  materials: Material[];
  tolerances: string[];
  metrologyMachineRequired?: boolean;
  certificationsRequired?: boolean;
  surfaceTolerances: string[];
  surfaceTreatments: string[];
  dimensionsMm: [number, number, number] | [];
  volumeCm3: number;
  weightGr?: number;
  turningLengthMm?: number;
  turningDiameterMm?: number;
  quantity: number;
  size?: number;
}

export interface AnalyzedPartNamed extends AnalyzedPart {
  name: string;
}

export interface Workpiece {
  id: string;
  name: string;
  quantities: number[];
  technologies: Pick<Technology, 'id'>[];
  materials: Material[];
  dimensionsMm: [number, number, number] | [];
  files: AnalyzedPartNamed[];
  showTechnologiesBadge: boolean;
  showMaterialsBadge: boolean;
  showDimensionsBadge: boolean;
}

export interface WorkingPart {
  id: string;
  files: Partial<UploadedFiles> | Partial<UploadedFiles>[];
  analyzedPart?: AnalyzedPart;
  batchSizes: number[];
  nbBatches: number;
  hasFollowUpOrders: boolean;
  demandType: DemandType;
  size?: number;
}

export type WorkingPartsMap = Record<string, WorkingPart>;

export enum ManufacturerFlags {
  ON_ORDERFOX = 'onOrderfox',
  AUDITED_BY_ORDERFOX = 'auditedByOrderfox',
  TIER_THREE_PAID_MEMBER = 'TIER3',
  TIER_TWO_PAID_MEMBER = 'TIER2',
  TIER_ONE_PAID_MEMBER = 'TIER1',
  TIER_ZERO_PAID_MEMBER = 'TIER0',
  BOOKMARKED = 'bookmarked',
}

export enum ManufacturerCapacity {
  UNKNOWN = 'unknown',
  FREE = 'free',
  NONE = 'none',
}

export interface Machine {
  model: string;
  brand: string;
  score: number;
  match?: boolean;
}

export interface ManufacturerContactAvailability {
  email: boolean;
  tel_number: boolean;
  address: boolean;
  latitude: boolean;
  longitude: boolean;
}

export interface MetrologyMachine {
  model: string;
  brand: string;
  quantity: number;
  score: number;
}

export interface PartImageObject {
  /* size */
  is_size_s: boolean;
  is_size_m: boolean;
  is_size_l: boolean;
  /* technology */
  is_milling: boolean;
  is_turning: boolean;
  is_cutting: boolean;
  is_joining: boolean;
  is_stamping: boolean;
  is_casting_moulding: boolean;
  is_forming: boolean;
  /* Shape */
  is_gear: boolean;
  is_shaft: boolean;
  is_screw: boolean;
  is_tube: boolean;
  /* material */
  is_metal: boolean;
  is_aluminium: boolean;
  is_brass: boolean;
  is_plastic: boolean;
  /* url */
  url: string;
}

export interface MatchedManufacturer {
  name?: string;
  id: string;
  partfox_id: number | null;
  country: string;
  zip?: string;
  employeeCount?: string;
  foundingYear: number | null;
  certificationIds?: string[];
  lotSizes?: string;
  paymentTerms?: string;
  matchRatingPct: number;
  rating: number | null;
  machines: Machine[];
  metrologyMachines: MetrologyMachine[];
  matchFilter: number;
  contactDetails: ManufacturerContactAvailability;
  flags: ManufacturerFlags[];
  hero_images: string[] | null;
  availableCapacity: ManufacturerCapacity;
  logoUrl: string | null;
  partfox_uuid: string | null;
  materialIds: string[];
  technologyIds: string[];

  /**
   * The youtube url of the video
   */
  promotionalVideo?: string | null;
  companyPresentation?: string | null;
  promotionalPdf?: string | null;
  partImageObjects?: PartImageObject[];
  /**
   * e.g. 32 RFQ Invitations
   */
  interactions?: number;
}

export interface MatchedManufacturerLight
  extends Pick<MatchedManufacturer, 'id' | 'partfox_id' | 'machines'> {}

export * from './search';

export default function FlagZH(
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1053_11752)">
        <path d="M0 0H21.3333V16H0V0Z" fill="#EE1C25" />
        <path
          d="M2.5596 5.9201L3.99958 1.6001L5.43956 5.9201L1.59961 3.2801H6.39955L2.5596 5.9201Z"
          fill="#FFFF00"
        />
        <path
          d="M8.80511 1.68287L7.32319 2.01127L8.31169 0.859385L8.21473 2.40968L7.39236 1.0372L8.80511 1.68287Z"
          fill="#FFFF00"
        />
        <path
          d="M10.3004 3.5782L8.80706 3.30615L10.1649 2.6278L9.47431 4.01914L9.2486 2.43514L10.3004 3.5782Z"
          fill="#FFFF00"
        />
        <path
          d="M10.0838 6.23746L8.8313 5.38001L10.3478 5.31446L9.14974 6.30315L9.58968 4.76482L10.0838 6.23746Z"
          fill="#FFFF00"
        />
        <path
          d="M8.19911 7.97461L7.37497 6.69994L8.79907 7.22519L7.31216 7.67445L8.3121 6.42541L8.19911 7.97461Z"
          fill="#FFFF00"
        />
      </g>
      <defs>
        <clipPath id="clip0_1053_11752">
          <rect width="21.3333" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

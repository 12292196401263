import { IntlFormatters } from 'react-intl';

import staticIntl from '@/core/libs/static-intl';
import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';

export abstract class AbstractSearchableConfiguration
  implements SearchableConfiguration
{
  public abstract readonly id: string;

  public abstract readonly translationKey: string;

  public abstract readonly descriptionTranslationKey: string;

  public abstract readonly category: CategoryEnum;

  /**
   * General search weight with a range from 0.0 - 1.0.
   * Values might be edited manually, therefore use the following simple definition.
   * 0.0 : hidden.
   * 0.5 : default.
   * 1.0 : on top of suggestions.
   */
  public abstract readonly searchWeight: number;

  /**
   * Non-internationalized shortcut. e.g. CH for Switzerland,  Britain for UK
   */
  protected abstract readonly aliases: string[];

  /**
   * Order inside its own category.
   */
  protected abstract readonly weightInsideCategory: number;

  /**
   * Other searchable objects related to this one. e.g. list of countries for a region.
   */
  protected abstract readonly relatives: SearchableConfiguration[];

  abstract matchingWeight(searchTerm: string): number;

  label(): string {
    return this.$t({ id: this.translationKey });
  }

  /**
   * @return List of lower cased values to compare when filtering. e.g. translated name, aliases, ...
   */
  searchableValues(): string[] {
    return [
      this.label().toLowerCase(),
      ...this.aliases.map((value) => value.toLowerCase()),
    ];
  }

  protected get $t(): IntlFormatters['$t'] {
    return staticIntl.instance.$t;
  }
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { PageLoader } from '@/core/components/layout/page-loader';
import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { errorHandler } from '@/core/libs/error-handler';
import { Company, Project, ProjectInvitation } from '@/generated/api';

import {
  useChangeRFQStatusByTokenToViewed,
  useChangeRFQStatusForLoggedInUserToViewed,
} from '../services/mutations';
import { RFQNavigationType, useRFQDetails } from '../services/queries';

export interface RFQContext {
  rfq: Project | undefined;
  isOwner: boolean;
  isUserInTenderArea: boolean;
  isLocked: boolean;
  invitation?: ProjectInvitation;
  isLoading: boolean;
  invitationToken?: string;
  isError: boolean;
  refetch: () => void;
  markInvitationViewed: (project: Project) => Promise<void>;
}

const RFQContext = createContext<RFQContext>({
  rfq: undefined,
  isOwner: false,
  isUserInTenderArea: false,
  isLocked: false,
  isLoading: false,
  isError: false,
  refetch: () => {},
  markInvitationViewed: async () => {},
});

export function RFQContextProvider(): React.ReactNode {
  const { pathname } = useLocation();
  const { invitationToken, projectId } = useParams();
  const navigate = useNavigate();
  const { currentUser, isLoggedIn } = useAuthenticationContext();

  const projectIdOrToken = invitationToken || projectId;

  const { data, isFetching, refetch, isError } = useRFQDetails({
    projectIdOrToken,
    type: invitationToken ? RFQNavigationType.TOKEN : RFQNavigationType.LINK,
    isEdit: pathname.includes('edit'),
  });

  const [rfq, setRfq] = useState<Project | undefined>(data?.project);
  const [invitation, setInvitation] = useState<ProjectInvitation | undefined>(
    data?.invitation,
  );

  useEffect(() => {
    if (data) {
      setRfq(data.project);
      setInvitation(data.invitation);
    }
  }, [data]);

  const changeRFQStatusMutation = useChangeRFQStatusForLoggedInUserToViewed();
  const changeRFQStatusByTokenMutation = useChangeRFQStatusByTokenToViewed();

  const isOwner = useMemo<boolean>(
    () => !!rfq && currentUser?.company.id === rfq.companyId,
    [rfq, currentUser],
  );
  const isUserInTenderArea = useMemo<boolean>(() => {
    if (!!rfq && (rfq.tenderArea === null || rfq.tenderArea.length === 0))
      return true;
    if (currentUser === null) return true; // access via token
    if (!!rfq && rfq.companyId === currentUser.company.id) return true;
    if (
      !!rfq &&
      rfq.tenderArea?.includes(currentUser?.company?.address?.country || '')
    )
      return true;

    return false;
  }, [rfq, currentUser]);

  const isLocked = useMemo<boolean>(() => {
    if (
      currentUser &&
      currentUser.company.membershipPlan &&
      [
        Company.membershipPlan.TIER1,
        Company.membershipPlan.TIER2,
        Company.membershipPlan.TIER3,
      ].includes(currentUser.company.membershipPlan)
    )
      return false;

    if (invitation?.isLocked && !currentUser) return true;
    if (invitation?.isLocked && currentUser?.company.membershipPlan === null)
      return true;

    return false;
  }, [currentUser, invitation?.isLocked]);

  const markInvitationViewed = useCallback(
    async (project: Project) => {
      try {
        let newInvitation;
        if (isLoggedIn) {
          newInvitation = await changeRFQStatusMutation.mutateAsync(project.id);
        } else if (
          invitationToken &&
          project.status !== Project.status.CLOSED
        ) {
          newInvitation = await changeRFQStatusByTokenMutation.mutateAsync(
            invitationToken,
          );
        }
        if (newInvitation) {
          setInvitation(newInvitation);
          setRfq(newInvitation.project);
        }
      } catch (error) {
        errorHandler.capture(error, {
          avoidFlashMessage: true,
        });
      }
    },
    [
      isLoggedIn,
      invitationToken,
      changeRFQStatusMutation,
      changeRFQStatusByTokenMutation,
    ],
  );

  useEffect(() => {
    if (invitationToken && currentUser && rfq)
      navigate(`/project/${rfq.id}/view`);
  }, [currentUser, invitationToken, navigate, rfq]);

  if (isError || (!isFetching && !data?.project)) {
    // Redirect in case of error or if no project is found
    invitationToken
      ? navigate(`/project-not-found?token=${invitationToken}`)
      : navigate(`/project-not-found?id=${projectId}`);
    return null;
  }

  if (isFetching) return <PageLoader />;

  const contextValue: RFQContext = {
    rfq,
    isOwner,
    isUserInTenderArea,
    isLocked,
    invitationToken,
    invitation,
    isLoading: isFetching,
    isError,
    refetch,
    markInvitationViewed,
  };

  return (
    <RFQContext.Provider value={contextValue}>
      <Outlet />
    </RFQContext.Provider>
  );
}

export function useRFQContext(): RFQContext {
  return useContext(RFQContext);
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { CheckoutRequest } from '../models/CheckoutRequest';
import type { PromotionCodeResponse } from '../models/PromotionCodeResponse';
import type { VoidDraftInvoiceRequest } from '../models/VoidDraftInvoiceRequest';

export class StripeService {
  /**
   * @param requestBody
   * @returns string If successful will return the Stripe SetupIntent CLIENT_SECRET
   * @throws ApiError
   */
  public static stripeControllerTrialPreCheckout(
    requestBody: CheckoutRequest,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/stripe/trial-pre-checkout',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Cannot retrieve setup intent`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns string If successful will return the Stripe CLIENT_SECRET or void
   * @throws ApiError
   */
  public static stripeControllerCheckout(
    requestBody: CheckoutRequest,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/stripe/checkout',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param couponCode
   * @returns PromotionCodeResponse If successful will return the Stripe Promotional Code
   * @throws ApiError
   */
  public static stripeControllerRetrievePromotionCode(
    couponCode: string,
  ): CancelablePromise<PromotionCodeResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/stripe/retrieve-promotion-code',
      query: {
        couponCode: couponCode,
      },
      errors: {
        404: `Cannot retrieve coupon`,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static stripeControllerWebhook(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/stripe/webhook',
    });
  }

  /**
   * @param requestBody Invoice ID
   * @returns any
   * @throws ApiError
   */
  public static stripeControllerVoidDraftInvoice(
    requestBody: VoidDraftInvoiceRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/stripe/void-draft-invoice',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param domain
   * @returns string Customer portal Url.
   * @throws ApiError
   */
  public static stripeControllerGetBillingPortalUrl(
    domain: any,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/stripe/billing-portal-url',
      query: {
        domain: domain,
      },
      errors: {
        400: `User has no Stripe ID.`,
      },
    });
  }
}

export enum CategoryLabelTranslationKeyEnum {
  height = 'supplierSearch.partDetails.labels.height',
  width = 'supplierSearch.partDetails.labels.width',
  length = 'supplierSearch.partDetails.labels.length',
  diameter = 'supplierSearch.partDetails.labels.diameter',
  companySize = 'supplierSearch.supplier.labels.employeeCount',
  certifications = 'supplierSearch.supplier.labels.certifications',
  materials = 'supplierSearch.partDetails.labels.materials',
  technologies = 'supplierSearch.partDetails.labels.technologies',
  countries = 'supplierSearch.supplier.labels.country',
  machinery = 'supplierSearch.searchBar.labels.machinery',
  regions = 'supplierSearch.partDetails.labels.regions',
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { CloudinaryUploadCredentialsDto } from '../models/CloudinaryUploadCredentialsDto';
import type { S3UploadCredentialsDto } from '../models/S3UploadCredentialsDto';

export class FilesService {
  /**
   * @returns S3UploadCredentialsDto
   * @throws ApiError
   */
  public static filesControllerGenerateTempS3UploadCredentials(): CancelablePromise<S3UploadCredentialsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/files/s3/temp-upload-credentials',
    });
  }

  /**
   * @returns S3UploadCredentialsDto
   * @throws ApiError
   */
  public static filesControllerGenerateCompanyS3UploadCredentials(): CancelablePromise<S3UploadCredentialsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/files/s3/company-upload-credentials',
    });
  }

  /**
   * @returns CloudinaryUploadCredentialsDto
   * @throws ApiError
   */
  public static filesControllerGenerateCloudinaryUploadSignature(): CancelablePromise<CloudinaryUploadCredentialsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/files/cloudinary/upload-credentials',
    });
  }
}

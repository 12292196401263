/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MachineSpecsDto } from './MachineSpecsDto';

export type MachineDto = {
  id: number | null;
  brand: string;
  model: string;
  quantity: number;
  status: MachineDto.status;
  machineType: string | null;
  manufacturingProcesses: Array<string>;
  specs: MachineSpecsDto;
};

export namespace MachineDto {
  export enum status {
    HIDDEN = 'hidden',
    VISIBLE = 'visible',
  }
}

import { errorHandler } from '@/core/libs/error-handler';
import { fetchApi } from '@/core/libs/fetch-api/fetch-api';
import {
  AnalyzedPart,
  WorkingPart,
  WorkingPartsMap,
} from '@/modules/matchmaking/types';

export interface AnalyzePartFilesResponse {
  parts: WorkingPartsMap;
  loadingTimeMS: number;
}

const ANALYZER_API_URL = import.meta.env.PUBLIC_ANALYSER_API_URL as string;
const BATCH_SIZE = 5;

export async function analyzePartFiles(
  workingParts: WorkingPartsMap,
): Promise<AnalyzePartFilesResponse> {
  const startTime = Date.now();
  const values = Object.values(workingParts);

  async function analyzeBatch(batch: WorkingPart[]): Promise<WorkingPartsMap> {
    const results = await fetchApi.post<{ parts?: AnalyzedPart[] }>(
      `${ANALYZER_API_URL}/api/man-search/analyze-parts`,
      {
        parts: batch.map(({ files, batchSizes, id }) => ({
          id: id ? id : undefined,
          ...files,
          quantity: batchSizes[0],
        })),
      },
      {
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'text/plain; charset=utf-8',
        },
      },
    );

    if (!results.parts?.length) {
      throw new Error('No parts analyzed in this batch');
    }

    return results.parts.reduce<WorkingPartsMap>(
      (list, analyzedPart, index) => {
        const part = batch[index];
        return {
          ...list,
          [part.id]: {
            ...part,
            analyzedPart,
          },
        };
      },
      {},
    );
  }

  async function processAllBatchesInParallel(): Promise<WorkingPartsMap> {
    const batches = [];
    for (let i = 0; i < values.length; i += BATCH_SIZE) {
      batches.push(values.slice(i, i + BATCH_SIZE));
    }

    const batchResults = await Promise.all(batches.map(analyzeBatch));

    return batchResults.reduce((acc, result) => ({ ...acc, ...result }), {});
  }

  if (values.length === 0) {
    throw new Error('No parts to analyze');
  }

  try {
    const analyzedParts = await processAllBatchesInParallel();
    return {
      parts: analyzedParts,
      loadingTimeMS: Date.now() - startTime,
    };
  } catch (error) {
    errorHandler.capture(error);
    throw error;
  }
}

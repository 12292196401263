import { CategoryDescriptionTranslationKeyEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryDescriptionTranslationKeyEnum';
import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { CategoryLabelTranslationKeyEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryLabelTranslationKeyEnum';
import { SearchableConfigurationImpl } from '@/modules/matchmaking/models/searchbar/impl/SearchableConfigurationImpl';
import { SearchableUnitImpl } from '@/modules/matchmaking/models/searchbar/impl/SearchableUnitImpl';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';
import { SearchableUnit } from '@/modules/matchmaking/models/searchbar/SearchableUnit';

/**
 * Synthetic searchables are generated on the fly and not stored anywhere as a dataset.
 */
export class SyntheticSearchableConfigurationFactory {
  static getSyntheticSearchableUnits(
    categoryEnumKey: CategoryEnum,
  ): SearchableUnit[] {
    return [...Array(20)]
      .map((v, index) => (index + 1) * (10 * (index + 1)))
      .map(
        (value) =>
          new SearchableUnitImpl(
            categoryEnumKey,
            categoryEnumKey + value,
            [],
            0.5,
            [],
            'General.mm',
            Object.values(CategoryEnum).indexOf(
              categoryEnumKey as CategoryEnum,
            ),
            'supplierSearch.partDetails.labels.dimensionsMm',
            value,
          ),
      );
  }

  static getSyntheticCategories(): SearchableConfiguration[] {
    const result: SearchableConfiguration[] = [];
    for (const categoryEnumKey in CategoryEnum) {
      result.push(
        new SearchableConfigurationImpl(
          CategoryEnum.category,
          categoryEnumKey,
          [],
          Object.keys(CategoryEnum).indexOf(categoryEnumKey),
          [],
          CategoryLabelTranslationKeyEnum[
            categoryEnumKey as keyof typeof CategoryLabelTranslationKeyEnum
          ],
          Object.values(CategoryEnum).indexOf(categoryEnumKey as CategoryEnum),
          CategoryDescriptionTranslationKeyEnum[
            categoryEnumKey as keyof typeof CategoryDescriptionTranslationKeyEnum
          ],
        ),
      );
    }
    return result;
  }
}

import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { errorHandler } from '@/core/libs/error-handler';
import { AuthService, LoginResponseDto } from '@/generated/api';

export type UseLogin = (
  email: string,
  password: string,
) => Promise<LoginResponseDto>;

export default function useLogin(): UseLogin {
  const { $t } = useIntl();

  return useCallback(
    async (email: string, password: string): Promise<LoginResponseDto> => {
      try {
        const session = await AuthService.authControllerLogin({
          email,
          password,
        });
        return session;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (
          error.status === 401 &&
          error.body.message === 'User is not enrolled'
        ) {
          window.location.href = '/pending-enrollment';
        } else if (
          error.status === 401 &&
          error.body.message === 'Email not confirmed'
        ) {
          window.location.href = `/verify-email?email=${encodeURIComponent(
            email,
          )}`;
        } else if (error.status === 401) {
          errorHandler.capture(error, {
            userErrorMessage: $t({ id: 'Account.error.wrongUserData' }),
          });
        } else {
          errorHandler.capture(error, {
            userErrorMessage: $t({ id: 'General.unknownError' }),
          });
        }

        throw error;
      }
    },
    [$t],
  );
}

/**
 * Names of possible categories for filtering in the searchbar.
 * The order of the categories prioritize their order when displaying in a list of suggestions.
 */
export enum CategoryEnum {
  technologies = 'technologies',
  countries = 'countries',
  length = 'length',
  diameter = 'diameter',
  height = 'height',
  width = 'width',
  regions = 'regions',
  certifications = 'certifications',
  companySize = 'companySize',
  materials = 'materials',
  machinery = 'machinery',
  partType = 'partType',
  partQuantity = 'partQuantity',
  category = 'category',
}

import { materials, MaterialType } from '@orderfoxag/reference-data';

export const searchableMaterials: MaterialType[] = materials.filter(({ id }) =>
  [
    'm1', // Acrylic (PMMA)
    'm10', // Composites
    'm100', // PVC
    'm101', // POM
    'm105', // Nylon 6
    'm106', // Nylon 6.6
    'm11', // Duroplast
    'm13', // Stainless steel
    'm15', // Case-hardened / heat-treated steel
    'm16', // Elastomers
    'm17', // Spring steel
    'm19', // Glass fibre reinforced plastic
    'm20', // Glass / minerals
    'm200', // PE
    'm201', // PS
    'm202', // Gold
    'm203', // Silver
    'm204', // Paladium
    'm205', // Platinum
    'm21', // Glass ceramic / lithium disilicate
    'm23', // Precious metals
    'm25', // Grey iron
    'm26', // Cast iron
    'm27', // Carbides
    'm28', // High-performance plastics
    'm29', // Solid wood
    'm30', // Cold forged steel
    'm32', // Ceramic
    'm33', // Cobalt-chrome
    'm35', // Copper and copper alloys
    'm39', // Magnesium
    'm3D001', // ABS
    'm3D008', // PC
    'm3D015a', // PA
    'm3D017a', // PP
    'm3D027a', // PHA
    'm3D028', // PEEK
    'm3D029a', // HIPS
    'm3D030', // PLA
    'm3D033a', // PETG
    'm3D034', // TPU
    'm3D035a', // TPE
    'm3D036', // PVA
    'm40', // Brass
    'm43', // Molybdenum
    'm44', // Natural fibre reinforced plastic
    'm46', // Nickel alloys
    'm47', // Optical glass
    'm5', // Aluminium
    'm50', // Quartz glass
    'm56', // Silicon carbide
    'm57', // Silicon nitride
    'm61', // Cast steel
    'm63', // Thermoplastic
    'm64', // Titanium
    'm66', // Tool steel
    'm67', // Tungsten
    'm7', // Machining steel
    'm70', // Zinc and zinc alloys
    'm77', // Plywood
    'm8', // Construction steel
    'm9', // Carbon fibre reinforced plastic
    'm94', // Composite wood
    'm97', // Form solid wood
    'm98', // Watering solid wood
    'mt01', // Cast metals
    'mt03', // Compounds
    'mt04', // Elastic plastics
    'mt05', // Light metals
    'mt06', // Natural materials
    'mt07', // Nonferrous metals
    'mt10', // Plastics
    'mt13', // Steel
    'mt15', // Wood
    'mt16', // High-performance alloys'
  ].includes(id),
);

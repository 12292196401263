/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DisableSubscriptionRequest = {
  reason: DisableSubscriptionRequest.reason;
};

export namespace DisableSubscriptionRequest {
  export enum reason {
    TOO_EXPENSIVE = 'too_expensive',
    MISSING_FEATURES = 'missing_features',
    SWITCHED_SERVICE = 'switched_service',
    UNUSED = 'unused',
    CUSTOMER_SERVICE = 'customer_service',
    TOO_COMPLEX = 'too_complex',
    LOW_QUALITY = 'low_quality',
    OTHER = 'other',
  }
}

export enum SearchValidFiltersEnum {
  CERTIFICATIONS = 'certifications',
  HEIGHT = 'height',
  WIDTH = 'width',
  LENGTH = 'length',
  DIAMETER = 'diameter',
  MATERIALS = 'materials',
  TECHNOLOGIES = 'technologies',
  COMPANY_SIZE = 'companySize',
  COUNTRIES = 'countries',
  MACHINERY = 'machinery',
  HAS_CAPACITY = 'hasCapacity',
  IS_VERIFIED = 'isVerified',
  REGIONS = 'regions',
  PART_TYPE = 'part_types',
  PART_QUANTITY = 'part_quantity',
}

import { useCallback } from 'react';

import { FileType } from '@/core/components/rsuite/Uploader/Uploader';
import { fetchApi } from '@/core/libs/fetch-api/fetch-api';

import { AcceptedFiles } from '../types';

interface UploadSuccessResponse {
  success: true;
  fileToken: string;
}

interface UploadUnsuccessfulResponse {
  success: false;
}

type UploadResponse = UploadSuccessResponse | UploadUnsuccessfulResponse;

export interface FileAnalyzerUploaderResponse {
  token: string;
  extension: AcceptedFiles;
  size?: number;
}

export interface UseFileAnalyzerUploader {
  upload(file: FileType): Promise<FileAnalyzerUploaderResponse>;
  isAcceptedFile(extension?: string): extension is AcceptedFiles;
}

export function useFileAnalyzerUploader(): UseFileAnalyzerUploader {
  function readFile(file: Blob, format = 'text'): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.addEventListener('loadend', () => {
        resolve(fileReader.result as string);
      });
      fileReader.onerror = reject;
      if (format === 'text') {
        fileReader.readAsText(file, 'UTF-8');
      } else if (format === 'binary') {
        fileReader.readAsBinaryString(file);
      } else if (format === 'base64url') {
        fileReader.readAsDataURL(file);
      } else {
        throw new Error(`Unknown format "${format}"`);
      }
    });
  }

  const getFileExtension = (filename?: string): string | undefined =>
    filename?.toLowerCase().split('.').pop();

  const isAcceptedFile = useCallback(
    (extension?: string): extension is AcceptedFiles => {
      return extension
        ? Object.values(AcceptedFiles).includes(extension as AcceptedFiles)
        : false;
    },
    [],
  );

  const upload = useCallback(
    async ({ blobFile }: FileType): Promise<FileAnalyzerUploaderResponse> => {
      if (!blobFile) {
        throw Error('No file selected');
      }

      const extension: string | undefined = getFileExtension(
        blobFile.name,
      )?.toLocaleUpperCase();
      if (isAcceptedFile(extension)) {
        const fileContent =
          extension === AcceptedFiles.PDF
            ? btoa(await readFile(blobFile, 'binary'))
            : await readFile(blobFile);

        try {
          const apiUrl = import.meta.env.PUBLIC_ANALYSER_API_URL as string;
          const result = await fetchApi.post<UploadResponse>(
            extension === AcceptedFiles.PDF
              ? `${apiUrl}/api/man-search/upload-pdf-file`
              : `${apiUrl}/api/man-search/upload-step-file`,
            extension === AcceptedFiles.PDF
              ? {
                  contentBase64: fileContent,
                  filename: blobFile.name,
                }
              : {
                  content: fileContent,
                  filename: blobFile.name,
                },
            {
              cache: 'no-cache',
              credentials: 'include',
              headers: {
                'Content-Type': 'text/plain; charset=utf-8',
              },
            },
          );
          if (!result.success) {
            throw Error('Something went wrong');
          }
          return {
            token: result.fileToken,
            extension,
          };
        } catch {
          throw Error('Unable to upload file');
        }
      } else {
        throw Error('Invalid extension');
      }
    },
    [isAcceptedFile],
  );

  return { upload, isAcceptedFile };
}

import { useQuery } from '@tanstack/react-query';

import { ProjectsService } from '@/generated/api';

export function useProjectEmailScheduleConfig() {
  return useQuery({
    queryKey: ['projectEmailScheduleConfig'],
    queryFn: () => ProjectsService.projectsControllerFindProjectSchedule(),
  });
}

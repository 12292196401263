/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SignupRequest = {
  /**
   * Country ID (see @orderfoxag/reference-data package)
   */
  country: string;
  state?: string;
  city?: string;
  zip?: string;
  street?: string;
  /**
   * The token of the invitation. Is in the url that the manufacturer receives
   */
  invitationToken?: string | null;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  password: string;
  website: string;
  locale: string;
  type: SignupRequest.type;
  /**
   * Whether the user has accepted the GTCs or not. If this is false, the use won't be able to use the protected routes
   */
  termsAccepted: boolean;
  companyId?: number;
  /**
   * Google Click Identifier
   */
  gclid?: string;
  /**
   * The gender specified by the user
   */
  gender?: SignupRequest.gender;
};

export namespace SignupRequest {
  export enum type {
    MANUFACTURER = 'manufacturer',
    BUYER = 'buyer',
    BOTH = 'both',
  }

  /**
   * The gender specified by the user
   */
  export enum gender {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
  }
}

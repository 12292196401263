import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/core/components/ui/tooltip';
import { cn } from '@/core/libs/utils';

import { Icon } from '../component-library/Icon/Icon';
import { IconType } from '../component-library/Icon/IconType';
import { Badge } from '../ui/badge';
import { useLayoutContext } from './Layout';

type NavItemProps = {
  as?: React.ElementType;
  to?: string;
  active?: boolean;
  onClick?: () => void;
  icon: IconType | React.ReactNode;
  text: string;
  showTooltip?: boolean;
  className?: string;
  isSubMenuItem?: boolean;
  dataTestId?: string;
  isNew?: boolean;
};

export const NavItem = React.forwardRef<HTMLDivElement, NavItemProps>(
  (
    {
      as = Link,
      to,
      active,
      onClick,
      icon,
      text,
      showTooltip = true,
      className,
      isSubMenuItem = false,
      dataTestId,
      isNew = false,
    },
    ref,
  ) => {
    const { expanded } = useLayoutContext();
    const { $t } = useIntl();
    const Component = as;
    const i =
      typeof icon === 'string' ? (
        <Icon
          type={icon as IconType}
          className="flex size-6 items-center justify-center text-xl"
        />
      ) : (
        icon
      );
    return (
      <TooltipProvider disableHoverableContent>
        <Tooltip>
          <TooltipTrigger asChild>
            <Component
              to={to}
              onClick={onClick}
              className={cn(
                'relative flex h-12 w-full items-center justify-start rounded-xl bg-white px-4 py-3 text-base !font-normal no-underline transition-colors hover:bg-neutral-100 hover:text-neutral-600 hover:no-underline focus-visible:text-neutral-600 focus-visible:no-underline',
                expanded || isSubMenuItem ? 'gap-4' : 'gap-0',
                active ? '!text-primary-700' : 'text-neutral-600',
                className,
              )}
              ref={ref}
              data-testid={dataTestId}
            >
              {i}
              {isNew && !expanded && (
                <Badge
                  size="xs"
                  color="primary"
                  className="absolute right-0 top-0 cursor-pointer px-1 py-0 text-2xs uppercase"
                >
                  {$t({ id: 'General.new' })}
                </Badge>
              )}
              <span
                className={cn(
                  'overflow-hidden text-left transition-all',
                  expanded || (!expanded && isSubMenuItem)
                    ? 'w-52'
                    : 'hidden w-0',
                )}
              >
                {text}
                {isNew && (
                  <Badge
                    size="xxs"
                    color="primary"
                    className="ml-4 cursor-pointer uppercase"
                  >
                    {$t({ id: 'General.new' })}
                  </Badge>
                )}
              </span>
            </Component>
          </TooltipTrigger>
          {showTooltip && !expanded && (
            <TooltipContent side="right" className="self-center py-1">
              {text}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    );
  },
);
NavItem.displayName = 'NavItem';

export default function FlagIT(
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1053_9633)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H21.3333V16H0V0Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H7.11V16H0V0Z"
          fill="#009246"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2227 0H21.3327V16H14.2227V0Z"
          fill="#CE2B37"
        />
      </g>
      <defs>
        <clipPath id="clip0_1053_9633">
          <rect width="21.3333" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

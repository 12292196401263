export enum CategoryDescriptionTranslationKeyEnum {
  category = '',
  height = 'supplierSearch.searchBar.suggestions.height',
  width = 'supplierSearch.searchBar.suggestions.width',
  length = 'supplierSearch.searchBar.suggestions.length',
  diameter = 'supplierSearch.searchBar.suggestions.diameter',
  companySize = 'supplierSearch.searchBar.suggestions.employeeCount',
  certifications = 'supplierSearch.searchBar.suggestions.certifications',
  materials = 'supplierSearch.searchBar.suggestions.materials',
  technologies = 'supplierSearch.searchBar.suggestions.technologies',
  countries = 'supplierSearch.searchBar.suggestions.country',
  machinery = 'supplierSearch.searchBar.suggestions.machinery',
  regions = 'supplierSearch.searchBar.suggestions.region',
}

import { Toaster as SonnerToaster } from 'sonner';

import { Breakpoints, useBreakpoint } from '@/core/context/BreakpointContext';

export function Toaster(): JSX.Element {
  const { breakpoint } = useBreakpoint();

  return (
    <SonnerToaster
      position={breakpoint === Breakpoints.SM ? 'top-center' : 'top-right'}
      toastOptions={{
        duration: 4500,
      }}
    />
  );
}

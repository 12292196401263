import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const BOOST_KEY = 'boost';

export function useSalesBoostUrl(): string {
  const [params, setParams] = useSearchParams();
  const getBoostedUrl = useCallback((): string => {
    const manUrl = params.get(BOOST_KEY);

    if (!manUrl) return sessionStorage.getItem(BOOST_KEY) || '';

    const withoutBoost = [...params.entries()].filter(
      ([key]) => key !== BOOST_KEY,
    );
    setParams(withoutBoost);
    sessionStorage.setItem(BOOST_KEY, manUrl);
    return manUrl;
  }, [params, setParams]);
  const [boostedUrl, setBoostUrl] = useState<string>(getBoostedUrl());

  useEffect(() => {
    const manUrl = getBoostedUrl();

    if (boostedUrl !== manUrl) setBoostUrl(manUrl);
  }, [params, boostedUrl, getBoostedUrl]);

  return boostedUrl;
}

import { Icon } from '../components/component-library/Icon/Icon';
import { IconType } from '../components/component-library/Icon/IconType';

export interface SplitFileName {
  name: string;
  extension?: string;
}

export function splitFileName(fileName: string): SplitFileName {
  const split = fileName.split('.');
  const extension = split.length > 1 ? split.pop() : undefined;
  const name = split.join('.');
  return { name, extension };
}

export function renameFile(originalFile: File, newName: string): File {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export const normalizeFile = (file: File): File =>
  renameFile(file, file.name.replace(/[^\w.]+/g, '_'));

export function renderFileIcon(extension?: string): IconType {
  switch (extension) {
    case 'pdf':
      return Icon.type.BLUEPRINT;
    case 'stp':
      return Icon.type.PERSPECTIVE_3D;
    case 'step':
      return Icon.type.PERSPECTIVE_3D;
    default:
      return Icon.type.FILE;
  }
}

import { formatNumber } from '@/core/libs/formatters';
import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { SuggestionsList } from '@/modules/matchmaking/models/searchbar/impl/SuggestionsList';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';
import { SearchableConfigurationFactory } from '@/modules/matchmaking/utils/factories/SearchableConfigurationFactory';
import { SyntheticSearchableConfigurationFactory } from '@/modules/matchmaking/utils/factories/SyntheticSearchableConfigurationFactory';
import { FuzzyMatcher } from '@/modules/matchmaking/utils/FuzzyMatcher';

import { SearchValidFiltersEnum } from '../models/searchbar/enums/SearchValidFiltersEnum';
import {
  SearchFilter,
  SearchFilterList,
  SearchFilterSearchValues,
  SearchFilterSingle,
  SearchFilterType,
  WeightedMatch,
} from '../types';

function getFromReferenceData<T, L extends SearchableConfiguration>(
  value: T | T[],
  list: L[],
): L[] | null {
  if (Array.isArray(value)) {
    const finalList = value.reduce<L[]>((valueList, item) => {
      const found = list?.find(({ id }) => id === item);
      if (found) {
        valueList.push(found);
      }
      return valueList;
    }, []);
    return finalList.length ? finalList : null;
  }
  const valueList: L[] = [];
  const found = list?.find(({ id }) => id === value);
  if (found) valueList.push(found);
  return valueList.length ? valueList : null;
}

function getNumberMatch({
  fullText,
}: SearchFilterSearchValues): WeightedMatch<number>[] | null {
  // some countries write scalar numbers with a comma or a dot
  const numbers = (fullText || '').match(/\d*\.?/gi)?.join('');
  if (numbers && parseFloat(numbers) > 0) {
    return [[0.9, parseFloat(numbers)]];
  } else {
    return null;
  }
}

function numberFromDTO(value?: string | null | number): number | null {
  if (value === undefined || value === null) {
    return null;
  }
  const parsed = parseFloat(value.toString());
  return parsed || null;
}

const companysizes = SearchableConfigurationFactory.getSearchableConfigurations(
  CategoryEnum.companySize,
);
const companySizeList = new SuggestionsList(companysizes);

const companySizeFilterconfig: SearchFilterList<
  SearchableConfiguration,
  string
> = {
  type: SearchFilterType.LIST,
  id: SearchValidFiltersEnum.COMPANY_SIZE,
  label: 'supplierSearch.supplier.labels.employeeCount',
  unique: false,
  suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.employeeCount',
  suggestionMatch: (fullText) =>
    companysizes.reduce<WeightedMatch<SearchableConfiguration>[]>(
      (matches, item) => {
        const weight = item.matchingWeight(fullText.fullText ?? '');
        if (weight > 0) {
          matches.push([weight, item]);
        }
        return matches;
      },
      [],
    ),
  suggestionList: () => companySizeList.sortedList,
  format: (value, $t) => $t({ id: value.translationKey }),
  formatToSearch: (value, $t) => $t({ id: value.translationKey }),
  valueToDTO: (value) => value.id,
  valueFromDTO: (value) => getFromReferenceData(value, companysizes),
  hashValue: (value) => value.id,
};

const subsetTechnologies: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.technologies,
  );

const technologiesMatcher: FuzzyMatcher<SearchableConfiguration> =
  new FuzzyMatcher(
    'supplierSearch.partDetails.labels.technologies',
    subsetTechnologies,
  );
const technologiesList = new SuggestionsList(subsetTechnologies);

const technologiesFilterconfig: SearchFilterList<
  SearchableConfiguration,
  string
> = {
  type: SearchFilterType.LIST,
  id: SearchValidFiltersEnum.TECHNOLOGIES,
  label: 'supplierSearch.partDetails.labels.technologies',
  unique: false,
  suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.technologies',
  suggestionMatch: (values, $t, maxSuggestions) =>
    technologiesMatcher.getMatches(values, maxSuggestions),
  suggestionList: () => technologiesList.sortedList,
  format: (value, $t) => $t({ id: value.translationKey }),
  formatToSearch: (value, $t) => $t({ id: value.translationKey }),
  valueToDTO: (value) => value.id,
  valueFromDTO: (value) => getFromReferenceData(value, subsetTechnologies),
  hashValue: (value) => value.id,
};

const materials = SearchableConfigurationFactory.getSearchableConfigurations(
  CategoryEnum.materials,
);

const materialsMatcher = new FuzzyMatcher(
  'supplierSearch.partDetails.labels.materials',
  materials,
);
const materialsList = new SuggestionsList(materials);

const materialsFilterconfig: SearchFilterList<SearchableConfiguration, string> =
  {
    type: SearchFilterType.LIST,
    id: SearchValidFiltersEnum.MATERIALS,
    label: 'supplierSearch.partDetails.labels.materials',
    unique: false,
    suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.materials',
    suggestionMatch: (value, $t, maxSuggestions) =>
      materialsMatcher.getMatches(value, maxSuggestions),
    suggestionList: () => materialsList.sortedList,
    format: (value, $t) => $t({ id: value.translationKey }),
    formatToSearch: (value, $t) => $t({ id: value.translationKey }),
    valueToDTO: (value) => value.id,
    valueFromDTO: (value) => getFromReferenceData(value, materials),
    hashValue: (value) => value.id,
  };

const certifications =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.certifications,
  );

const certificationsMatcher = new FuzzyMatcher(
  'supplierSearch.supplier.labels.certifications',
  certifications,
);
const certificationsList = new SuggestionsList(certifications);

const certificationsFilterConfig: SearchFilterList<
  SearchableConfiguration,
  string
> = {
  type: SearchFilterType.LIST,
  id: SearchValidFiltersEnum.CERTIFICATIONS,
  label: 'supplierSearch.supplier.labels.certifications',
  suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.certifications',
  unique: false,
  suggestionMatch: (value, $t, maxSuggestions) =>
    certificationsMatcher.getMatches(value, maxSuggestions),
  suggestionList: () => certificationsList.sortedList,
  format: (value, $t) => $t({ id: value.translationKey }),
  formatToSearch: (value, $t) => $t({ id: value.translationKey }),
  valueToDTO: (value) => value.id,
  valueFromDTO: (value) => getFromReferenceData(value, certifications),
  hashValue: (value) => value.id,
};

const countries = SearchableConfigurationFactory.getSearchableConfigurations(
  CategoryEnum.countries,
);
const countryMatcher = new FuzzyMatcher<SearchableConfiguration>(
  'supplierSearch.supplier.labels.country',
  countries,
);
const countriesList = new SuggestionsList(
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.countries,
  ),
);

const countryFilterConfig: SearchFilterList<SearchableConfiguration, string> = {
  type: SearchFilterType.LIST,
  id: SearchValidFiltersEnum.COUNTRIES,
  label: 'supplierSearch.supplier.labels.country',
  suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.country',
  unique: false,
  suggestionMatch: (value, $t, maxSuggestions) =>
    countryMatcher.getMatches(value, maxSuggestions),
  suggestionList: () => countriesList.sortedList,
  format: (value, $t) => $t({ id: value.translationKey }),
  formatToSearch: (value, $t) => $t({ id: value.translationKey }),
  valueToDTO: (value) => value.id,
  valueFromDTO: (value) => getFromReferenceData(value, countries),
  hashValue: (value) => value.id,
};

const regions = SearchableConfigurationFactory.getSearchableConfigurations(
  CategoryEnum.regions,
);
const regionMatcher = new FuzzyMatcher<SearchableConfiguration>(
  'supplierSearch.partDetails.labels.regions',
  regions,
);
const regionsList = new SuggestionsList(regions);

const regionFilterConfig: SearchFilterList<SearchableConfiguration, string> = {
  type: SearchFilterType.LIST,
  id: SearchValidFiltersEnum.REGIONS,
  label: 'supplierSearch.partDetails.labels.regions',
  suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.region',
  unique: false,
  suggestionMatch: (value, $t, maxSuggestions) =>
    regionMatcher.getMatches(value, maxSuggestions),
  suggestionList: () => regionsList.sortedList,
  format: (value, $t) => $t({ id: value.translationKey }),
  formatToSearch: (value, $t) => $t({ id: value.translationKey }),
  valueToDTO: (value) => value.id,
  valueFromDTO: (value) => getFromReferenceData(value, regions),
  hashValue: (value) => value.id,
};

const heightFilterConfig: SearchFilterSingle<number, number> = {
  type: SearchFilterType.SINGLE,
  id: SearchValidFiltersEnum.HEIGHT,
  label: 'supplierSearch.partDetails.labels.height',
  unique: true,
  suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.height',
  suggestionMatch: getNumberMatch,
  format: (value) => `${formatNumber(value)}mm`,
  formatToSearch: (value) => value.toString(),
  valueToDTO: (value) => value,
  valueFromDTO: (value) => numberFromDTO(value),
  suggestionList: () =>
    SyntheticSearchableConfigurationFactory.getSyntheticSearchableUnits(
      CategoryEnum.height,
    ).map((value) => value.scalar),
  hashValue: (value) => value.toString(),
};

const widthFilterConfig: SearchFilterSingle<number, number> = {
  type: SearchFilterType.SINGLE,
  id: SearchValidFiltersEnum.WIDTH,
  label: 'supplierSearch.partDetails.labels.width',
  unique: true,
  suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.width',
  suggestionMatch: getNumberMatch,
  format: (value) => `${formatNumber(value)}mm`,
  formatToSearch: (value) => value.toString(),
  valueToDTO: (value) => value,
  valueFromDTO: (value) => numberFromDTO(value),
  suggestionList: () =>
    SyntheticSearchableConfigurationFactory.getSyntheticSearchableUnits(
      CategoryEnum.width,
    ).map((value) => value.scalar),
  hashValue: (value) => value.toString(),
};

const lengthFilterConfig: SearchFilterSingle<number, number> = {
  type: SearchFilterType.SINGLE,
  id: SearchValidFiltersEnum.LENGTH,
  label: 'supplierSearch.partDetails.labels.length',
  unique: true,
  suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.length',
  suggestionMatch: getNumberMatch,
  format: (value) => `${formatNumber(value)}mm`,
  formatToSearch: (value) => value.toString(),
  valueToDTO: (value) => value,
  valueFromDTO: (value) => numberFromDTO(value),
  suggestionList: () =>
    SyntheticSearchableConfigurationFactory.getSyntheticSearchableUnits(
      CategoryEnum.length,
    ).map((value) => value.scalar),
  hashValue: (value) => value.toString(),
};

const diameterFilterConfig: SearchFilterSingle<number, number> = {
  type: SearchFilterType.SINGLE,
  id: SearchValidFiltersEnum.DIAMETER,
  label: 'supplierSearch.partDetails.labels.diameter',
  unique: true,
  suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.diameter',
  suggestionMatch: getNumberMatch,
  format: (value) => `${formatNumber(value)}mm`,
  formatToSearch: (value) => value.toString(),
  valueToDTO: (value) => value,
  valueFromDTO: (value) => numberFromDTO(value),
  suggestionList: () =>
    SyntheticSearchableConfigurationFactory.getSyntheticSearchableUnits(
      CategoryEnum.diameter,
    ).map((value) => value.scalar),
  hashValue: (value) => value.toString(),
};

const machineries = SearchableConfigurationFactory.getSearchableConfigurations(
  CategoryEnum.machinery,
);
const machineriesMatcher = new FuzzyMatcher<SearchableConfiguration>(
  'supplierSearch.searchBar.suggestions.machinery',
  machineries,
);
const machineriesList = new SuggestionsList(
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.machinery,
  ),
);
const machineryFilterConfig: SearchFilterList<SearchableConfiguration, string> =
  {
    type: SearchFilterType.LIST,
    id: SearchValidFiltersEnum.MACHINERY,
    label: 'supplierSearch.searchBar.labels.machinery',
    suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.machinery',
    suggestionMatch: (value, $t, maxSuggestions) =>
      machineriesMatcher.getMatches(value, maxSuggestions),
    suggestionList: () => machineriesList.sortedList,
    format: (value) => value.label(),
    formatToSearch: (value) => value.label(),
    valueToDTO: (value) => value.id,
    valueFromDTO: (value) => getFromReferenceData(value, machineries),
    hashValue: (value) => value.label(),
  };

const partTypes = SearchableConfigurationFactory.getSearchableConfigurations(
  CategoryEnum.partType,
);
const partTypeMatcher = new FuzzyMatcher<SearchableConfiguration>(
  'supplierSearch.searchBar.suggestions.partType',
  partTypes,
);
const partTypeList = new SuggestionsList(
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.partType,
  ),
);
const partTypeFilterConfig: SearchFilterList<SearchableConfiguration, string> =
  {
    type: SearchFilterType.LIST,
    id: SearchValidFiltersEnum.PART_TYPE,
    label: 'supplierSearch.searchBar.labels.partType',
    suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.partType',
    suggestionMatch: (value, $t, maxSuggestions) =>
      partTypeMatcher.getMatches(value, maxSuggestions),
    suggestionList: () => partTypeList.sortedList,
    format: (value) => value.label(),
    formatToSearch: (value) => value.label(),
    valueToDTO: (value) => value.id,
    valueFromDTO: (value) => getFromReferenceData(value, partTypes),
    hashValue: (value) => value.label(),
  };

const partQuantityFilterConfig: SearchFilterSingle<number, number> = {
  type: SearchFilterType.SINGLE,
  id: SearchValidFiltersEnum.PART_QUANTITY,
  label: 'supplierSearch.searchBar.labels.partQuantity',
  unique: true,
  suggestionLabelMatch: 'supplierSearch.searchBar.suggestions.partQuantity',
  suggestionMatch: getNumberMatch,
  format: (value) => `${formatNumber(value)}`,
  formatToSearch: (value) => value.toString(),
  valueToDTO: (value) => value,
  valueFromDTO: (value) => numberFromDTO(value),
  suggestionList: () =>
    SyntheticSearchableConfigurationFactory.getSyntheticSearchableUnits(
      CategoryEnum.partQuantity,
    ).map((value) => value.scalar),
  hashValue: (value) => value.toString(),
};

export const allSearchableCategoriesWithTheirListOfSearchableConfigurations = {
  [SearchValidFiltersEnum.TECHNOLOGIES]: technologiesFilterconfig,
  [SearchValidFiltersEnum.COUNTRIES]: countryFilterConfig,
  [SearchValidFiltersEnum.LENGTH]: lengthFilterConfig,
  [SearchValidFiltersEnum.DIAMETER]: diameterFilterConfig,
  [SearchValidFiltersEnum.HEIGHT]: heightFilterConfig,
  [SearchValidFiltersEnum.WIDTH]: widthFilterConfig,
  [SearchValidFiltersEnum.REGIONS]: regionFilterConfig,
  [SearchValidFiltersEnum.CERTIFICATIONS]: certificationsFilterConfig,
  [SearchValidFiltersEnum.COMPANY_SIZE]: companySizeFilterconfig,
  [SearchValidFiltersEnum.MATERIALS]: materialsFilterconfig,
  [SearchValidFiltersEnum.MACHINERY]: machineryFilterConfig,
  [SearchValidFiltersEnum.PART_TYPE]: partTypeFilterConfig,
  [SearchValidFiltersEnum.PART_QUANTITY]: partQuantityFilterConfig,
};

export const allSearchableCategoryLists: SearchFilter[] = Object.values(
  allSearchableCategoriesWithTheirListOfSearchableConfigurations,
);

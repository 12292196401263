/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { ActivitySummaryDto } from '../models/ActivitySummaryDto';
import type { DashboardDto } from '../models/DashboardDto';
import type { TrackingEventRequest } from '../models/TrackingEventRequest';

export class AnalyticsService {
  /**
   * @returns DashboardDto
   * @throws ApiError
   */
  public static analyticsControllerUserAnalytics(): CancelablePromise<DashboardDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/analytics/user',
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static analyticsControllerTrackEvent(
    requestBody: TrackingEventRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/analytics/track-event',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param since
   * @returns ActivitySummaryDto
   * @throws ApiError
   */
  public static analyticsControllerGeneralAnalytics(
    since?: string,
  ): CancelablePromise<ActivitySummaryDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/analytics/general',
      query: {
        since: since,
      },
    });
  }
}

import { IntlShape } from 'react-intl';

class StaticIntl {
  private intl: IntlShape | null = null;

  initialize(intl: IntlShape): void {
    this.intl = intl;
  }

  get instance(): IntlShape {
    if (this.intl) {
      return this.intl;
    }
    throw Error('ReactIntl has not been initialized');
  }

  /**
   * @deprecated use native Intl for number formatting
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
   */
  get numberSeparators(): [string, string] {
    if (this.intl) {
      const [numericSeparator, decimal] = this.intl
        .$t({ id: 'decimal' })
        .split('');
      return [numericSeparator, decimal];
    }
    throw Error('ReactIntl has not been initialized');
  }
}

export default new StaticIntl();

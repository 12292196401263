// @see: https://www.intercom.com/help/en/articles/168-install-intercom-in-your-product-for-logged-in-users
/* eslint-disable */
// @ts-nocheck
export function initIntercom(): void {
  var APP_ID = import.meta.env.PUBLIC_INTERCOM;
  window.intercomSettings = {
    app_id: APP_ID,
    api_base: 'https://api-iam.eu.intercom.io',
  };
  /* prettier-ignore */
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

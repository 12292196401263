/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MachineSpecsDto } from './MachineSpecsDto';

export type MachineRequest = {
  /**
   * If provided will trigger an update operation
   */
  id: number | null;
  brand: string;
  model: string;
  quantity: number;
  status: MachineRequest.status;
  specs: MachineSpecsDto;
};

export namespace MachineRequest {
  export enum status {
    HIDDEN = 'hidden',
    VISIBLE = 'visible',
  }
}

import { useIntl } from 'react-intl';
import { useRouteError } from 'react-router-dom';

import Layout from './Layout';
import { Page, PageContent } from './page';

interface ErrorMessage {
  subtitle: string;
  description: string;
}

type ErrorMessageMap = Record<number, ErrorMessage>;

export interface ErrorPageProps {
  code?: number;
}

export default function ErrorPage({ code }: ErrorPageProps): JSX.Element {
  const { $t } = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError();
  const status = [404, 500].includes(error?.status || code)
    ? error?.status || code
    : 500;
  const messages: ErrorMessageMap = {
    404: {
      subtitle: $t({ id: 'General.errors.404.subtitle' }),
      description: $t({ id: 'General.errors.404.description' }),
    },
    500: {
      subtitle: $t({ id: 'General.errors.500.subtitle' }),
      description: $t({ id: 'General.errors.500.description' }),
    },
  };

  return (
    <Layout>
      <Page
        className="flex flex-col items-center justify-start gap-5 text-center"
        name={messages[status].subtitle}
        noIndex
      >
        <PageContent className="pt-14">
          <h1 className="text-[100px] font-bold leading-[unset] text-neutral-700 sm:text-[200px]">
            {status}
          </h1>
          <div className="text-3xl font-bold text-neutral-700 sm:text-4xl">
            {messages[status].subtitle}
          </div>
          <div className="text-base text-neutral-400 sm:text-lg">
            {messages[status].description}
          </div>
        </PageContent>
      </Page>
    </Layout>
  );
}

export class ResponseError {
  private readonly _message: string;
  private readonly _statusCode: number;
  private readonly _additionalMessage?: unknown;

  constructor({ status, statusText, body }: Response) {
    this._message = statusText;
    this._statusCode = status;
    this._additionalMessage = body;
  }

  get statusCode(): number {
    return this._statusCode;
  }

  get message(): string {
    return this._message;
  }

  get additionalMessage(): string | undefined {
    try {
      return JSON.stringify(this._additionalMessage);
    } catch {
      return undefined;
    }
  }

  toString(): string {
    const additional = this.additionalMessage
      ? `\n${this.additionalMessage}`
      : '';
    return `${this.statusCode} - ${this.message}${additional}`;
  }
}

import {
  certifications,
  companysizes,
  countries,
  machineries,
} from '@orderfoxag/reference-data';

import { CategoryEnum } from '@/modules/matchmaking/models/searchbar/enums/CategoryEnum';
import { SearchableConfigurationImpl } from '@/modules/matchmaking/models/searchbar/impl/SearchableConfigurationImpl';
import { SearchableConfiguration } from '@/modules/matchmaking/models/searchbar/SearchableConfiguration';
import partTypesData from '@/modules/matchmaking/types/searchables/partTypes.json';
import { regions } from '@/modules/matchmaking/types/searchables/regions-overrides';
import { SerializedSearchableConfiguration } from '@/modules/matchmaking/types/SerializedSearchableConfiguration';
import { SyntheticSearchableConfigurationFactory } from '@/modules/matchmaking/utils/factories/SyntheticSearchableConfigurationFactory';
import { ReferenceDataMapper } from '@/modules/matchmaking/utils/ReferenceDataMapper';
import { searchableMaterials as refDataSearchableMaterials } from '@/modules/matchmaking/utils/searchable-materials';
import { searchableTechnologies as refDataSearchableTechnologies } from '@/modules/matchmaking/utils/searchable-technologies';

export class SearchableConfigurationFactory {
  public static getAllPossibleSuggestions(): SearchableConfiguration[] {
    const result: SearchableConfiguration[] = [];
    for (const categoryEnumKey in CategoryEnum) {
      result.concat(
        SearchableConfigurationFactory.getSearchableConfigurations(
          categoryEnumKey as CategoryEnum,
        ),
      );
    }
    return result;
  }

  /**
   * There are multiple kind of searchable:
   * - **Synthetics** are generated at runtime.
   * - **Reference-data** are pulled from the reference-data package and then enriched with weights stored in this project.
   * - **local-data** are stored in json files inside this project including their weights.
   *
   * @param categoryEnumKey
   */
  static getSearchableConfigurations(
    categoryEnumKey: CategoryEnum,
  ): SearchableConfiguration[] {
    switch (categoryEnumKey) {
      case CategoryEnum.category:
        return SyntheticSearchableConfigurationFactory.getSyntheticCategories();
      case CategoryEnum.certifications:
        return ReferenceDataMapper.mapReferenceDataTypeToSearchableConfiguration(
          categoryEnumKey,
          certifications,
        );
      case CategoryEnum.materials:
        return ReferenceDataMapper.mapReferenceDataTypeToSearchableConfiguration(
          categoryEnumKey,
          refDataSearchableMaterials,
        );
      case CategoryEnum.technologies:
        return ReferenceDataMapper.mapReferenceDataTypeToSearchableConfiguration(
          categoryEnumKey,
          refDataSearchableTechnologies,
        );
      case CategoryEnum.countries:
        return ReferenceDataMapper.mapReferenceDataTypeToSearchableConfiguration(
          categoryEnumKey,
          countries,
        );
      case CategoryEnum.machinery:
        return ReferenceDataMapper.mapReferenceDataTypeToSearchableConfiguration(
          categoryEnumKey,
          machineries,
        );
      case CategoryEnum.regions:
        return ReferenceDataMapper.mapRegionTypeToSearchableConfiguration(
          categoryEnumKey,
          regions,
        );
      case CategoryEnum.companySize:
        return ReferenceDataMapper.mapCompanySizeTypeToSearchableRange(
          categoryEnumKey,
          companysizes,
        );
      case CategoryEnum.height:
      case CategoryEnum.length:
      case CategoryEnum.width:
      case CategoryEnum.diameter:
        return SyntheticSearchableConfigurationFactory.getSyntheticSearchableUnits(
          categoryEnumKey,
        );
      case CategoryEnum.partType:
        return SearchableConfigurationFactory.deserializeSearchableConfiguration(
          partTypesData,
        );
      default:
        return [];
    }
  }

  protected static deserializeSearchableConfiguration(
    serializedJsonData: SerializedSearchableConfiguration[],
  ): SearchableConfiguration[] {
    return serializedJsonData.map(
      (data) =>
        new SearchableConfigurationImpl(
          data.category as CategoryEnum,
          data.id,
          [],
          data.searchWeight,
          [],
          data.translationKey,
          data.searchWeight,
          '',
        ),
    );
  }
}

export const searchableTechnologies: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.technologies,
  );

export const searchableMaterials: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.materials,
  );

export const searchableCountries: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.countries,
  );

export const searchableCertificates: SearchableConfiguration[] =
  SearchableConfigurationFactory.getSearchableConfigurations(
    CategoryEnum.certifications,
  );

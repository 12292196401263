import { RegionType } from '@orderfoxag/reference-data';
import { IntlShape } from 'react-intl';
import { ItemDataType as ItemDataTypeOld } from 'rsuite/esm/@types/common';

import { ItemDataType } from '@/core/components/tree-combobox/tree-combobox';
import { formatNumber } from '@/core/libs/formatters';
import { getHighestValue } from '@/core/libs/number-utils';
import { allSearchableCategoriesWithTheirListOfSearchableConfigurations } from '@/modules/matchmaking/components/SearchFilter.config';
import { SearchValidFiltersEnum } from '@/modules/matchmaking/models/searchbar/enums/SearchValidFiltersEnum';
import { SearchQuery } from '@/modules/matchmaking/models/searchbar/SearchQuery';
import {
  AnalyzedPart,
  ManufacturerFlags,
  MatchedManufacturer,
  PartImageObject,
  SearchFilter,
} from '@/modules/matchmaking/types';
import { regions } from '@/modules/matchmaking/types/searchables/regions-overrides';
import { Material } from '@/generated/types';

const sortlowestDeltaFirst = ([x, y, z]: number[]): number[] =>
  Math.abs(x - y) < Math.abs(z - y) ? [x, y, z] : [z, y, x];

export const sortDimensions = (
  dimensions: number[],
  isTurning: boolean,
  reverse = false,
): [number, number, number] | [] => {
  if (!dimensions.length) return [];
  let sorted = [...dimensions].sort((a, b) => (a > b ? 1 : -1)); // length/longest at the end
  if (isTurning) sorted = sortlowestDeltaFirst(sorted); // turning parts: sort by delta
  if (reverse) sorted = sorted.reverse(); // longest/length at the beginning (cases like create RFQ)
  return [sorted[0], sorted[1], sorted[2]];
};

export const renderDimensions = (
  $t: IntlShape['$t'],
  dimensions: number[],
  isTurning = false,
): string =>
  sortDimensions(dimensions, isTurning)
    .map((axis) => `${formatNumber(axis)}${$t({ id: 'General.mm' })}`)
    .join(' / ');

export const isTurningPart = (techs: string[]): boolean =>
  techs.indexOf('t1') > -1;

export function sortDropDownDataTypeByName(
  a: ItemDataType<string | string[]>,
  b: ItemDataType<string | string[]>,
): number {
  if (a.label && b.label && a.label < b.label) {
    return -1;
  }
  if (a.label && b.label && a.label < b.label) {
    return 1;
  }
  return 0;
}

// TODO: Remove on old rfq creation cleanup
export function sortDropDownDataTypeByNameOld(
  a: ItemDataTypeOld,
  b: ItemDataTypeOld,
): number {
  if (a.label && b.label && a.label < b.label) {
    return -1;
  }
  if (a.label && b.label && a.label < b.label) {
    return 1;
  }
  return 0;
}

export function isSearchValidFilter(
  key: string,
): key is SearchValidFiltersEnum {
  return Object.values(SearchValidFiltersEnum).some((value) => value === key);
}

export function deHyphenateUrlSegment(segment: string): string {
  return segment.replace(/-[a-z]/g, (match) =>
    match.replace('-', '').toUpperCase(),
  );
}

export function hyphenateUrlSegment(segment: string): string {
  return segment.replace(/([A-Z])/g, '-$1').toLowerCase();
}

export function urlToSearchQuery(url?: string): SearchQuery {
  const query: SearchQuery = {};

  const split = url?.split('/') || [];
  let selectedFilter: SearchFilter | null = null;
  split.map(decodeURI).forEach((segment) => {
    segment = deHyphenateUrlSegment(segment);

    if (segment == SearchValidFiltersEnum.IS_VERIFIED) {
      const value = Boolean(segment.toString());
      query.isVerified = value ? true : undefined;
    } else if (segment == SearchValidFiltersEnum.HAS_CAPACITY) {
      const value = Boolean(segment.toString());
      query.hasCapacity = value ? true : undefined;
    }
    if (
      isSearchValidFilter(segment) &&
      segment != SearchValidFiltersEnum.IS_VERIFIED &&
      segment != SearchValidFiltersEnum.HAS_CAPACITY
    ) {
      selectedFilter =
        allSearchableCategoriesWithTheirListOfSearchableConfigurations[segment];
    } else if (selectedFilter) {
      const value = selectedFilter.valueFromDTO(segment);
      if (value !== null) {
        if (selectedFilter.unique) {
          query[selectedFilter.id] =
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            typeof value === 'number' ? (value as any) : [segment];
        } else if (!selectedFilter.unique) {
          const curentVal = query[selectedFilter.id];
          const spreadVal = Array.isArray(curentVal) ? curentVal : [];
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          query[selectedFilter.id] = [...spreadVal, segment] as any;
        }
      }
    }
  });

  return query;
}

export function searchQueryToUrl(search: SearchQuery | null): string {
  if (!search) return '';

  const urlSegments: string[] = [];

  Object.entries(search)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .forEach(([key, value]) => {
      if (isSearchValidFilter(key)) {
        key = hyphenateUrlSegment(key);
        const values: (string | number | boolean)[] = Array.isArray(value)
          ? value
          : [value];
        const filteredValues = values.filter(Boolean);
        if (filteredValues.length) {
          urlSegments.push(key);
          filteredValues
            .sort()
            .forEach((item) => urlSegments.push(item.toString()));
        }
      }
    });

  const appendix = urlSegments.length > 0 ? '/' : '';

  return `${urlSegments.map(encodeURI).join('/')}${appendix}`;
}

export function partsToQuery(parts: AnalyzedPart[]): SearchQuery {
  const query: SearchQuery = {};

  parts.forEach((part) => {
    const technologies = part.technologies.map(({ id }) => id);
    const materials = part.materials.map(({ id }) => id);
    const [width, height, length] = part.dimensionsMm?.length
      ? sortDimensions(part.dimensionsMm, isTurningPart(technologies))
      : [];

    query.technologies = [
      ...new Set([...(query.technologies || []), ...technologies]),
    ].filter((value) =>
      allSearchableCategoriesWithTheirListOfSearchableConfigurations.technologies.valueFromDTO(
        value,
      ),
    );
    query.materials = [
      ...new Set([...(query.materials || []), ...materials]),
    ].filter((value) =>
      allSearchableCategoriesWithTheirListOfSearchableConfigurations.materials.valueFromDTO(
        value,
      ),
    );

    query.length = getHighestValue(query.length, length);
    query.width = getHighestValue(query.width, height);
    query.height = getHighestValue(query.height, width);
  });

  return query;
}

export function getCountriesFromRegions(
  regionIDs: string[] | undefined,
): string[] {
  if (!regionIDs) return [];
  const res: RegionType[] = [];
  regionIDs?.forEach((regID) => {
    const found = regions.find((region) => region.id === regID);
    if (found) res.push(found);
  });

  const test = res.map((region) => region.countries);

  return ([] as string[]).concat(...test);
}

export function buildNonSearchbarFilterQuery(
  query: SearchQuery,
): Pick<
  SearchQuery,
  SearchValidFiltersEnum.HAS_CAPACITY | SearchValidFiltersEnum.IS_VERIFIED
> {
  return {
    [SearchValidFiltersEnum.HAS_CAPACITY]: query.hasCapacity,
    [SearchValidFiltersEnum.IS_VERIFIED]: query.isVerified,
  };
}

export function supplierHasSomeFlags(
  supplier: MatchedManufacturer,
  flags: ManufacturerFlags[],
): boolean {
  return flags.some((f) => {
    return supplier.flags.includes(f);
  });
}

export function renderMaterial(
  { id, designation }: Material,
  $t: IntlShape['$t'],
): string {
  const appendix = designation ? ` (${designation})` : '';
  return `${$t({
    id: `referenceData.materials.${id}`,
  })}${appendix}`;
}

/**
 * Generates alt text for search result media based on a 'partImageObject'.
 * The alt text is used for SEO purposes.
 * Example: "Large plastic milling gear"
 * @param partImageObject
 */
export function buildAltTextForSearchResultMedia(
  partImageObject?: PartImageObject,
): string {
  if (!partImageObject) return `CNC part`;

  let size, material, shape, technology;

  // Size
  if (partImageObject.is_size_s) {
    size = 'small';
  } else if (partImageObject.is_size_m) {
    size = 'medium';
  } else if (partImageObject.is_size_l) {
    size = 'large';
  } else {
    size = undefined;
  }

  // Material
  if (partImageObject.is_metal) {
    material = 'metal';
  } else if (partImageObject.is_aluminium) {
    material = 'aluminium';
  } else if (partImageObject.is_brass) {
    material = 'brass';
  } else if (partImageObject.is_plastic) {
    material = 'plastic';
  } else {
    material = undefined;
  }

  // Technology
  if (partImageObject.is_milling) {
    technology = 'milling';
  } else if (partImageObject.is_turning) {
    technology = 'turning';
  } else if (partImageObject.is_cutting) {
    technology = 'cutting';
  } else if (partImageObject.is_joining) {
    technology = 'joining';
  } else if (partImageObject.is_stamping) {
    technology = 'stamping';
  } else if (partImageObject.is_casting_moulding) {
    technology = 'casting';
  } else if (partImageObject.is_forming) {
    technology = 'forming';
  } else {
    technology = 'CNC';
  }

  // Shape
  if (partImageObject.is_gear) {
    shape = 'gear';
  } else if (partImageObject.is_shaft) {
    shape = 'shaft';
  } else if (partImageObject.is_screw) {
    shape = 'screw';
  } else if (partImageObject.is_tube) {
    shape = 'tube';
  } else {
    shape = 'part';
  }

  return [size, material, technology, shape]
    .join(' ')
    .replace(/\s{2,}/g, ' ') // removes double white-spaces
    .toLocaleLowerCase();
}

export function shortenSearchQueryCountriesIntoRegions(
  search: SearchQuery,
): SearchQuery {
  const searchQueryCountries = search[SearchValidFiltersEnum.COUNTRIES]?.filter(
    (c) => !c.includes(','),
  );
  const countriesInRegions: string[] = [];
  const extractedRegions: string[] = [];

  regions.forEach((region) => {
    const countriesInRegion = region.countries;
    if (
      countriesInRegion &&
      countriesInRegion.every(
        (country) => searchQueryCountries?.includes(country),
      )
    ) {
      countriesInRegions.push(...countriesInRegion);
      extractedRegions.push(region.id);
    }
  });

  return {
    ...search,
    countries:
      search[SearchValidFiltersEnum.COUNTRIES] &&
      search[SearchValidFiltersEnum.COUNTRIES].filter(
        (c) => !countriesInRegions.includes(c),
      ),
    regions: extractedRegions,
  };
}

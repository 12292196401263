import { createClient, SupabaseClient } from '@supabase/supabase-js';

const client = createClient(
  import.meta.env.PUBLIC_SUPABASE_URL,
  import.meta.env.PUBLIC_SUPABASE_KEY,
);

export default function useSupabase(): SupabaseClient {
  return client;
}

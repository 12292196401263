import { useCallback, useMemo } from 'react';
import { Dict } from 'mixpanel-browser';
import { v4 } from 'uuid';

import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import eventTracker from '@/core/libs/event-tracker/event-tracker';
import TrackerEvents from '@/core/libs/event-tracker/TrackerEvents.enum';

export interface UseUserTracking {
  userId: string;
  trackUserEvent: (eventName: TrackerEvents, eventProps?: Dict) => void;
  TrackerEvents: typeof TrackerEvents;
}

export function useUserTracking(): UseUserTracking {
  const sessionStorageKey = 'UTID';
  const { currentUser } = useAuthenticationContext();

  const userId = useMemo<string>(() => {
    const id =
      currentUser?.id?.toString() || sessionStorage.getItem(sessionStorageKey);
    if (!id) {
      const newId = v4();
      sessionStorage.setItem(sessionStorageKey, newId);
      return newId;
    }
    return id;
  }, [currentUser]);

  const userData = useMemo(() => {
    if (!currentUser) return {};
    return {
      userId: currentUser.id,
      companyId: currentUser.company.id,
      companyName: currentUser.company.name,
      userEmail: currentUser.email,
      type: currentUser.type,
      membership: currentUser.company.membershipPlan,
      country: currentUser.company.address?.country,
      website: currentUser.company.website,
      language: currentUser.locale,
      vat: currentUser.company.vat,
      status: currentUser.company.onboardingStatus?.map((os) => os.status),
    };
  }, [currentUser]);

  const trackUserEvent = useCallback(
    (eventName: TrackerEvents, eventProps?: Dict) => {
      eventTracker.track(eventName, {
        userId,
        host: location.host,
        ...userData,
        ...eventProps,
      });
    },
    [userData, userId],
  );

  return { userId, trackUserEvent, TrackerEvents: TrackerEvents };
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AddTeammateRequest = {
  firstName: string;
  lastName: string;
  email: string;
  type: AddTeammateRequest.type;
};

export namespace AddTeammateRequest {
  export enum type {
    MANUFACTURER = 'manufacturer',
    BUYER = 'buyer',
    BOTH = 'both',
  }
}

import {
  regions as regionsRefData,
  RegionType,
} from '@orderfoxag/reference-data';

const regions: RegionType[] = [
  ...regionsRefData,
  {
    name: 'Asia',
    id: 'asia',
    translationKey: 'referenceData.continents.CAS',
    countries: [
      'AE',
      'AF',
      'AM',
      'AZ',
      'BD',
      'BH',
      'BN',
      'BT',
      'CC',
      'CN',
      'CX',
      'GE',
      'HK',
      'ID',
      'IL',
      'IN',
      'IQ',
      'IR',
      'JO',
      'JP',
      'KG',
      'KH',
      'KP',
      'KR',
      'KW',
      'KZ',
      'LA',
      'LB',
      'LK',
      'MM',
      'MN',
      'MO',
      'MV',
      'MY',
      'NP',
      'OM',
      'PH',
      'PK',
      'PS',
      'QA',
      'SA',
      'SG',
      'SY',
      'TH',
      'TJ',
      'TM',
      'TR',
      'TW',
      'UZ',
      'VN',
      'YE',
    ],
  },
];

export { regions };

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { errorHandler } from '@/core/libs/error-handler';
import {
  InvitationsService,
  Project,
  ProjectInvitation,
} from '@/generated/api';

interface RFQDetailsData {
  project: Project;
  invitation: ProjectInvitation;
}

export function useChangeRFQStatusForLoggedInUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      status,
    }: {
      id: number;
      status: ProjectInvitation.status;
    }) => InvitationsService.invitationsControllerUpdateStatus(id, status),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['getRFQDetails'],
      });
    },
    onError: (error) => {
      errorHandler.capture(error);
    },
  });
}

export function useChangeRFQStatusForLoggedInUserToViewed() {
  return useMutation({
    mutationFn: (id: number) =>
      InvitationsService.invitationsControllerUpdateStatus(
        id,
        ProjectInvitation.status.VIEWED,
      ),
  });
}

export function useChangeRFQStatusByTokenToViewed() {
  return useMutation({
    mutationFn: (token: string) =>
      InvitationsService.invitationsControllerMarkInvitationViewedByToken(
        token,
      ),
  });
}

export function useBookmarkRFQ() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, isBookmarked }: { id: number; isBookmarked: boolean }) =>
      InvitationsService.invitationsControllerToggleBookmark(id, isBookmarked),
    onMutate: async ({ isBookmarked }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['getRFQDetails'] });

      // Snapshot the previous value
      const previousRFQDetails = queryClient.getQueryData<RFQDetailsData>([
        'getRFQDetails',
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData<RFQDetailsData>(['getRFQDetails'], (old) => {
        if (!old) return old;
        return {
          ...old,
          invitation: {
            ...old.invitation,
            isBookmarked,
          },
        };
      });

      // Return a context object with the snapshotted value
      return { previousRFQDetails };
    },
    onError: (err, newRFQ, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData<RFQDetailsData>(
        ['getRFQDetails'],
        context?.previousRFQDetails,
      );
    },
    onSettled: async () => {
      // Always refetch after error or success to ensure data is up to date
      await queryClient.invalidateQueries({ queryKey: ['getRFQDetails'] });
    },
  });
}

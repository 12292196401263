import { useIntl } from 'react-intl';

import { Loader } from '@/core/components/component-library/Icon/loader';
import {
  Page,
  PageContent,
  PageContentProps,
} from '@/core/components/layout/page';

export function PageLoader(): JSX.Element {
  const { $t } = useIntl();

  return (
    <Page name={$t({ id: 'General.loading' })} noIndex>
      <PageContent variant="centered">
        <Loader size="lg" showText />
      </PageContent>
    </Page>
  );
}

type PageContentLoaderProps = Omit<PageContentProps, 'children'>;
export function PageContentLoader({
  ...props
}: PageContentLoaderProps): JSX.Element {
  return (
    <PageContent {...props} variant="centered">
      <Loader size="lg" showText />
    </PageContent>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { errorHandler } from '@/core/libs/error-handler';
import { allSearchableCategoriesWithTheirListOfSearchableConfigurations } from '@/modules/matchmaking/components/SearchFilter.config';
import { FileAnalyzerUploaderResponse } from '@/modules/matchmaking/hooks/use-file-analyzer-uploader';
import { SearchValidFiltersEnum } from '@/modules/matchmaking/models/searchbar/enums/SearchValidFiltersEnum';
import { SearchQuery } from '@/modules/matchmaking/models/searchbar/SearchQuery';
import {
  SearchFilter,
  SuggestionType,
  ValuedSuggestion,
} from '@/modules/matchmaking/types';
import {
  isSearchValidFilter,
  urlToSearchQuery,
} from '@/modules/matchmaking/utils';

export interface UseUrlSearchQuery {
  query: SearchQuery;
  urlQuery?: string;
  urlFiles?: FileAnalyzerUploaderResponse[];
  filesQueryKey: string;
  suggestions: ValuedSuggestion[];
}

export function useUrlSearchQuery(): UseUrlSearchQuery {
  const filesQueryKey = 'f';
  const { '*': splat } = useParams();
  const [searchParams] = useSearchParams();
  const { $t } = useIntl();
  const [query, setQuery] = useState<SearchQuery>(urlToSearchQuery(splat));
  const [urlFiles, setUrlFiles] = useState<
    FileAnalyzerUploaderResponse[] | undefined
  >();

  const queryToSuggestions = useCallback(
    (
      urlQuery: SearchQuery,
      translation: IntlFormatters['$t'],
    ): ValuedSuggestion[] => {
      return Object.entries(urlQuery).reduce<ValuedSuggestion[]>(
        (list, [key, dto]) => {
          if (
            isSearchValidFilter(key) &&
            key != SearchValidFiltersEnum.HAS_CAPACITY &&
            key != SearchValidFiltersEnum.IS_VERIFIED
          ) {
            const filter: SearchFilter =
              allSearchableCategoriesWithTheirListOfSearchableConfigurations[
                key
              ];
            const value = filter.valueFromDTO(dto);
            const values = Array.isArray(value) ? value : [value];
            values.forEach((item) =>
              list.push({
                id: v4(),
                filter: filter,
                type: SuggestionType.VALUE,
                label: translation({ id: filter.label }),
                value: item,
                suggestionText: '',
                selected: true,
              }),
            );
          }
          return list;
        },
        [],
      );
    },
    [],
  );

  const [suggestionList, setSuggestionList] = useState<ValuedSuggestion[]>(
    queryToSuggestions(urlToSearchQuery(splat), $t),
  );

  useEffect(() => {
    const urlQuery = urlToSearchQuery(splat);
    setQuery(urlQuery);
    setSuggestionList(queryToSuggestions(urlQuery, $t));
  }, [splat, queryToSuggestions, $t]);

  useEffect(() => {
    const encodedString = searchParams.get(filesQueryKey);
    if (encodedString) {
      try {
        setUrlFiles(JSON.parse(atob(encodedString)));
      } catch (error) {
        errorHandler.capture(error, { avoidFlashMessage: true });
      }
    } else {
      setUrlFiles(undefined);
    }
  }, [searchParams, splat]);

  return {
    query,
    urlQuery: splat,
    urlFiles,
    suggestions: suggestionList,
    filesQueryKey,
  };
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';

import { ResetPasswordModal } from '@/core/components/modals/reset-password-modal';
import { SignInModal } from '@/core/components/modals/sign-in-modal';
import useGetCompanyStatus from '@/core/hooks/useGetCompanyStatus';
import useGetUserData from '@/core/hooks/useGetUserData';
import useIsEmailInUse from '@/core/hooks/useIsEmailInUse';
import useLogin from '@/core/hooks/useLogin';
import useResetPasswordForEmail from '@/core/hooks/useResetPasswordForEmail';
import useSupabase from '@/core/hooks/useSupabase';
import { errorHandler } from '@/core/libs/error-handler';
import {
  Company,
  CompanyStatusDto,
  LoginResponseDto,
  User,
} from '@/generated/api';

import { LanguageCode, useLocaleContext } from './I18nContext';

interface AuthenticationProviderProps {
  children: React.ReactNode;
}

enum Modals {
  Login,
  ResetPassword,
}

export type OnUserEventCallback = (user: User) => void;

export interface OpenAuthorizationModalOptions {
  onLogin?: OnUserEventCallback;
  uncloseable?: boolean;
  closeableBackdrop?: boolean;
}

export interface AuthenticationContext {
  isLoggedIn: boolean;
  isLoading: boolean;
  currentUser: User | null;
  companyStatus: CompanyStatusDto | null;
  getUserData(): Promise<User | undefined>;
  getCompanyStatus(): Promise<CompanyStatusDto | undefined>;
  openLoginModal(options?: OpenAuthorizationModalOptions): void;
  openForgotPasswordModal(): void;
  logout(): void;
  login(email: string, password: string): Promise<LoginResponseDto | void>;
  isEmailTaken(email: string): Promise<boolean>;
  resetPasswordForEmail(email: string): Promise<boolean>;
}

export const AUTH_COOKIE_KEY = 'buyerJWT';

export const AuthenticationContext = createContext<AuthenticationContext>({
  isLoggedIn: false,
  isLoading: false,
  currentUser: null,
  companyStatus: null,
  getUserData() {
    errorHandler.capture(
      'Wrong usage of AuthenticationContext.getUserData without the provider.',
      {
        avoidFlashMessage: true,
      },
    );
    return Promise.reject();
  },
  getCompanyStatus() {
    errorHandler.capture(
      'Wrong usage of AuthenticationContext.getCompanyStatus without the provider.',
      {
        avoidFlashMessage: true,
      },
    );
    return Promise.reject();
  },
  openLoginModal() {
    errorHandler.capture(
      'Wrong usage of AuthenticationContext.openLoginModal without the provider.',
      {
        avoidFlashMessage: true,
      },
    );
  },
  openForgotPasswordModal() {
    errorHandler.capture(
      'Wrong usage of AuthenticationContext.openForgotPasswordModal without the provider.',
      {
        avoidFlashMessage: true,
      },
    );
  },
  logout() {
    errorHandler.capture(
      'Wrong usage of AuthenticationContext.logout without the provider.',
      {
        avoidFlashMessage: true,
      },
    );
  },
  login(email: string, password: string) {
    errorHandler.capture(
      `Wrong usage of AuthenticationContext.login without the provider. With params: Email:${email} ; Password: ${password}`,
      { avoidFlashMessage: true },
    );
    return Promise.reject();
  },
  isEmailTaken(email: string) {
    errorHandler.capture(
      `Wrong usage of AuthenticationContext.isEmailTaken without the provider. With params: Email: ${email}`,
      { avoidFlashMessage: true },
    );
    return Promise.reject();
  },
  resetPasswordForEmail(email: string): Promise<boolean> {
    errorHandler.capture(
      `Wrong usage of AuthenticationContext.resetPasswordForEmail without the provider. With params: Emai: ${email}`,
      { avoidFlashMessage: true },
    );
    return Promise.reject();
  },
});

export function AuthenticationProvider({
  children,
}: AuthenticationProviderProps): JSX.Element {
  const { locale } = useIntl();
  const { setUserLocale } = useLocaleContext();
  const supabase = useSupabase();
  const isEmailTaken = useIsEmailInUse();
  const fetchUserData = useGetUserData();
  const fetchCompanyStatus = useGetCompanyStatus();
  const login = useLogin();
  const resetPasswordForEmail = useResetPasswordForEmail();
  const [uncloseableLogin, setUncloseableLogin] = useState<boolean>(false);
  const [closeableBackdropLogin, setCloseableBackdropLogin] =
    useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<Modals | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [companyStatus, setCompanyStatus] = useState<CompanyStatusDto | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [authCookie, setAuthCookie] = useState<string>(
    Cookies.get(AUTH_COOKIE_KEY) || '',
  );
  const [onLoginCallback, setOnLoginCallback] = useState<
    OnUserEventCallback | undefined
  >();

  const logout = useCallback(async (): Promise<void> => {
    localStorage.removeItem('impersonatedUser');
    Cookies.set(AUTH_COOKIE_KEY, '', { expires: 0 });
    await supabase.auth.signOut();
    setIsLoggedIn(false);
    setCurrentUser(null);
    Sentry.setUser(null);
    !!window.Intercom && window.Intercom('shutdown');
    !!window.Intercom && window.Intercom('boot');
    if (location.pathname !== '/reset-password') location.href = '/';
  }, [supabase.auth]);

  const handleAuthCookieChange = (
    authToken: string,
    expiresInSeconds = 86400,
  ): void => {
    Cookies.set(AUTH_COOKIE_KEY, authToken, { expires: expiresInSeconds });
    setAuthCookie(authToken);
  };

  const resetPasswordForEmailCallback = useCallback(
    async (email: string) => {
      try {
        setIsLoading(true);
        return await resetPasswordForEmail(email);
      } finally {
        setIsLoading(false);
      }
    },
    [resetPasswordForEmail],
  );

  const getCompanyStatus = useCallback(async (): Promise<
    CompanyStatusDto | undefined
  > => {
    setIsLoading(true);
    try {
      const companyStatusData = await fetchCompanyStatus();
      if (companyStatusData) setCompanyStatus(companyStatusData);
      return companyStatusData;
    } catch {
      setCompanyStatus(null);
    } finally {
      setIsLoading(false);
    }
  }, [fetchCompanyStatus]);

  const redirectFirstLogin = async (
    userType: LoginResponseDto.userType,
    membershipPlan: LoginResponseDto.membershipPlan | null,
  ): Promise<void> => {
    let destination: string = '/';

    if (userType === LoginResponseDto.userType.BUYER) {
      destination = '/';
    } else if (!membershipPlan) {
      const storedMembershipPlan = localStorage.getItem(
        'membership_plan',
      ) as Company.membershipPlan;
      const storedBillingCycle = localStorage.getItem(
        'billing_cycle',
      ) as string;
      destination =
        storedMembershipPlan && storedBillingCycle
          ? `/checkout?membership_plan=${storedMembershipPlan}&billing_cycle=${storedBillingCycle}`
          : '/pricing';
    }

    if (window.location.pathname !== destination)
      window.location.href = destination;
  };

  const getUserData = useCallback(async (): Promise<User | undefined> => {
    setIsLoading(true);
    try {
      const userData = await fetchUserData(authCookie);

      if (typeof userData === 'string') throw new Error();
      setCurrentUser(userData);

      if (locale !== userData.locale) {
        setUserLocale(userData.locale as LanguageCode);
      }

      setIsLoggedIn(true);

      return userData;
    } catch {
      setIsLoggedIn(false);
    } finally {
      setIsLoading(false);
    }
  }, [authCookie, fetchUserData, locale, setUserLocale]);

  const loginCallback = useCallback(
    async (
      email: string,
      password: string,
    ): Promise<LoginResponseDto | void> => {
      setIsLoading(true);
      try {
        const res = await login(email, password);
        handleAuthCookieChange(res.access_token, res.expires_in / 60 / 60 / 24);
        await supabase.auth.setSession({
          access_token: res.access_token,
          refresh_token: res.refresh_token,
        });
        Sentry.setUser({ email });
        !!window.Intercom && window.Intercom('boot', { email });

        if (res.isFirstLogin)
          redirectFirstLogin(res.userType, res.membershipPlan);

        // We do not retrieve the user data because it will be automatically triggered indirectly
        // when the auth cookie change is completed by the useEffect, we leave the loading until that happens
        return res;
      } catch (error) {
        errorHandler.capture(error, { avoidFlashMessage: true });
        setIsLoading(false);
        throw Error();
      } finally {
        // getUserData is triggered automatically once the authCookie is set and it will handle the isLoading automatically
        // do not set it to false here or the login callback will fail and the UserAuthenticatedContext will trigger the modal again
        // when it should not because of the useEffect
      }
    },
    [login, supabase.auth],
  );

  const openLoginModal = useCallback(
    ({
      onLogin,
      uncloseable,
      closeableBackdrop = false,
    }: OpenAuthorizationModalOptions = {}): void => {
      if (!isLoggedIn) {
        setUncloseableLogin(uncloseable ?? false);
        setCloseableBackdropLogin(closeableBackdrop);
        setOnLoginCallback(() => onLogin);
        setCurrentModal(Modals.Login);
      } else if (currentUser) {
        onLogin?.(currentUser);
      }
    },
    [currentUser, isLoggedIn],
  );

  const openForgotPasswordModal = (): void =>
    setCurrentModal(Modals.ResetPassword);

  const closeModal = useCallback((): void => {
    setCurrentModal(null);
  }, []);

  useEffect(() => {
    getUserData().then((user) => {
      if (!user) return;
      Sentry.setUser({
        email: user.email,
        membership: user.company.membershipPlan,
      });
      window.heap?.identify(user.id);
      window.heap?.addUserProperties({
        plan: user.company.membershipPlan,
        email: user.email,
        company: user.company.name,
      });

      if (window.Intercom)
        window.Intercom('boot', { email: user.email, user_id: user.id });
    });
  }, [getUserData]);

  useEffect(() => {
    if (currentUser) {
      onLoginCallback?.(currentUser);
      setOnLoginCallback(undefined);
    }
  }, [currentUser, onLoginCallback]);

  return (
    <AuthenticationContext.Provider
      value={{
        isLoggedIn,
        isLoading,
        currentUser,
        companyStatus,
        getUserData,
        getCompanyStatus,
        openLoginModal,
        openForgotPasswordModal,
        login: loginCallback,
        logout,
        isEmailTaken,
        resetPasswordForEmail: resetPasswordForEmailCallback,
      }}
    >
      <>
        {children}
        <SignInModal
          isOpen={currentModal === Modals.Login}
          onClose={closeModal}
          onForgotPasswordClick={(): void =>
            setCurrentModal(Modals.ResetPassword)
          }
          uncloseable={uncloseableLogin}
          closeableBackdrop={closeableBackdropLogin}
        />
        <ResetPasswordModal
          isOpen={currentModal === Modals.ResetPassword}
          onClose={closeModal}
        />
      </>
    </AuthenticationContext.Provider>
  );
}

export function useAuthenticationContext(): AuthenticationContext {
  return useContext(AuthenticationContext);
}

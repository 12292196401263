import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { PageLoader } from '@/core/components/layout/page-loader';
import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { User } from '@/generated/api';

type AuthorizationGuardProps = {
  disallowedUserTypes?: User['type'][];
  disallowedUserRoles?: User['role'][];
};

const DEFAULT_DISALLOWED_USER_TYPES: User['type'][] = [];
const DEFAULT_DISALLOWED_USER_ROLES: User['role'][] = [];

export function AuthorizationGuard({
  disallowedUserTypes = DEFAULT_DISALLOWED_USER_TYPES,
  disallowedUserRoles = DEFAULT_DISALLOWED_USER_ROLES,
}: AuthorizationGuardProps) {
  const location = useLocation();
  const { isLoading, isLoggedIn, currentUser } = useAuthenticationContext();

  if (isLoading && !isLoggedIn) {
    return <PageLoader />;
  }

  if (!currentUser) {
    return <Navigate to="/search" replace />;
  }

  const { role, type } = currentUser;

  if (
    disallowedUserRoles.includes(role) ||
    disallowedUserTypes.includes(type)
  ) {
    return <Navigate to="/404" replace state={{ from: location }} />;
  }

  return <Outlet />;
}

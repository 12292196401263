import { Link } from 'react-router-dom';

import { cn } from '@/core/libs/utils';

export function TopNavItem({
  as = Link,
  to,
  active,
  children,
  onClick,
}: {
  as?: React.ElementType;
  to?: string;
  active?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}): JSX.Element {
  const Component = as;
  return (
    <Component
      to={to}
      onClick={onClick}
      className={cn(
        'bg-transparent flex h-14 w-full items-center justify-start gap-4 rounded-xl px-4 py-4 text-base font-normal no-underline transition-colors hover:bg-neutral-100 hover:text-neutral-600 hover:no-underline focus-visible:text-neutral-600 focus-visible:no-underline',
        active ? 'text-primary-700' : 'text-neutral-600',
      )}
    >
      {children}
    </Component>
  );
}

import { useState } from 'react';
import Cookies from 'js-cookie';

import { AUTH_COOKIE_KEY } from '@/core/context/AuthenticationContext';
import { errorHandler } from '@/core/libs/error-handler';
import {
  CancelablePromise,
  CompaniesService,
  Company,
  FilesService,
  OpenAPI,
  S3UploadCredentialsDto,
  StripeService,
  UpdatePublicProfileRequest,
  UpdateUserCompanyRequest,
  UpdateUserRequest,
  User,
  UsersService,
} from '@/generated/api';

OpenAPI.TOKEN = Cookies.get(AUTH_COOKIE_KEY);

export function updateUser(
  userId: number,
  data: UpdateUserRequest,
): CancelablePromise<User> {
  return UsersService.userControllerUpdateUser(userId, data);
}

export function updateUserCompany(
  data: UpdateUserCompanyRequest,
): CancelablePromise<string> {
  return CompaniesService.companyControllerUpdateCompany(data);
}

export function getStripeCustommerPortalUrl(): CancelablePromise<string> {
  return StripeService.stripeControllerGetBillingPortalUrl(
    window.location.origin,
  );
}

export function updatePublicDetails(
  data: Pick<UpdatePublicProfileRequest, 'publicEmail' | 'publicPhone'>,
): CancelablePromise<Company> {
  return CompaniesService.companyControllerUpdateSettings({
    publicEmail: data.publicEmail,
    publicPhone: data.publicPhone,
  });
}

export function updateCapacity(
  newCapacity: string | null,
): CancelablePromise<Company> {
  return CompaniesService.companyControllerUpdateSettings({
    hasNoCapacityUntil: newCapacity,
  });
}

export function updateFactoryTourVideo(
  newLink: string | null,
): CancelablePromise<Company> {
  return CompaniesService.companyControllerUpdateSettings({
    promotionalVideo: newLink,
  });
}

export async function uploadFile(file: File): Promise<S3UploadCredentialsDto> {
  const uploadCredentials =
    await FilesService.filesControllerGenerateTempS3UploadCredentials();

  const formData = uploadCredentials.fields.reduce<FormData>(
    (form: FormData, { key, value }) => {
      form.append(key, value);
      return form;
    },
    new FormData(),
  );

  formData.append('Content-Type', file.type);
  formData.append('file', file);

  await window.fetch(uploadCredentials.uploadUrl, {
    method: 'POST',
    body: formData,
    credentials: 'omit',
  });

  return uploadCredentials;
}

export async function uploadCompanyLogo(file: File): Promise<void> {
  const uploadCredentials = await uploadFile(file);

  await CompaniesService.companyControllerUpdateSettings({
    logo: {
      name: file.name,
      url: `${uploadCredentials.publicUrl}/${file.name}`,
    },
  });
}

export async function uploadCompanyPdf(file: File): Promise<void> {
  const uploadCredentials = await uploadFile(file);

  await CompaniesService.companyControllerUpdateSettings({
    promotionalPdf: {
      name: file.name,
      url: `${uploadCredentials.publicUrl}/${file.name}`,
    },
  });
}

export function useUpdateItemVisibility(): {
  loading: {
    showLogo: boolean;
    showVideo: boolean;
    showPdf: boolean;
  };
  updateItemVisibility: (
    key: 'showLogo' | 'showVideo' | 'showPdf',
    newValue: boolean,
  ) => Promise<void>;
} {
  const [loading, setLoading] = useState({
    showLogo: false,
    showVideo: false,
    showPdf: false,
  });
  async function updateItemVisibility(
    key: 'showLogo' | 'showVideo' | 'showPdf',
    newValue: boolean,
  ): Promise<void> {
    try {
      setLoading((prev) => ({ ...prev, [key]: true }));
      await CompaniesService.companyControllerUpdateSettings({
        [key]: newValue,
      });
    } catch (error) {
      errorHandler.capture(error);
    } finally {
      setLoading((prev) => ({ ...prev, [key]: false }));
    }
  }

  return { loading, updateItemVisibility };
}

export async function removeCompanyPdf(): Promise<void> {
  await CompaniesService.companyControllerUpdateSettings({
    promotionalPdf: null,
  });
}
